<template>
  <div class="spr-autocomplete-dropdown">
    <div class="single-select-wrapper">
      <div class="relative inline-block w-full">
        <input v-model="searchValue" type="text" class="search-input" :placeholder="placeholder" @input="handleInput" @keydown.enter="selectSingleOption" @keydown="handleKeyDown($event)" @keypress="validateInput($event)" @blur="hideDropdown" />
        <ul v-if="showDropdown" class="dropdown">
          <li
            v-for="item in filteredList"
            :key="item"
            :class="{ highlighted: item === hoveredItem }"
            @mouseenter="hoveredItem = item"
            @mouseleave="hoveredItem = null"
            @click="selectItem(item)"
          >
            {{ item }}
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import Vue from 'vue'
  // the data object
  export interface TemplateComponentData {
  searchValue: string,
  showDropdown: boolean,
  filteredList: Array<any>,
  hoveredItem: any
}

  export default Vue.extend({
    name: 'SimpleAutoCompleteDropdown',
    props: {
      items: {
        type: Array,
        required: true
      },
      value: {
        type: String,
        required: true
      },
      placeholder: {
        type: String,
        required: true
      }
    },
    data (): TemplateComponentData {
      return {
        searchValue: this.value,
        showDropdown: false,
        filteredList: [],
        hoveredItem: null
      }
    },
    watch: {
      value (newValue, oldValue) {
        this.searchValue = newValue
      }
    },
    methods: {
      selectItem (item) {
        this.searchValue = item
        this.showDropdown = false
        this.$emit('input', item)
      },
      selectSingleOption () {
        if (this.filteredList.length === 1) {
          this.selectItem(this.filteredList[0])
        }
      },
      handleInput () {
        this.showDropdown = true
        this.filteredList = this.items.filter((item: any) =>
          item.toLowerCase().includes(this.searchValue.toLowerCase())
        )
        if (this.filteredList.length === 1) {
          this.selectItem(this.filteredList[0])
        }
      },
      handleKeyDown (event) {
        if (event.key === 'Backspace' && this.searchValue === '') {
          event.preventDefault()
        }
      },
      validateInput (event) {
        const charCode = event.which ? event.which : event.keyCode
        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
          event.preventDefault()
        }
      },
      hideDropdown () {
        setTimeout(() => {
          this.showDropdown = false // Hide dropdown when input loses focus
          if (!this.items.includes(this.searchValue)) {
            this.searchValue = ''
          }
        }, 300)
      }
    }
  })
</script>

<style lang="scss" scoped>
@import "../assets/scss/_variables.scss";

.spr-autocomplete-dropdown {
  list-style-type: none;
  padding: 0;
  position: absolute;
  z-index: 5;
  li {
    cursor: pointer;
  }
  ul {
    list-style-type: none;
    padding: 0;
    position: absolute;
    z-index: 10;
    max-height: 100px; /* Set maximum height */
    overflow-y: auto; /* Add scrollbar if content exceeds max height */
    width: 100%; /* Set width to 100% */
  }

  li.highlighted {
    background-color: #f0f0f0;
  }

  .dropdown {
  -webkit-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.12), 0 2px 4px 0 rgba(0, 0, 0, 0.08);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.12), 0 2px 4px 0 rgba(0, 0, 0, 0.08);
  background-color: #fff;
  color: #606f7b;
  border-radius: 0.25em;
  line-height: 1.25;
  text-align: left;
}

.dropdown > li {
  padding: 0.5em 0.75em;
}
}

.spr-autocomplete-dropdown{
  .search-input {
      display: block;
      width: 100%;
      padding: 6px 20px;
      font-size: 1em;
      color: $brand-font;
      background-color: #fff;
      background-clip: padding-box;
      border: 2px solid $brand-grey3;
      transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      box-sizing: border-box;
      border-radius: 60px;
      outline: none;

      &.clearclose {
          &::-ms-clear {
              display: none !important;
              width: 0;
              height: 0;
          }
      }
  }
}
</style>
