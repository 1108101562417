<template>
  <div class="spr-datepicker">
    <label v-if="title" :for="_uid" class="cap-label">{{ title }}</label>
    <date-picker v-if="initialDate"
                 :id="_uid"
                 :title="title"
                 :is-valid="true"
                 :date="initialDate"
                 :option="defaultOptions"
                 :limit="limit"
                 @change="updateValue"
    />
    <date-picker v-else
                 :id="_uid"
                 :title="title"
                 :is-valid="true"
                 :option="defaultOptions"
                 :limit="limit"
                 @change="updateValue"
    />
  </div>
</template>

<script lang="ts">
  import Vue from 'vue'
  // Customised from Vue-datepicker https://www.npmjs.com/package/vue-datepicker with our own input box styling
  import myDatepicker from '../components/BaseDatepicker.vue'
  import moment from 'moment'
  import { DatePickerOptions } from '../types/custom'

  export interface TemplateComponentData {
    defaultOptions: DatePickerOptions,
    timeoption: DatePickerOptions,
    multiOption: DatePickerOptions
  }

  export default Vue.extend({
    name: 'SprDatePicker',
    components: {
      'date-picker': myDatepicker
    },
    props: {
      title: {
        type: String,
        default: ''
      },
      value: {
        type: String, // expected in finnish format....
        default: ''
      },
      placeholder: {
        type: String,
        default: ''
      },
      hasError: Boolean,
      initial: {
        type: String,
        default: ''
      },
      options: {
        type: Object,
        default: null
      },
      limit: {
        type: Array,
        default: () => []
      },
      isNullable: Boolean,
      resultAsDate: {
        type: Boolean,
        default: false
      }
    },
    data (): TemplateComponentData {
      return {
        defaultOptions: {
          type: 'day',
          week: [
            this.$t('datePicker.mondayShort') as string,
            this.$t('datePicker.tuesdayShort') as string,
            this.$t('datePicker.wednesdayShort') as string,
            this.$t('datePicker.thursdayShort') as string,
            this.$t('datePicker.fridayShort') as string,
            this.$t('datePicker.saturdayShort') as string,
            this.$t('datePicker.sundayShort') as string
          ],
          month: [
            this.$t('datePicker.january') as string,
            this.$t('datePicker.february') as string,
            this.$t('datePicker.march') as string,
            this.$t('datePicker.april') as string,
            this.$t('datePicker.may') as string,
            this.$t('datePicker.june') as string,
            this.$t('datePicker.july') as string,
            this.$t('datePicker.august') as string,
            this.$t('datePicker.september') as string,
            this.$t('datePicker.october') as string,
            this.$t('datePicker.november') as string,
            this.$t('datePicker.december') as string
          ],
          format: 'DD.MM.YYYY',
          placeholder: this.placeholder || 'Choose date',
          inputStyle: {
            border: 'none',
            width: '100%',
            'box-shadow': '2px 2px 5px #464646',
            padding: '10px 20px'
          },
          errorStyle: {
            border: '2px solid #CC0000'
          },
          color: {
            header: '#CC0000',
            headerText: '#fff',
            checkedDay: '#CC0000'
          },
          buttons: {
            ok: this.$t('datePicker.chooseDateOk'),
            cancel: this.$t('datePicker.chooseDateCancel'),
            clear: this.isNullable ? this.$t('datePicker.chooseDateClear') : null
          },
          overlayOpacity: 0.5, // 0.5 as default
          dismissible: true // as true as default
        },
        timeoption: {
          type: 'min',
          week: ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su'],
          month: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
          format: 'YYYY-MM-DD HH:mm'
        },
        multiOption: {
          type: 'multi-day',
          week: ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su'],
          month: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
          format: 'YYYY-MM-DD HH:mm'
        }
      }
    },
    computed: {
      initialDate (): any {
        var rv = {} as any
        rv.time = this.initial && this.initial !== '' ? moment(this.initial, ['DD.MM.YYYY', 'YYYY-MM-DDTHH:mm:ss']).format('DD.MM.YYYY') : null
        return rv
      }
    },
    watch: {
      hasError (val): void {
        this.defaultOptions.inputStyle.border = val ? '2px solid #CC0000' : '2px solid #DDDDDD'
      },
      value (val): void {
        this.updateValue(this.value)
      }
    },
    mounted () {
      Object.assign(this.defaultOptions, this.options)
    },
    methods: {
      updateValue (val) {
        if (!val) {
          this.$emit('input', null)
        } else {
          var date = val
          var dateArr = val.split('.')
          if (dateArr.length === 3) {
            // moment() obviously fixes the 0-based month issue, but so does the
            // date picked component, so we need to unfix it before handing it to
            // moment.
            date = new Date(dateArr[2], dateArr[1] - 1, dateArr[0])
          }
          if (this.resultAsDate) {
            this.$emit('input', date)
          } else {
            this.$emit('input', moment(date).format('DD.MM.YYYY'))
          }
        }
      }
    }
  })
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
  @import "../assets/scss/_variables.scss";

  .spr-datepicker {
    label {
      color: $brand-red;
      margin-left: 20px;
    }
  }
</style>
