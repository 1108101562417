<template>
  <div v-if="selectImage" class="event-page">
    <div class="container">
      <div class="row">
        <div class="col-12 back-header py-2">
          <span @click="selectImage = null">
            <font-awesome-icon icon="chevron-left" class="back" />
            {{ $t("customerForm.back") }}
          </span>
        </div>
      </div>
      <div class="row">
        <div
          v-for="(e, i) in imageLib"
          :key="i"
          class="col-6 col-sm-6 col-md-4 py-1"
        >
          <img
            :src="e.prev"
            alt="background image option"
            class="select-thumb"
            :class="{ selected: libImage === e.url }"
            @click="libImage = e.url"
          />
        </div>
      </div>
      <div class="row pb-3">
        <div class="col-12 text-center">
          <sprbutton
            type="primary"
            :size="1"
            :title="$t('eventPage.cancel')"
            class="spr-redborder button-width my-1"
            click-event="can"
            @can="selectImage = null"
          />
          <sprbutton
            type="primary"
            :size="1"
            :title="$t('eventPage.select')"
            class="spr-redborder button-width my-1"
            click-event="selectimg"
            @selectimg="setLibraryImage"
          />
        </div>
      </div>
    </div>
  </div>
  <div v-else class="event-page">
    <div v-if="isUpdating" class="updating">
      <div class="spinner">
        <loading />
      </div>
    </div>

    <!-- BACK BUTTON -->
    <div class="col-12" style="box-sizing:content-box">
      <backbar />
    </div>

    <!-- HEADER AREA -->
    <div class="hero">
      <div class="eventimage" :style="heroImageStyle" />
      <div class="thumbimage" :style="thumbImageStyle" />
      <div class="container">
        <h1>
          <div class="event-title-container">
            <div class="white-before"></div>
            <div class="inline-with-whitebefore">
              <span class="event-title">{{ translation.name }}</span>
            </div>
          </div>
        </h1>
        <span v-if="sprEvent.category !== null" class="category">{{
          computedCategory
        }}</span>
        <span v-if="sprEvent.type !== null" class="type">{{
          computedType
        }}</span>
        <div class="row">
          <div v-if="showMessageFull" class="col-12">
            <div class="message-full">
              <div class="message-text">
                {{ $t("eventPage.eventClosedMessage") }}
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="hasRole('Admin') || hasRole('NationalAdmin') || isCreator || isEventAdmin || isAdd"
          class="actions"
        >
          <sprbutton
            v-if="showJoinAction"
            description="Join Event As Admin"
            contrast
            type="primary"
            :size="1"
            :title="modalButton"
            class="spr-greyborder button-width"
            click-event="joinEvenet"
            :disabled="isRegistrationDeadlinePassed"
            @joinEvenet="eventStartJoinButton"
          />
          <sprbutton
            v-if="eventEditOpen"
            contrast
            type="primary"
            :size="1"
            :title="isAdd ? $t('eventPage.publish') : $t('eventPage.save')"
            class="spr-greyborder button-width"
            click-event="eventPublish"
            @eventPublish="eventPublishButton"
          />
          <sprbutton
            v-if="!eventEditOpen && isEdit"
            contrast
            type="primary"
            :size="1"
            :title="$t('eventPage.edit')"
            class="spr-greyborder button-width"
            click-event="eventEdit"
            @eventEdit="eventEditButton"
          />
          <sprbutton
            v-if="!eventEditOpen && isCreator"
            contrast
            type="primary"
            :size="1"
            :title="$t('eventPage.copy')"
            class="spr-greyborder button-width"
            click-event="eventCopy"
            @eventCopy="eventCopyButton"
          />
          <sprbutton
            v-if="eventEditOpen"
            contrast
            type="primary"
            :size="1"
            :title="$t('eventPage.cancel')"
            class="spr-greyborder button-width"
            click-event="eventCancel"
            @eventCancel="eventCancelButton"
          />
          <b-btn
            v-if="sprEvent.id"
            v-b-modal.removeModal
            class="spr-button button-width spr-roundbox-1"
          >
            {{ $t("eventPage.remove") }}
          </b-btn>
          <div v-if="isCreator" class="organizer">
            <div class="row">
              <div class="col-3 col-lg-4" />
              <div class="col-9 col-lg-8">
                <span>{{ $t("eventPage.organizerYou") }}</span>
              </div>
            </div>
          </div>
        </div>
        <div v-else-if="showJoinAction" class="actions">
          <sprbutton
            description="Join Event"
            type="primary"
            contrast
            :size="1"
            :title="modalButton"
            class="spr-greyborder button-width"
            click-event="joinEvenet"
            :disabled="isRegistrationDeadlinePassed"
            @joinEvenet="eventStartJoinButton"
          />
        </div>
      </div>
    </div>

    <div class="event-content">
      <!-- EDIT ROW for hero area information -->
      <div v-if="eventEditOpen" class="edit-row hero-area">
        <div class="container top-space">
          <div class="row">
            <div ref="validation" class="col-12">
              <sprvalidation
                v-if="validationErrors && validationErrors.length > 0"
                :validation-errors="validationErrors"
                class="mx-auto my-4 d-block"
              />
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="row">
                <div class="col-12">
                  <span class="cap-label">{{
                    $t("eventPage.languageSelector")
                  }}</span>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <div
                    v-for="(e, i) in availableTranslations"
                    :key="i"
                    class="translation"
                  >
                    <button
                      class="language-name"
                      :class="{
                        selected:
                          currentTranslation.languageId === e.languageId,
                      }"
                      @click="changeEditorLanguage(e.languageId)"
                    >
                      {{ e.text }}
                    </button>
                    <button
                      v-if="currentTranslation.languageId !== e.languageId"
                      class="remove-language"
                      @click="deleteTranslation(e.languageId)"
                    >
                      {{ $t("eventPage.removeLanguage") }} {{ e.text }}
                    </button>
                  </div>
                </div>
                <div class="col-12">
                  <div
                    v-for="(e, i) in possibleTranslations"
                    :key="i"
                    class="translation"
                  >
                    <button class="disabled language-name">
                      {{ e.text }}
                    </button>
                    <button
                      class="remove-language"
                      @click="newTranslation(e.id)"
                    >
                      {{ $t("eventPage.addLanguage") }} {{ e.text }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="space-between-row mt-3">
            <div class="col-12 col-lg-8">
              <forminput
                v-model="currentTranslation.name"
                v-validate="'required'"
                :hint="$t('eventPage.name')"
                :is-valid="!errors.has('eventPage.name')"
                name="eventPage.name"
                :required="true"
              />
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-12 col-lg-8">
              <eventorganizerdropdown
                v-validate="'required|hasEventOrganizer'"
                :hint="$t('eventPage.eventDistrictSection')"
                name="eventPage.eventDistrictSection"
                :required="true"
                :value="eventOrganizerData"
                :is-valid="!errors.has('eventPage.eventDistrictSection')"
                @input="onEventOrganizerChanged"
              />
            </div>
          </div>
          <div class="row">
            <div class="col-12 volunteertask-filter">
              <a
                href="javascript:void(0)"
                :class="{ selected: !isVolunteerTask }"
                @click="onRegularEventClick"
              >{{ $t("eventPage.event") }}</a>
              <a
                href="javascript:void(0)"
                :class="{ selected: isVolunteerTask }"
                @click="onVolunteerTaskClick"
              >{{ $t("eventPage.volunteerTask") }}</a>
            </div>
          </div>
          <div class="row">
            <div class="col-12 text-left">
              <span class="subheader">{{ $t('eventPage.eventCategory') }}</span>
            </div>
            <div class="col-12">
              <checkboxlist v-model="sprEvent.category"
                            :list="involvedEventCategoriesOptions"
                            :rows="3"
              />
            </div>
          </div>
          <div v-show="!isVolunteerTask" class="row">
            <div class="col-12 text-left">
              <span class="subheader">{{ $t('eventPage.eventType') }}</span>
            </div>
            <div class="col-12">
              <checkboxlist v-model="sprEvent.type"
                            :list="eventTypes"
                            :rows="3"
              />
            </div>
          </div>
          <div class="row" style="margin-top:25px;">
            <!-- change this into a list of checkboxes #6257
            <div class="col-12 col-md-6 col-lg-4">
              <dropdownnumber
                v-model="sprEvent.category"
                :title="$t('eventPage.eventCategory')"
                :items="eventCategories"
                :is-valid="true"
              />
            </div>-->
            <!--<div v-show="!isVolunteerTask" class="col-12 col-md-6 col-lg-4">
               and this for the other one! can't remember the ticket number now
              <dropdownnumber
                v-model="sprEvent.type"
                :title="$t('eventPage.eventType')"
                :items="eventTypes"
                :is-valid="true"
              />
            </div>-->
            <div class="col-md-12">
              <checkboxinput
                v-model="sprEvent.isOnline"
                :title="$t('eventPage.isOnline')"
                :aria-label="$t('eventPage.isOnline')"
              />
            </div>
            <div class="col-md-12">
              <checkboxinput
                v-model="sprEvent.isPublic"
                :title="$t('eventPage.visibility')"
                :aria-label="$t('eventPage.visibility')"
              />
            </div>
            <div class="col-12">
              <div class="vertical-container">
                <div class="cap-label cap-label-language">
                  {{ $t("eventPage.eventLanguages") }}
                </div>
                <div class="language-content">
                  <pillbox
                    :items="languages"
                    :selected="selectedlanguages"
                    @change="onLanguageChanged"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-sm-12 col-md-8">
              <div class="upload">
                <div class="cap-label mt-3">
                  {{ $t("eventPage.changeBackground") }}
                </div>
                <b-form-file
                  accept="image/*"
                  prefix="img"
                  :placeholder="$t('eventPage.chooseBackgroundPlaceholder')"
                  :choose-label="$t('eventPage.chooseButton') + ': '"
                  :drop-label="$t('eventPage.dropFile')"
                  @change="onBackgroundFileChange"
                />
              </div>
            </div>
            <div class="col-12">
              <sprbutton
                type="primary"
                :size="1"
                :title="$t('eventPage.bgBankButton')"
                class="spr-redborder button-width"
                click-event="selectBg"
                @selectBg="selectImage = 1"
              />
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-sm-12 col-md-8">
              <div class="upload">
                <div class="cap-label mt-3">
                  {{ $t("eventPage.changeThumbnail") }}
                </div>
                <b-form-file
                  accept="image/*"
                  prefix="img"
                  :placeholder="$t('eventPage.chooseThumbPlaceHolder')"
                  :choose-label="$t('eventPage.chooseButton') + ': '"
                  :drop-label="$t('eventPage.dropFile')"
                  @change="onThumbFileChange"
                />
              </div>
            </div>
            <div class="col-12">
              <sprbutton
                type="primary"
                :size="1"
                :title="$t('eventPage.thumbBankButton')"
                class="spr-redborder button-width"
                click-event="selectBg"
                @selectBg="selectImage = 2"
              />
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-12 col-sm-12 col-md-8">
              <textareainput
                v-model="currentTranslation.moreInfoQuestion"
                :title="$t('eventPage.moreInfoQuestion')"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="container">
        <div class="row">
          <div class="col-sm-12">
            <div class="column">
              <!-- EVENT TIME & DATE -->
              <div class="event-block time">
                <a
                  v-if="isEdit && eventEditOpen"
                  tabindex="0"
                  href="javascript:void(0);"
                  class="edit-corner"
                  @click="timeEditButton"
                >
                  <font-awesome-icon
                    icon="pencil-alt"
                    class="brand-red"
                    aria-hidden="true"
                  />
                </a>
                <div v-if="!timeEditorOpen" class="event-block-container">
                  <div class="row">
                    <div class="col-2 col-sm-2">
                      <span class="icon" aria-hidden="true">
                        <font-awesome-icon
                          :icon="['far', 'clock']"
                          aria-hidden="true"
                        />
                      </span>
                    </div>
                    <div class="col-10 col-sm-10">
                      <span>{{ formattedStartDate }}
                        <span
                          v-if="
                            formattedEndDate !== formattedStartDate &&
                              !isVolunteerTask
                          "
                        >
                          &ndash; {{ formattedEndDate }}</span> </span><br />
                      <span v-if="sprEvent.isAllDay">{{
                        $t("eventPage.allDay")
                      }}</span>
                      <span v-else>{{ $t("eventPage.time") }} {{ formattedStartTime }}
                        <span
                          v-if="
                            formattedEndTime !== formattedStartTime &&
                              !isVolunteerTask
                          "
                        >
                          &ndash; {{ formattedEndTime }}</span> </span><br />
                      <span v-if="sprEvent.occurance > 1" class="grey-text">{{ occurrenceText }}<br /></span>
                      <span
                        v-if="
                          sprEvent.registrationDeadline &&
                            !isRegistrationDeadlinePassed
                        "
                        class="grey-text"
                      >{{ $t("eventPage.registrationDeadline") }}:
                        {{ displayRegistrationDeadline }}<br /></span>
                      <span
                        v-else-if="
                          sprEvent.registrationDeadline &&
                            isRegistrationDeadlinePassed
                        "
                        class="grey-text"
                      >{{ $t("eventPage.registrationDeadlinePassed") }}<br /></span>
                    </div>
                  </div>
                </div>
                <div v-else class="event-block-container edit-area">
                  <div class="row">
                    <div class="col-sm-12">
                      <span class="cap-label edit-label">{{
                        isVolunteerTask
                          ? $t("eventPage.volunteerTaskDate")
                          : $t("eventPage.editDate")
                      }}</span>
                      <div class="row">
                        <div class="col-10 col-sm-6 col-md-3 col-xl-2">
                          <datepicker
                            :key="componentKey"
                            v-model="displayStartDate"
                            v-validate="'required'"
                            :title="
                              isVolunteerTask
                                ? $t('eventPage.volunteerTaskDeadline')
                                : $t('eventPage.startDate')
                            "
                            :has-error="errors.has('eventPage.startDate')"
                            name="eventPage.startDate"
                            :required="true"
                            :initial="displayStartDate"
                            :options="datepickerOptions"
                          />
                        </div>
                        <div
                          v-show="!sprEvent.isAllDay"
                          class="col-10 col-sm-6 col-md-3 col-xl-2"
                        >
                          <timepicker
                            v-model="eventStartTime"
                            :title="
                              isVolunteerTask
                                ? $t('eventPage.endTime')
                                : $t('eventPage.startTime')
                            "
                            :disabled="sprEvent.isAllDay"
                          />
                        </div>
                        <div
                          v-show="!sprEvent.isAllDay && !isVolunteerTask"
                          class="col-10 col-sm-6 col-md-3 col-xl-2"
                        >
                          <timepicker
                            v-model="eventEndTime"
                            :title="$t('eventPage.endTime')"
                            :disabled="sprEvent.isAllDay"
                          />
                        </div>
                        <div
                          v-show="!isVolunteerTask"
                          class="col-10 col-sm-6 col-md-3 col-xl-2"
                        >
                          <datepicker
                            :key="componentKey"
                            v-model="displayEndDate"
                            v-validate="{
                              required: true,
                              dateIsAfter: displayStartDate,
                            }"
                            :title="$t('eventPage.endDate')"
                            :initial="displayEndDate"
                            :has-error="errors.has('eventPage.endDate')"
                            name="eventPage.endDate"
                            :required="true"
                            :options="datepickerOptions"
                          />
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-12">
                          <checkboxinput
                            v-model="sprEvent.isAllDay"
                            :title="$t('eventPage.allDay')"
                            :aria-label="$t('eventPage.allDay')"
                          />
                        </div>
                      </div>
                      <div v-show="isAdd">
                        <div class="row">
                          <div class="col-md-12">
                            <checkboxinput
                              :title="$t('eventPage.recurringEvent')"
                              :value="isRecurring"
                              :aria-label="$t('eventPage.recurringEvent')"
                              @input="onRecurringEventChange"
                            />
                          </div>
                        </div>
                        <div v-show="isRecurring">
                          <div class="row">
                            <div class="col-10 col-sm-6 col-xl-4">
                              <dropdownnumber
                                :title="$t('eventPage.repeat')"
                                :items="recurrenceFrequencyOptions"
                                :is-valid="true"
                                :value="recurrenceFrequency"
                                @input="onFrequencyUpdate"
                              />
                            </div>
                          </div>
                          <div class="row">
                            <div
                              class="pr-md-0 col-10 col-sm-6 col-md-3 col-xl-2"
                            >
                              <datepicker
                                :key="componentKey"
                                :title="$t('eventPage.recurringUntil')"
                                :value="recurringUntil"
                                :initial="recurringUntil"
                                :has-error="
                                  errors.has('eventPage.recurringUntil')
                                "
                                name="eventPage.recurringUntil"
                                :options="datepickerOptions"
                                :limit="recurringUntilLimit"
                                @input="onRecurringUntilUpdate"
                              />
                            </div>
                            <div class="col-10 col-md-9 col-xl-6 repetitions">
                              <div class="wrap">
                                <span class="prefix">{{
                                  $t("eventPage.repetitionsPrefix")
                                }}</span>
                                <numberinput
                                  v-validate="'between:1,52'"
                                  :value="Number(sprEvent.repeat)"
                                  :min="2"
                                  :max="52"
                                  :is-valid="
                                    !errors.has(
                                      'eventPage.recurringRepetitions'
                                    )
                                  "
                                  name="eventPage.recurringRepetitions"
                                  @input="onRepetitionsUpdate"
                                />
                                <span class="suffix">{{
                                  $t("eventPage.repetitionsSuffix")
                                }}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-10 col-sm-6 col-xl-4">
                          <datepicker
                            :key="componentKey"
                            v-model="registrationDeadline"
                            v-validate="{
                              required: false,
                              dateIsBefore: validatorDate,
                            }"
                            :title="$t('eventPage.registrationDeadline')"
                            :initial="registrationDeadline"
                            :has-error="errors.has('eventPage.registrationDeadline')"
                            name="eventPage.registrationDeadline"
                            :options="datepickerOptions"
                            :is-nullable="true"
                            :placeholder="$t('eventPage.chooseDate')"
                            :limit="registrationDeadlineLimit"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- EVENT LOCATION -->
              <div class="event-block location">
                <a
                  v-if="isEdit && eventEditOpen"
                  tabindex="0"
                  href="javascript:void(0);"
                  class="edit-corner"
                  @click="locationEditButton"
                >
                  <font-awesome-icon
                    icon="pencil-alt"
                    class="brand-red"
                    aria-hidden="true"
                  />
                </a>

                <div v-if="!locationEditorOpen" class="event-block-container">
                  <div class="row">
                    <div class="col-2 col-sm-2">
                      <span class="icon" aria-hidden="true">
                        <font-awesome-icon
                          icon="map-marker-alt"
                          aria-hidden="true"
                        />
                      </span>
                    </div>
                    <div class="col-10 col-sm-10">
                      <p v-if="formattedLocation" class="mr-4">
                        {{ formattedLocation }}
                      </p>
                      <p v-if="sprEvent.isOnline" class="mr-4">
                        {{ $t("eventPage.isOnline") }}
                      </p>
                      <a
                        v-if="hasLocation"
                        href="javascript:void(0)"
                        @click="showMap"
                      >{{ $t("eventPage.showOnMap") }}</a>
                      <div v-if="hasLocation && mapIsVisible" class="map">
                        <VueMap
                          :center="mapCenter"
                          :zoom="mapZoom"
                          style="width: 100%; height: 250px"
                        >
                          <VueMarker
                            v-for="m in markers"
                            :key="m.id"
                            :position.sync="m.position"
                            :clickable="false"
                            :draggable="false"
                          />
                        </VueMap>
                      </div>
                      <div
                        v-if="
                          isEdit && hasLocation && mapIsVisible && geocodeFailed
                        "
                        class="geocodefailed"
                      >
                        {{ $t("eventPage.geocodeFailed") }}
                      </div>
                    </div>
                  </div>
                </div>
                <div v-else class="event-block-container edit-area">
                  <div class="row">
                    <div class="col-sm-12">
                      <span class="cap-label edit-label">{{
                        $t("eventPage.editLocation")
                      }}</span>

                      <div class="row">
                        <div class="col-12 col-lg-8">
                          <forminput
                            v-model="currentTranslation.placeName"
                            :hint="$t('eventPage.placeName')"
                            name="eventPage.placeName"
                            :is-valid="true"
                          />
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-12 col-lg-8">
                          <forminput
                            v-model="currentTranslation.streetAddress"
                            :hint="$t('eventPage.streetAddress')"
                            name="eventPage.streetAddress"
                            :is-valid="true"
                          />
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-12 col-sm-6 col-md-4 col-lg-2">
                          <div class="search-item">
                            <div class="cap-label">
                              {{ $t("eventPage.zipCode")
                              }}<span v-if="!sprEvent.isOnline">*</span>
                            </div>
                            <singleselectdropdown
                              v-model="selectedZipLocation"
                              v-validate="{
                                required: !sprEvent.isOnline,
                              }"
                              :fixed-width="true"
                              :required="!sprEvent.isOnline"
                              name="eventPage.zipCode"
                              :is-valid="!errors.has('eventPage.zipCode')"
                              :value="selectedZipLocation"
                              :searchable="true"
                              :allowempty="true"
                              :items="zipCodes"
                              @change="onIsZipCodeChanged"
                            />
                          </div>
                        </div>
                        <div class="col-12 col-sm-6 col-md-8 col-lg-6">
                          <forminput
                            v-model="currentTranslation.city"
                            v-validate="{
                              required: !sprEvent.isOnline,
                            }"
                            :required="!sprEvent.isOnline"
                            :hint="$t('eventPage.city')"
                            name="eventPage.city"
                            :is-valid="!errors.has('eventPage.city')"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- EVENT FEE -->
              <div class="event-block">
                <a
                  v-if="isEdit && eventEditOpen"
                  tabindex="0"
                  href="javascript:void(0);"
                  class="edit-corner"
                  @click="feeEditButton"
                >
                  <font-awesome-icon
                    icon="pencil-alt"
                    class="brand-red"
                    aria-hidden="true"
                  />
                </a>
                <div v-if="!feeEditorOpen" class="event-block-container fee">
                  <div class="row">
                    <div class="col-2 col-sm-2">
                      <span class="icon" aria-hidden="true">
                        <font-awesome-icon
                          icon="euro-sign"
                          aria-hidden="true"
                        />
                      </span>
                    </div>
                    <div class="col-12 col-lg-8">
                      {{ translation.price }}
                    </div>
                  </div>
                </div>
                <div v-else class="event-block-container edit-area">
                  <div class="row">
                    <div class="col-12 col-lg-8">
                      <forminput
                        v-model="currentTranslation.price"
                        :hint="$t('eventPage.fee')"
                        name="eventPage.fee"
                        :is-valid="true"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- EVENT DESCRIPTION -->
            <div class="event-block">
              <a
                v-if="isEdit && eventEditOpen"
                tabindex="0"
                href="javascript:void(0);"
                class="edit-corner"
                @click="descriptionEditButton"
              >
                <font-awesome-icon
                  icon="pencil-alt"
                  class="brand-red"
                  aria-hidden="true"
                />
              </a>
              <div v-if="!descriptionEditorOpen" class="event-block-container">
                <div class="row">
                  <div class="col-12 col-lg-8">
                    <span class="cap-label">{{
                      $t("eventPage.description")
                    }}</span>
                    <p
                      v-if="translation.description"
                      :key="componentKey"
                      v-linkified:options="{ nl2br: true }"
                    >
                      {{ translation.description }}
                    </p>
                  </div>
                </div>
              </div>
              <div v-else class="event-block-container">
                <div class="row">
                  <div class="col-12 col-lg-8">
                    <textareainput
                      v-model="currentTranslation.description"
                      :title="$t('eventPage.description')"
                      :rows="8"
                    />
                  </div>
                </div>
              </div>
            </div>

            <!-- CONTACT PERSON -->
            <div class="event-block">
              <a
                v-if="isEdit && eventEditOpen"
                tabindex="0"
                href="javascript:void(0);"
                class="edit-corner"
                @click="contactEditButton"
              >
                <font-awesome-icon
                  icon="pencil-alt"
                  class="brand-red"
                  aria-hidden="true"
                />
              </a>
              <div
                v-if="!contactEditorOpen"
                class="event-block-container contact"
              >
                <div class="row field-row">
                  <div class="col-12 col-lg-8">
                    <span class="cap-label">{{ $t("eventPage.contact") }}</span>
                    <div v-if="sprEvent.contact" class="contact-person">
                      {{ sprEvent.contact.firstName }}
                      {{ sprEvent.contact.lastName }}
                    </div>
                    <span v-if="sprEvent.contact.email">{{
                      sprEvent.contact.email
                    }}</span>
                    <br />
                    <span v-if="sprEvent.contact.phone">{{
                      sprEvent.contact.phone
                    }}</span>
                  </div>
                </div>
              </div>
              <div v-else class="event-block-container contact">
                <div class="row field-row">
                  <div class="col-12 col-lg-8">
                    <span class="cap-label">{{ $t("eventPage.contact") }}</span>
                    <div class="contact-details">
                      <forminput
                        v-model="sprEvent.contact.firstName"
                        v-validate="'required'"
                        :hint="$t('eventPage.firstName')"
                        name="eventPage.firstName"
                        :is-valid="!errors.has('eventPage.firstName')"
                        :required="true"
                      />
                      <forminput
                        v-model="sprEvent.contact.lastName"
                        v-validate="'required'"
                        :hint="$t('eventPage.lastName')"
                        name="eventPage.lastName"
                        :is-valid="!errors.has('eventPage.lastName')"
                        :required="true"
                      />
                      <forminput
                        v-model="sprEvent.contact.email"
                        :hint="$t('eventPage.email')"
                        name="sprEvent.contact.email"
                        :is-valid="true"
                      />
                      <forminput
                        v-model="sprEvent.contact.phone"
                        :hint="$t('eventPage.phone')"
                        name="sprEvent.contact.phone"
                        :is-valid="true"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- SOCIAL MEDIA -->
            <div v-if="!isAdd" class="event-block">
              <sharethis />
            </div>
          </div>
        </div>
        <!-- EVENT ADMINS -->
        <div v-if="hasRole('Admin') || hasRole('NationalAdmin') || isCreator || isEventAdmin" class="row">
          <div class="col-12">
            <div class="event-block admins">
              <a
                v-if="isEdit && eventEditOpen && (hasRole('Admin') || hasRole('NationalAdmin') || isCreator)"
                tabindex="0"
                href="javascript:void(0);"
                class="edit-corner"
                @click="adminsEditButton"
              >
                <font-awesome-icon
                  icon="pencil-alt"
                  class="brand-red"
                  aria-hidden="true"
                />
              </a>
              <div class="event-block-container">
                <eventadmins
                  :admins="sprEvent.eventAdmins"
                  :is-edit-mode="
                    (hasRole('Admin') || hasRole('NationalAdmin') || isCreator || isAdd) && isEditAdminsOpen
                  "
                  @addAdmin="onAddAdmin"
                  @removeAdmin="onRemoveAdmin"
                />
              </div>
            </div>
          </div>
        </div>
        <div v-if="(isEdit && eventEditOpen) || isAdd" class="row">
          <div class="col-12 text-left">
            <span class="subheader">{{ $t('eventPage.eventRegistrationOptions') }}</span>
          </div>
          <div class="col-md-12">
            <sprradiogroupkey
              ref="eventRegistrationOptions"
              v-model="sprEvent.eventRegistration"
              radio-group-color="basic"
              :options="eventRegistrationOptions"
            /> <!-- options for eventregistration are external, Oma or None -->
          </div>
        </div>
        <div v-if="((isEdit && eventEditOpen) || isAdd) && sprEvent.eventRegistration === 1" class="col-12 col-lg-8">
          <!-- eventregistration is external, not Oma or none -->
          <forminput
            v-model="currentTranslation.externalUrl"
            v-validate="{ url: { require_protocol: true } }"
            :hint="$t('eventPage.externalEvent')"
            name="eventPage.externalEvent"
            :placeholder="$t('eventPage.externalEventPlaceholder')"
            :is-valid="!errors.has('eventPage.externalEvent')"
          />
        </div>
        <div v-if="((isEdit && eventEditOpen) || isAdd) && sprEvent.eventRegistration === 0" class="event-block">
          <!-- eventregistration is external, not Oma or none -->
          <div ref="editeventroles" class="row">
            <div class="col-12">
              <SprCollapsible v-if="showEditRoles"
                              :title="$t('eventPage.roles')"
              >
                <!-- ROLES -->
                <editeventroles
                  v-model="sprEvent.eventRoles"
                  :show-warning="showNoRolesWarning"
                  :eventid="sprEvent.id"
                />
              </SprCollapsible>
              <vieweventroles
                v-if="showViewRoles"
                v-model="sprEvent.eventRoles"
              />
            </div>
          </div>
        </div>
        <!-- CONTACT NOTIFICATIONS -->
        <div v-if="(hasRole('Admin') || hasRole('NationalAdmin') || isCreator || isEventAdmin)" class="event-block contact-notifications">
          <a
            v-if="isEdit && eventEditOpen"
            tabindex="0"
            href="javascript:void(0);"
            class="edit-corner"
            @click="contactNotificationsButton"
          >
            <font-awesome-icon
              icon="pencil-alt"
              class="brand-red"
              aria-hidden="true"
            />
          </a>
          <!-- CLOSED -->
          <div v-if="!contactNotificationsOpen" class="event-block-container contact">
            <div class="row field-row">
              <div class="col-12 col-lg-8">
                <span class="cap-label">{{
                  $t("eventPage.contactNotificationHeader")
                }}</span>
                <div v-if="contactsReadOnly" class="contact-person">
                  <div class="onlyContactsInfo">
                    <div>{{ contactsReadOnly }}</div>
                  </div>
                </div>
                <div class="row pt-3">
                  <div class="col-12 col-sm-9 col-md-6">
                    <div class="onlyFrequencyDefinition">
                      <dl>
                        <dt v-show="hasContactNotifications">
                          {{ $t("userAdmin.sectionReportFrequency") }}
                        </dt>
                        <dd>{{ frequencyDefinition }}</dd>
                      </dl>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- OPEN -->
          <div v-else class="event-block-container contact">
            <div class="row">
              <div class="col-12 col-md-12">
                <span class="cap-label">{{
                  $t("eventPage.contactNotificationHeader")
                }}</span>
              </div>
              <div class="col-9 col-md-6">
                <div class="emailinput">
                  <forminput
                    v-model="contactNotificationInputMail"
                    v-validate="'email'"
                    input-type="email"
                    :placeholder="$t('userAdmin.sectionReportContactAdd')"
                    name="contactNotifications.contactemail"
                    :required="false"
                    :is-valid="!errors.has('contactNotifications.contactemail')"
                  />
                </div>
              </div>
              <div class="col-3 col-md-6">
                <sprbutton
                  :size="5"
                  :title="$t('userAdmin.sectionReportContactAdd')"
                  click-event="addContactNotification"
                  class="add-button"
                  @addContactNotification="onAddContactNotification"
                />
              </div>
            </div>
            <div class="col-12 editor-area">
              <span>
                <pill
                  v-for="(contact, index) in contactNotifications.contacts"
                  :key="index"
                  :value="contact.email"
                  :show-delete="true"
                  @remove="onRemoveContact(contact)"
                />
              </span>
            </div>
            <!-- end of contact notifications  -->
            <div class="frequency">
              {{ $t("userAdmin.sectionReportFrequency") }}
            </div>
            <sprradiogroupkey
              ref="contactNotificationFrequency"
              v-model="contactNotifications.notificationFrequency"
              radio-group-color="basic"
              :options="contactNotificationFrequencies"
            />
          </div>
        </div>
        <div v-if="((isEdit && eventEditOpen) || isAdd) && sprEvent.eventRegistration === 0" class="event-block">
          <div class="row">
            <div class="col-12 participants">
              <!-- PARTICIPANTS -->
              <div v-if="hasRole('Admin') || hasRole('NationalAdmin') || isCreator || isEventAdmin">
                <eventparticipantsgrid
                  v-model="sprEvent.eventRoles"
                  @change="onParticipationChanged"
                  @changeRemoval="onRemoveParticipants"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- BOTTOM BUTTONS -->
      <div
        v-if="hasRole('Admin') || hasRole('NationalAdmin') || isCreator || isEventAdmin || isAdd"
        class="footer-actions"
      >
        <div class="col-12">
          <div class="container">
            <div class="row">
              <div class="col-12">
                <sprbutton
                  v-if="!isAdd && (hasRole('Admin') || hasRole('NationalAdmin') || isCreator || isEventAdmin)"
                  type="primary"
                  :size="1"
                  :title="$t('eventPage.downloadParticipants')"
                  class="spr-redborder button-width my-1"
                  click-event="downloadParticipants"
                  @downloadParticipants="onDownloadParticipants"
                />
                <sprbutton
                  v-if="eventEditOpen"
                  type="primary"
                  :size="1"
                  :title="isAdd ? $t('eventPage.publish') : $t('eventPage.save')"
                  class="spr-redborder button-width my-1"
                  click-event="eventPublish"
                  @eventPublish="eventPublishButton"
                />
                <sprbutton
                  v-if="!eventEditOpen && isEdit"
                  type="primary"
                  :size="1"
                  :title="$t('eventPage.edit')"
                  class="spr-redborder button-width my-1"
                  click-event="eventEdit"
                  @eventEdit="eventEditButton"
                />
                <sprbutton
                  v-if="eventEditOpen"
                  type="primary"
                  :size="1"
                  :title="$t('eventPage.cancel')"
                  class="spr-redborder button-width my-1"
                  click-event="eventCancel"
                  @eventCancel="eventCancelButton"
                />
                <sprbutton
                  v-if="!isAdd && (hasRole('Admin') || hasRole('NationalAdmin') || isCreator || isEventAdmin)"
                  type="primary"
                  :size="1"
                  :title="$t('eventPage.removeParticipants')"
                  class="spr-redborder button-width my-1"
                  click-event="removeParticipatns"
                  @removeParticipatns="removeParticipants"
                />
                <b-btn
                  v-if="sprEvent.id"
                  v-b-modal.removeModal
                  class="spr-button button-width spr-roundbox-1"
                >
                  {{ $t("eventPage.remove") }}
                </b-btn>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- BOTTOM BACK BAR -->
      <div class="col-12">
        <backbar />
      </div>

      <!-- MODAL -->
      <b-modal
        :id="_uid + 'modal'"
        v-model="showModal"
        size="lg"
        centered
        cancel-disabled
        ok-disabled
        @close="cancelModal"
      >
        <div
          v-if="joinStage === 1"
          class="text-center container-fluid modal-container"
        >
          <!-- Anonyymin napit -->
          <sprbutton
            type="primary"
            description="Login before joining"
            :size="4"
            :title="$t('eventPage.loginAndJoin')"
            class="spr-redborder button-width d-block mx-auto my-2"
            click-event="buttonClick"
            :disabled="isRegistrationDeadlinePassed"
            @buttonClick="loginJoin"
          />
          <sprbutton
            type="primary"
            description="Anonymous Join"
            :size="4"
            :title="$t('eventPage.joinNow')"
            class="spr-redborder button-width d-block mx-auto my-2"
            click-event="buttonClick"
            :disabled="isRegistrationDeadlinePassed"
            @buttonClick="anonJoin"
          />
        </div>
        <div class="event-block secondary">
          <div
            v-if="
              joinStage === 2 || hasRole('Admin') || hasRole('NationalAdmin') || isCreator || isEventAdmin
            "
          >
            <eventjoin
              v-if="!isCreator && !isParticipant && isLoggedIn"
              :roles="sprEvent.eventRoles"
              :more-info-question="translation.moreInfoQuestion"
              :profile-id="profileId"
              @join="onEventJoin"
            />
            <eventjoinpublic
              v-if="!isParticipant && !isLoggedIn"
              :roles="sprEvent.eventRoles"
              :more-info-question="translation.moreInfoQuestion"
              @join="onEventJoinPublic"
            />
            <eventjoin
              v-if="!isCreator && isParticipant && isLoggedIn"
              :roles="sprEvent.eventRoles"
              :more-info-question="translation.moreInfoQuestion"
              :profile-id="profileId"
              is-leaving
              @join="onEventJoin"
            />
            <eventjoinpublic
              v-if="isParticipant && !isLoggedIn"
              :roles="sprEvent.eventRoles"
              :event-id="sprEvent.id"
              :more-info-question="translation.moreInfoQuestion"
              is-leaving
              @join="onEventJoinPublic"
            />
          </div>
        </div>
        <!-- header -->
        <div slot="modal-header" class="container-fluid">
          <div class="row">
            <div class="col-10 col-sm-7">
              <h3 v-if="joinStage === 1">
                {{ $t("eventPage.joinEvent") }}
              </h3>
              <h3 v-if="joinStage === 2 && !isParticipant">
                {{ $t("eventPage.enterInfo") }}
              </h3>
              <h3 v-if="joinStage === 2 && isParticipant">
                {{ $t("eventPage.joinSuccess") }}
              </h3>
            </div>
          </div>
        </div>
        <!-- footer -->
        <div slot="modal-footer" class="container-fluid">
          <div class="row">
            <div
              class="col-12 text-right close-link brand-red"
              @click="cancelModal"
            >
              <span class="brand-red">{{ $t("eventPage.closeModal") }}</span>
              <font-awesome-icon
                icon="times"
                class="brand-red"
                aria-hidden="true"
              />
            </div>
          </div>
        </div>
      </b-modal>

      <!-- REMOVAL MODAL -->
      <b-modal
        id="removeModal"
        ref="removeModal"
        size="lg"
        centered
        cancel-disabled
        ok-disabled
      >
        <div v-if="sprEvent.occurance > 1" class="recurring">
          <p>{{ $t("eventPage.confirmRemoveRepeatText") }}</p>
          <sprradiogroupkey
            ref="recurring"
            v-model="recurringDeleteMode"
            radio-group-color="basic"
            :options="recurringOptions"
          />
        </div>
        <div v-else>
          <p>{{ $t("eventPage.confirmRemoveText") }}</p>
        </div>
        <div class="col-md-12">
          <checkboxinput
            :title="$t('eventPage.confirmDelete')"
            :value="confirmDelete"
            :aria-label="$t('eventPage.confirmDelete')"
            @input="onConfirmDelete"
          />
        </div>
        <sprbutton
          type="primary"
          :size="1"
          :title="$t('eventPage.remove')"
          :disabled="!confirmDelete"
          class="spr-redborder"
          click-event="eventRemove"
          @eventRemove="confirmEventRemoval"
        />
        <!-- header -->
        <div slot="modal-header" class="container-fluid">
          <div class="row">
            <div class="col-10 col-sm-7">
              <h3 v-if="sprEvent.repeat > 1">
                {{ $t("eventPage.confirmRemoveRepeatTitle") }}
              </h3>
              <h3 v-else>
                {{ $t("eventPage.confirmRemoveTitle") }}
              </h3>
            </div>
          </div>
        </div>
        <!-- footer -->
        <div slot="modal-footer" class="container-fluid">
          <div class="row">
            <div
              class="col-12 text-right close-link brand-red"
              @click="hideRemoveModal"
            >
              <span class="brand-red">{{ $t("eventPage.closeModal") }}</span>
              <font-awesome-icon
                icon="times"
                class="brand-red"
                aria-hidden="true"
              />
            </div>
          </div>
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
  import backbar from '../BackBar.vue'
  import forminput from '../SPRSimpleText.vue'
  import singleselectdropdown from '../common/Dropdown/SingleSelectDropdown.vue'
  import numberinput from '../SPRSimpleNumberInput.vue'
  import dropdownnumber from '../SPRDropdownNumberKey.vue'
  import sprbutton from '../SPRButton.vue'
  import datepicker from '../SPRDatepicker.vue'
  import timepicker from '../SPRTimepicker.vue'
  import checkboxinput from '../SPRSimpleCheckbox.vue'
  import textareainput from '../SPRSimpleTextArea.vue'
  import sprradiogroupkey from '../SPRRadioGroupKey.vue'
  import editeventroles from './EditEventRoles.vue'
  import vieweventroles from './ViewEventRoles.vue'
  import eventparticipantsgrid from './EventParticipantsGrid.vue'
  import eventjoin from './EventJoin.vue'
  import eventjoinpublic from './EventJoinPublic.vue'
  import eventadmins from './EventAdmins.vue'
  import eventorganizerdropdown from '../SPREventOrganizersDropdown.vue'
  import sprvalidation from '../SPRValidationError.vue'
  import loading from '../SPRLoading.vue'
  import sharethis from './ShareThis.vue'
  import moment from 'moment'
  import json2csv from 'json2csv'
  import { Map as VueMap, Marker as VueMarker } from 'vue2-google-maps'

  import checkboxlist from '../SPRSimpleCheckboxBitmaskList.vue'
  import enumhelper from '../../mixins/enums.js'
  import googlemaps from '../../mixins/googlemaps.js'
  import eventhelper from '../../mixins/event.js'
  import locationhelper from '../../mixins/locationutils.js'

  import { Validator } from 'vee-validate'
  import dateisafter from '../../mixins/validator-dateisafter.js'
  import dateisbefore from '../../mixins/validator-dateisbefore'
  import hasEventOrganizer from '../../mixins/validator-hasEventOrganizer.js'
  import pillbox from '../common/Pill/PillBox.vue'
  import pill from '../SPRPill.vue'
  import SprCollapsible from '../common/Collapsible/SprEventCollapsible.vue'

  Validator.extend('dateIsAfter', dateisafter)
  Validator.extend('dateIsBefore', dateisbefore)
  Validator.extend('hasEventOrganizer', hasEventOrganizer)

  export default {
    name: 'Event',
    pageName: 'eventpage',
    components: {
      SprCollapsible,
      pill,
      singleselectdropdown,
      sprvalidation,
      backbar,
      forminput,
      checkboxlist,
      checkboxinput,
      textareainput,
      sprbutton,
      datepicker,
      timepicker,
      dropdownnumber,
      numberinput,
      sprradiogroupkey,
      VueMap,
      VueMarker,
      editeventroles,
      vieweventroles,
      eventparticipantsgrid,
      eventjoin,
      eventjoinpublic,
      eventadmins,
      eventorganizerdropdown,
      loading,
      sharethis,
      pillbox
    },
    mixins: [enumhelper, googlemaps, eventhelper, locationhelper],
    data () {
      return {
        zipCodes: [],
        confirmDelete: false,
        postError: null,
        selectImage: null,
        libImage: null,
        suppressErrors: true,
        showModal: false,
        removeEvent: false,
        joinStage: 0,
        availableLanguages: [54, 200, 46],
        langToAdd: null,
        currentTranslation: {},
        isRecurring: false,
        recurringDeleteMode: 0, // 0 - Only this event, 1 = All recurring evets. See recurringOptions.
        recurringUntil: null,
        recurrenceFrequency: 2,
        isVolunteerTask: false,
        languages: [],
        selectedlanguages: [],
        sprEvent: {
          id: -1,
          name: '',
          eventRegistration: 0, // Event registration is Oma, not external or none
          moreInfoQuestion: '',
          description: '',
          date: '',
          location: '',
          price: '',
          eventRoles: [],
          startTime: {
            day: 1,
            month: 1,
            year: 0,
            hour: 0,
            minute: 0
          },
          endTime: {
            day: 1,
            month: 1,
            year: 0,
            hour: 0,
            minute: 0
          },
          isAllDay: false,
          occurance: 1,
          repeat: 2,
          registrationDeadline: null,
          streetAddress: '',
          zipCode: '',
          city: '',
          contact: {
            firstName: '',
            lastName: '',
            email: '',
            phone: ''
          },
          translations: [],
          image: '',
          imageThumb: '',
          oldImage: '', // Todo : These are for edit mode to change the existing image
          oldImageThumb: '',
          placeName: '',
          isOnline: false,
          isPublic: true,
          duration: '', // moment.duration(60, 'minutes'),
          category: 0,
          type: 0,
          eventAdmins: [],
          eventOrganizer: {
            eventOrganizerType: null,
            organizerId: null
          },
          languages: 1
        },

        selectedZipLocation: {},
        datepickerOptions: {
          inputStyle: {
            display: 'inline-block',
            border: '2px solid #ccc',
            'box-shadow': 'none',
            'border-radius': '24px',
            color: '#5F5F5F'
          }
        },
        eventEditOpen: false,
        timeEditorOpen: false,
        locationEditorOpen: false,
        feeEditorOpen: false,
        contactEditorOpen: false,
        contactNotificationsOpen: false,
        descriptionEditorOpen: false,
        isEditAdminsOpen: false,
        isAdd: false,
        isEdit: false,
        mapCenter: {
          // on helsinki to start
          lat: 60.17,
          lng: 24.94
        },
        mapZoom: 7,
        mapIsVisible: false,
        markers: [],
        geocodeFailed: true,
        isUpdating: false,
        isCopying: false,
        showNoRolesWarning: false,
        participantDownloadFile: null,
        addedAdmins: [],
        componentKey: 0,
        contactNotifications: {
          contacts: [],
          notificationFrequency: 0
        },
        contactNotificationInputMail: '',
        participantsToRemove: []
      }
    },
    metaInfo () {
      return {
        meta: [
          { property: 'og:title', content: this.translation.name },
          { property: 'og:url', content: window.location.href },
          { property: 'og:image', content: this.sprEvent.image }
        ]
      }
    },
    computed: {
      profileId () {
        if (this.$store.state.profile.profileId) { return this.$store.state.profile.profileId } else { return null }
      },
      frequencyDefinition () {
        if (this.hasContactNotifications) {
          return this.getNotificationFrequencyTranslation(this.contactNotifications.notificationFrequency)
        }

        return this.$t('userAdmin.sectionReportNoDefinition')
      },

      hasContactNotifications () {
        return this.contactNotifications.contacts &&
          this.contactNotifications.contacts.length > 0
      },
      contactsReadOnly () {
        if (this.contactNotifications?.contacts && this.contactNotifications?.contacts.length > 0) {
          return this.contactNotifications.contacts.map(c => c.email).join('; ')
        }

        return ''
      },
      recurringOptions: function () {
        var options = []
        var option1 = this.$t('eventPage.removeThisEvent')
        var option2 = this.$t('eventPage.removeAllEvents')
        options.push({ value: 0, text: option1 })
        options.push({ value: 1, text: option2 })

        return options
      },
      recurringDeleteModeToBoolean: function () {
        if (this.recurringDeleteMode === 1) {
          return true
        }

        return false
      },
      eventOrganizerData () {
        if (!this.sprEvent.eventOrganizer) {
          return null
        }

        return {
          eventOrganizerType:
            this.sprEvent.eventOrganizer.eventOrganizerType >= 0
              ? Number(this.sprEvent.eventOrganizer.eventOrganizerType)
              : null,
          organizerId: this.sprEvent.eventOrganizer.organizerId
            ? Number(this.sprEvent.eventOrganizer.organizerId)
            : null,
          id: this.sprEvent.eventOrganizer.id
            ? Number(this.sprEvent.eventOrganizer.id)
            : null
        }
      },
      imageLib: function () {
        if (!this.selectImage) {
          return []
        }

        if (this.selectImage === 1) {
          return this.$store.state.eventBackgrounds
        } else {
          return _.map(this.$store.state.eventThumbs, function (x) {
            return { url: x, prev: x }
          })
        }
      },
      modalButton: function () {
        return this.isParticipant
          ? this.$t('eventPage.leave')
          : this.$t('eventPage.joinEvent')
      },
      validationErrors: function () {
        if (this.suppressErrors) {
          return []
        }

        var rv = _.map(this.errors.items, function (x) {
          return [x.field, x.rule, '']
        })

        if (this.postError) {
          rv.push(['', this.postError, ''])
        }

        if (!this.sprEvent.eventRoles || this.sprEvent.eventRoles.length === 0) {
          rv.push(['eventPage.rolesRequired', null, ''])
        }

        return rv
      },
      showMessageFull: function () {
        return (this.sprEvent.isFull || this.sprEvent.isClosed) &&
          !this.isParticipant && !this.isCreator && !this.isAdd
      },
      showJoinAction: function () {
        if (
          ((this.sprEvent.isFull || this.sprEvent.isClosed) &&
            !this.isParticipant) || this.sprEvent.eventRegistration === 2 //event registration is None
        ) {
          return false
        }
        return !this.isCreator && !this.isAdd
      },
      possibleTranslations: function () {
        var self = this
        var translations = _.map(this.$store.state.allLanguages, function (x) {
          if (x) {
            x.text = self.normalizeTranslation(x.isoCode)
            return x
          }
        })

        // null it to disable language limitations...
        if (!self.availableLanguages) {
          return translations
        }

        return _.filter(translations, function (y) {
          return (
            self.availableLanguages.indexOf(y.id) >= 0 &&
            !_.find(self.availableTranslations, function (z) {
              return z.languageId === y.id
            })
          )
        })
      },
      availableTranslations: function () {
        var self = this
        return _.map(this.sprEvent.translations, function (x) {
          const lng = _.find(self.$store.state.allLanguages, function (y) {
            return y.id === x.languageId
          })

          return {
            languageId: x.languageId,
            text: self.normalizeTranslation(lng.isoCode)
          }
        })
      },
      translation: function () {
        if (this.eventEditOpen) {
          return this.currentTranslation
        }
        // eventTranslation is defined in event.js
        // and takes the translations object from sprEvent
        // to find the right translation.
        return this.eventTranslation(this.sprEvent)
      },
      isCreator () {
        return (
          this.$store.state.profile &&
          this.$store.state.profile.profileId === this.sprEvent.creatorId
        )
      },
      isEventAdmin () {
        var self = this
        return (
          this.isLoggedIn &&
          _.find(this.sprEvent.eventAdmins, function (admin) {
            return (
              admin.email.toLowerCase() ===
              self.jwtToken[
                'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name'
              ].toLowerCase()
            )
          }) !== undefined
        )
      },
      isParticipant () {
        return this.currentRole != null || this.publicRole != null
      },
      isLoggedIn () {
        return this.$store.state.profile != null
      },
      // find a participant in the event that is equal to the current user,
      // and you know that this user plays a role in the event.
      currentRole () {
        const self = this
        let participantFound
        if (self.$store.state.profile) {
          for (var i = 0; i < this.sprEvent.eventRoles.length; i++) {
            participantFound = _.find(
              self.sprEvent.eventRoles[i].participants,
              function (participant) {
                return (
                  participant.profileId === self.$store.state.profile.profileId
                )
              }
            )

            if (participantFound) {
              return this.sprEvent.eventRoles[i]
            }
          }
        }

        return null
      },
      publicRole () {
        var self = this
        // not logged in so can only check if they have registered this session
        const roles = _.find(this.$store.state.publicEvents, function (role) {
          return role.eventId === self.sprEvent.id
        })
        return roles
      },
      eventHasStarted () {
        var st = this.sprEvent.startTime
        var now = moment()
        var start = moment([st.year, st.month - 1, st.day, st.hour, st.minute])

        return now > start
      },
      // Hero section computed properties
      heroImageStyle: function () {
        if (this.sprEvent.image) {
          return `display:block;background-image: url(${this.sprEvent.image})`
        }
        return ''
      },
      thumbImageStyle: function () {
        if (this.sprEvent.imageThumb) {
          return `display:block;background-image: url(${this.sprEvent.imageThumb})`
        }
        return ''
      },
      computedCategory: function () {
        return this.getProfileInvolvedEventCategoriesMaskTranslation(this.sprEvent.category)
      },
      eventCategories: function () {
        var self = this
        return _.map(this.$store.state.eventCategories, function (val, key) {
          return { value: val.id, text: self.$t('eventCategory.' + val.text) }
        })
      },
      involvedEventCategoriesOptions: function () { //Array<SprCheckboxBitmaskValue>
        var self = this
        var validItems = _.filter(this.$store.state.involvedEventCategories, (x) => x.id !== 0)

        return _.map(validItems, function (val, key) {
          return { value: val.id, text: self.$t('involvedEventCategories.' + val.text).toString() }
        })
      },
      computedType: function () {
        return this.isVolunteerTask
          ? this.getEventTypesTranslation(this.volunteerTaskTypeId)
          : this.getEventTypesMaskTranslation(this.sprEvent.type)
      },
      eventTypes: function () {
        var self = this
        return _.map(
          _.filter(this.$store.state.eventTypes, function (i) {
            return i.text !== 'volunteerTask'
          }),
          function (val, key) {
            return {
              value: val.id,
              text: self.$t('eventTypes.' + val.text)
            }
          }
        )
      },
      volunteerTaskTypeId: function () {
        return _.find(this.$store.state.eventTypes, { text: 'volunteerTask' }).id
      },
      // Date and Time computed properties
      occurrenceText: function () {
        return this.$t('eventPage.occurrenceText')
          .replace(
            '{occurrence}',
            this.getEventRecurrenceTranslation(
              this.sprEvent.occurance
            ).toLowerCase()
          )
          .replace('{repeat}', this.sprEvent.repeat)
          .replace(
            '{until}',
            moment(this.sprEvent.lastOccurrence, 'YYYY-MM-DDTHH:mm:ss').format(
              'DD.MM.YYYY'
            )
          )
      },
      recurrenceFrequencyOptions: function () {
        var self = this
        return _.map(
          this.$store.state.eventRecurrenceFrequencies,
          function (val, key) {
            return { value: val.id, text: self.$t('eventOccurance.' + val.text) }
          }
        )
      },
      /**
       * Returns the external URL of the event if defined for the user's selected language.
       * Else returns the first found external URL in any language.
       * If no external URLs are defined, returns _undefined_.
       */
      externalUrl: function () {
        var self = this
        return (
          this.translation.externalUrl ||
          _.get(_.find(self.sprEvent.translations, 'externalUrl'), 'externalUrl')
        )
      },
      eventStartTime: {
        get () {
          return {
            hour: this.sprEvent.startTime.hour,
            minute: this.sprEvent.startTime.minute
          }
        },
        set (val) {
          this.sprEvent.startTime.hour = val.hour
          this.sprEvent.startTime.minute = val.minute
        }
      },
      eventEndTime: {
        get () {
          return {
            hour: this.sprEvent.endTime.hour,
            minute: this.sprEvent.endTime.minute
          }
        },
        set (val) {
          this.sprEvent.endTime.hour = val.hour
          this.sprEvent.endTime.minute = val.minute
        }
      },
      validatorDate () {
        return moment([
          this.sprEvent.startTime.year,
          this.sprEvent.startTime.month - 1,
          this.sprEvent.startTime.day
        ]).format('DD.MM.YYYY')
      },
      displayStartDate: {
        get: function () {
          return moment([
            this.sprEvent.startTime.year,
            this.sprEvent.startTime.month - 1,
            this.sprEvent.startTime.day
          ]).format('DD.MM.YYYY')
        },
        set: function (newValue) {
          var d = moment(newValue, 'DD.MM.YYYY')
          this.sprEvent.startTime.year = d.year()
          this.sprEvent.startTime.month = d.month() + 1
          this.sprEvent.startTime.day = d.date()
        }
      },
      formattedStartTime: function () {
        return this.formatTime(
          this.sprEvent.startTime.hour,
          this.sprEvent.startTime.minute
        )
      },
      displayEndDate: {
        get: function () {
          return moment([
            this.sprEvent.endTime.year,
            this.sprEvent.endTime.month - 1,
            this.sprEvent.endTime.day
          ]).format('DD.MM.YYYY')
        },
        set: function (newValue) {
          var d = moment(newValue, 'DD.MM.YYYY')
          this.sprEvent.endTime.year = d.year()
          this.sprEvent.endTime.month = d.month() + 1
          this.sprEvent.endTime.day = d.date()
        }
      },
      formattedEndTime: function () {
        return this.formatTime(
          this.sprEvent.endTime.hour,
          this.sprEvent.endTime.minute
        )
      },
      formattedStartDate: function () {
        return this.startTimeDate.format('DD.MM.YYYY')
      },
      formattedEndDate: function () {
        return this.endTimeDate.format('DD.MM.YYYY')
      },
      startTimeDate () {
        return moment([
          this.sprEvent.startTime.year,
          this.sprEvent.startTime.month - 1,
          this.sprEvent.startTime.day,
          this.sprEvent.startTime.hour,
          this.sprEvent.startTime.minute
        ])
      },
      endTimeDate () {
        return moment([
          this.sprEvent.endTime.year,
          this.sprEvent.endTime.month - 1,
          this.sprEvent.endTime.day,
          this.sprEvent.endTime.hour,
          this.sprEvent.endTime.minute
        ])
      },
      registrationDeadline: {
        get () {
          return this.sprEvent.registrationDeadline
            ? moment.utc(this.sprEvent.registrationDeadline).format('DD.MM.YYYY')
            : null
        },
        set (val) {
          this.sprEvent.registrationDeadline = val
            ? moment(val, 'DD.MM.YYYY').endOf('day').toDate()
            : null
        }
      },
      displayRegistrationDeadline () {
        if (this.sprEvent.registrationDeadline) {
          return moment
            .utc(this.sprEvent.registrationDeadline)
            .local()
            .format('DD.MM.YYYY')
        }
        return ''
      },
      isRegistrationDeadlinePassed () {
        const result =
          this.sprEvent.registrationDeadline &&
          moment.utc(this.sprEvent.registrationDeadline) < moment()

        return result
      },
      registrationDeadlineLimit () {
        return [
          {
            type: 'fromto',
            to: moment(this.startTimeDate).add(1, 'day').format('YYYY-MM-DD')
          }
        ]
      },
      recurringUntilLimit () {
        const available = []
        for (let i = 2; i <= 52; i++) {
          const occurence = this.calculateRecurringUntil(
            this.startTimeDate,
            this.recurrenceFrequency,
            i
          )
          available.push(occurence.format('YYYY-MM-DD'))
        }
        return [
          {
            type: 'days',
            available: available
          }
        ]
      },
      // Location computed properties
      hasLocation: function () {
        return (
          this.sprEvent.streetAddress !== '' &&
          this.sprEvent.city !== '' &&
          this.sprEvent.zipCode !== ''
        )
      },
      formattedLocation: function () {
        const locationText = [
          this.translation.placeName,
          this.translation.streetAddress
        ]
          .filter(Boolean)
          .join(', ')
        return this.formatAddress(
          locationText,
          this.sprEvent.zipCode,
          this.translation.city
        )
      },
      formattedGeocodeLocation: function () {
        return `${this.translation.streetAddress},${this.translation.city},${this.sprEvent.zipCode}`
      },
      showEditRoles: function () {
        return (
          this.isAdd || (this.isEdit && this.eventEditOpen)
        )
      },
      contactNotificationFrequencies () {
        const self = this
        const result = _.map(
          this.$store.state.reportFrequency,
          function (val, key) {
            return {
              value: val.id,
              text: self.$t('userAdmin.sectionReportFrequency' + val.text)
            }
          }
        )
        return result
      },
      eventRegistrationOptions () { // options for eventregistration are external, Oma or None
        const self = this
        const result = _.map(
          this.$store.state.eventRegistration,
          function (val, key) {
            return {
              value: val.id,
              text: self.$t('userAdmin.eventRegistration' + val.text)
            }
          }
        )
        return result
      },
      showViewRoles: function () {
        return !this.showEditRoles && !this.externalUrl
      }
    },
    watch: {
      startTimeDate: function (newVal, oldVal) {
        if (oldVal.year() !== 0 && !this.isUpdating && !this.isCopying) {
          // ignore possible initial value
          // calculate duration between old and new val
          const diff = moment.duration(newVal.diff(oldVal))
          // also calculate if/how many days were adjusted
          const diffDays = moment.duration(
            moment(newVal).startOf('day').diff(moment(oldVal).startOf('day'))
          )
          // adjust end time by calculated duration
          const endTime = moment(this.endTimeDate).add(diff)
          this.sprEvent.endTime.year = endTime.year()
          this.sprEvent.endTime.month = endTime.month() + 1
          this.sprEvent.endTime.day = endTime.date()
          this.sprEvent.endTime.hour = endTime.hour()
          this.sprEvent.endTime.minute = endTime.minute()
          // adjust registration deadline if set and starting day was changed
          if (this.registrationDeadline) {
            this.registrationDeadline = moment(
              this.registrationDeadline,
              'DD.MM.YYYY'
            ).add(diffDays)
          }
          // recalculate recurrence info
          if (this.isAdd) this.onRecurringEventChange(this.isRecurring)
        }
      },
      sprEvent () {
        this.setActionLanguages()
      }
    },
    created () {
      this.zipCodes = this.$store.state.zipCodes.map((z, i) => {
        return { value: i, text: z.zipCode }
      })
      this.setActionLanguages()
      this.$store.subscribe((mutation, state) => {
        if (mutation.type === 'setLanguage') {
          this.componentKey += 1
        }
      })
    },
    mounted () {
      if (this.$route.params.id) {
        this.sprEvent.id = Number(this.$route.params.id)
        const self = this
        this.loadEvent(this.sprEvent.id, function (response) {
          self.selectedZipLocation = self.zipCodes.find(
            (z) => z.text === self.sprEvent.zipCode
          )
          if (
            self.$route.params.mode === 'edit' ||
            self.hasRole('Admin') ||
            self.hasRole('NationalAdmin') ||
            self.isCreator ||
            self.isEventAdmin
          ) {
            self.isEdit = true
          }
          if (self.$route.params.mode === 'join') {
            if (!self.isCreator) {
              self.eventStartJoinButton()
            }
          }
        })
      } else {
        this.addNewEvent()
        // Set default section as creators section
        if (this.jwtToken && this.jwtToken.SectionMembership) {
          this.sprEvent.sectionId = Number(this.jwtToken.SectionMembership)
          this.sprEvent.eventOrganizer.eventOrganizerType = 200 // Section
          this.sprEvent.eventOrganizer.organizerId = this.sprEvent.sectionId
        }

        this.newTranslation(54)
        this.changeEditorLanguage(54, true)

        this.sprEvent.eventRoles = [
          {
            eventId: 0,
            maximumParticipants: 0,
            name: 'Osallistuja'
          }
        ]
        this.sprEvent.eventAdmins = [
          {
            email:
              this.jwtToken[
                'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name'
              ],
            isCreator: true
          }
        ]
      }
    },
    beforeRouteLeave (to, from, next) {
      if (this.selectImage) {
        this.selectImage = null
        // If the image list is visible, prevent navigation
        // You can also handle other conditions here if needed
        next(false) // Prevent navigation
      } else {
        // Allow navigation if the image list is not visible
        next()
      }
    },
    methods: {
      addNewEvent () {
        // check permissions.
        if (
          !this.hasRole('Admin') &&
          !this.hasRole('Employee') &&
          !this.hasRole('System') &&
          !this.hasRole('NationalAdmin') &&
          // NationalEmployee has the right to access Event without given Id. This is the logical conclusion,
          // because it is neither Update, nor Deletion of an Event, so should be considered equal to NationalAdmin
          // in my interpretation:
          !this.hasRole('NationalEmployee') &&
          !this.jwtToken.ActionGroupMembership
        ) {
          this.$router.push('/front')
        }

        this.setAddMode()
        // the event is being created by this person (profile) so the profile Id can already be assigned to the event
        this.sprEvent.creatorId = this.$store.state.profile.profileId
        this.isAdd = true

        const startTime = this.nextHour()
        const endTime = this.nextHour().add(1, 'hour')

        this.sprEvent.startTime.year = startTime.year()
        // month +1, because moment months start counting at 0
        this.sprEvent.startTime.month = startTime.month() + 1
        this.sprEvent.startTime.day = startTime.date()
        this.sprEvent.startTime.hour = startTime.hour()
        this.sprEvent.startTime.minute = startTime.minute()

        this.sprEvent.endTime.year = endTime.year()
        // month +1, because moment months start counting at 0
        this.sprEvent.endTime.month = endTime.month() + 1
        this.sprEvent.endTime.day = endTime.date()
        this.sprEvent.endTime.hour = endTime.hour()
        this.sprEvent.endTime.minute = endTime.minute()
      },
      updateContacts (contactNotifications) {
        const url =
          this.$store.state.apiBases.eventservice +
          this.$store.state.apiEndpoints.setContactNotifications[0].replace(
            '{0}',
            this.sprEvent.id
          )

        this.$http.post(url, contactNotifications).then(
          (s) => {
            // @ts-ignore cannot find mixins
            this.showAlertSuccess(this.$t('userAdmin.saveSuccess'))
            this.getContacts()
          },
          (fail) => {
            // @ts-ignore cannot find mixins
            this.showAlertError(this.$t('common.fetchFailed'))
          }
        )
      },
      getContacts () {
        if (!(this.hasRole('Admin') || this.hasRole('NationalAdmin') || this.isCreator || this.isEventAdmin)) return

        var url =
          this.$store.state.apiBases.userservice +
          this.$store.state.apiEndpoints.getContactNotifications[0].replace(
            '{0}',
            this.sprEvent.id
          )
        this.$http.get(url).then(
          (s) => {
            this.contactNotifications.notificationFrequency = s.body.notificationFrequency
            this.contactNotifications.contacts = s.body.contacts.map((c) => { return { email: c.email } })
          },
          (f) => {
            if (f.status !== 401) { this.showAlertError(this.$t('common.fetchFailed')) }
          }
        )
      },

      getNotificationFrequencyTranslation (frequency) {
        const item = _.filter(this.$store.state.reportFrequency, (x) => {
          return x.id === frequency
        })

        if (item && item.length > 0) {
          return this.$t('userAdmin.sectionReportFrequency' + item[0].text)
        } else {
          return this.$t('userAdmin.sectionReportFrequencyNone')
        }
      },
      onRemoveContact (contact) {
        this.contactNotifications.contacts = this.contactNotifications.contacts.filter(
          (x) => x.email.toLowerCase() !== contact.email.toLowerCase()
        )
      },
      isValid () {
        if (this.sectionContacts.length > 0) {
          if (this.sprEvent.notificationFrequency === 0) {
            return false
          }
        }

        return true
      },
      onAddContactNotification () {
        this.$validator
          .validate('contactNotifications.contactemail')
          .then((resp) => {
            if (resp) {
              const email = this.contactNotificationInputMail.toLowerCase()
              if (
                !this.contactNotifications.contacts.find(
                  (contact) => contact.email.toLowerCase() === email
                )
              ) {
                this.contactNotifications.contacts.push({ email: email })
              }
            }
            this.contactNotificationInputMail = null
          })
      },
      setMessageFrequency (val) {
        this.recurrenceFrequency = val
      },
      onIsZipCodeChanged (val) {
        const value = val || { text: '' }
        this.selectedZipLocation = this.zipCodes.find(
          (z) => z.text === value.text
        )
        const newZipCode = this.$store.state.zipCodes.find(
          (z) => z.zipCode === value.text
        )
        this.currentTranslation.city = newZipCode ? newZipCode.cityFi : ''
        this.sprEvent.zipCode = value.text
      },
      onLanguageChanged (e) {
        this.sprEvent.languages = e.reduce((b, v) => b + v.id, 0)
        this.selectedlanguages = e
      },
      setActionLanguages () {
        const lang = this.$store.state.customerLanguages
        this.languages = this.$store.state.groupLanguages
          .filter((l) => l.id !== 0)
          .map((v) => {
            return {
              id: v.id,
              text:
                'actionLanguage.' +
                lang.find(
                  (l) => l.displayName.toUpperCase() === v.text.toUpperCase()
                ).isoCode
            }
          })
        this.selectedlanguages = this.languages.filter((v) =>
          v.id === null || v.id === 0
            ? false
            : (v.id & this.sprEvent.languages) === v.id
        )
      },
      focusOnErrors () {
        window.scrollTo({ top: 0, behavior: 'smooth' })
      },
      setLibraryImage: function () {
        if (this.selectImage === 1) {
          this.sprEvent.image = this.libImage
        } else {
          this.sprEvent.imageThumb = this.libImage
        }

        this.selectImage = null
        window.scrollTo(0, 0)
      },
      cancelModal: function () {
        this.joinStage = 0
        this.showModal = false
        this.removeEvent = false
      },
      hideRemoveModal () {
        this.$refs.removeModal.hide()
      },
      loginJoin: function () {
        this.$router.push('/login?from=' + this.$route.path + '/join')
      },
      anonJoin: function () {
        this.joinStage = 2
        this.$nextTick(function () {
          window.scrollTo(0, document.body.scrollHeight)
        })
      },
      registerJoin: function () {
        sessionStorage.setItem('postloginredirect', this.$route.path + '/join')
        this.$router.push({ name: 'wizard' })
      },
      eventStartJoinButton: function () {
        if (this.externalUrl) {
          const joinAuditUrl =
            this.$store.state.apiBases.eventservice +
            this.$store.state.apiEndpoints.externaleventlog[0]
          const data = {
            evt: this.sprEvent
          }
          this.$http.post(joinAuditUrl, data).then(() => {
            window.location.href = this.externalUrl
          })
        } else {
          this.isEdit = true
          this.showModal = true
          if (this.isLoggedIn) {
            this.joinStage = 2
          } else {
            if (this.isParticipant) {
              this.joinStage = 2
            } else {
              this.joinStage = 1
            }
          }
        }
      },
      newTranslationInject: function () {
        if (this.langToAdd && this.langToAdd.id) {
          this.newTranslation(this.langToAdd.id)
        }
      },
      newTranslation: function (id) {
        if (!id) {
          return
        }
        if (
          _.find(this.availableTranslations, function (x) {
            return x.languageId === id
          })
        ) {
          return
        }

        this.sprEvent.translations.push({
          city: '',
          description: '',
          languageId: id,
          name: '',
          placeName: '',
          price: '',
          streetAddress: ''
        })
        this.changeEditorLanguage(id, true)
      },
      deleteTranslation: function (id) {
        const cur = this.currentTranslation.languageId
        if (id !== this.currentTranslation.languageId) {
          this.sprEvent.translations = _.filter(
            this.sprEvent.translations,
            function (x) {
              return x.languageId !== id
            }
          )
        }
        this.changeEditorLanguage(cur)
      },
      changeEditorLanguage: function (val, skipValidation) {
        const self = this
        if (skipValidation) {
          self.currentTranslation = _.find(
            self.sprEvent.translations,
            function (x) {
              return x.languageId === val
            }
          )
        } else {
          this.suppressErrors = false
          self.$validator.validate().then((v) => {
            if (v) {
              self.currentTranslation = _.find(
                self.sprEvent.translations,
                function (x) {
                  return x.languageId === val
                }
              )
            }
          })
        }
      },
      loadEvent (id, callback, bustCache) {
        this.isUpdating = true
        let url =
          this.$store.state.apiBases.eventservice +
          this.$store.state.apiEndpoints.eventget[0].replace('{id}', id)

        if (bustCache) {
          const rand = Math.random() * 1000000
          url += `?rnd=${rand}`
        }

        this.$http.get(url).then(
          (response) => {
            this.sprEvent = response.body
            this.sprEvent.oldImage = this.sprEvent.image
            this.sprEvent.oldImageThumb = this.sprEvent.imageThumb

            // TODO(JanneF): Select language as current language (for editing) more elegant way
            this.currentTranslation = this.translation
            var eventMetaImage = this.sprEvent.image
            if (eventMetaImage === '') {
              eventMetaImage =
                'https://sprvatistorage.blob.core.windows.net/eventimages/event_25.jpg'
            }
            if (!eventMetaImage.startsWith('http')) {
              eventMetaImage = ''
            }
            this.isVolunteerTask =
              this.sprEvent.type === this.volunteerTaskTypeId
            this.isUpdating = false
            this.getContacts()
            if (callback) {
              callback(response)
            }
          },
          (response) => {
            if (response.status === 403) {
              // logged out but accessing a non public event
              this.$router.replace(`/login?from=/event/${this.sprEvent.id}`)
            } else {
              this.$router.replace('/events')
            }
          }
        )
      },
      setAddMode () {
        this.eventEditOpen =
          this.timeEditorOpen =
          this.locationEditorOpen =
          this.feeEditorOpen =
          this.contactEditorOpen =
          this.descriptionEditorOpen =
          this.isEditAdminsOpen =
          this.contactNotificationsOpen =
          true
      },
      setEditMode () {
        this.eventEditOpen =
          this.timeEditorOpen =
          this.locationEditorOpen =
          this.feeEditorOpen =
          this.contactEditorOpen =
          this.descriptionEditorOpen =
          this.isEditAdminsOpen =
          this.contactNotificationsOpen =
          false
      },
      eventPublishButton: function () {
        this.isCopying = false
        this.suppressErrors = false
        if (this.isVolunteerTask) {
          let id = 0
          if (this.sprEvent.endTime) {
            id = this.sprEvent.endTime.$id
            this.sprEvent.endTime = _.cloneDeep(this.sprEvent.startTime)
            this.sprEvent.endTime.$id = id
          } else {
            this.sprEvent.endTime = _.cloneDeep(this.sprEvent.startTime)
          }
          this.sprEvent.type = this.volunteerTaskTypeId
        }
        this.$validator.validate().then((result) => {
          if (result) {
            return this.save()
          } else {
            this.$refs.validation.scrollIntoView()
          }
        })
      },
      save () {
        this.isUpdating = true
        this.postError = null

        if (this.sprEvent.isAllDay) {
          this.sprEvent.startTime.hour = 0
          this.sprEvent.startTime.minute = 0
          this.sprEvent.endTime.hour = 23
          this.sprEvent.endTime.minute = 59
        }

        if (this.isAdd) {
          if (this.sprEvent.eventRoles.length === 0) {
            this.isUpdating = false
            this.showNoRolesWarning = true
            const addRolesEl = this.$refs.editeventroles
            const top = addRolesEl.offsetTop
            window.scrollTo(0, top)
            return
          }

          const addurl =
            this.$store.state.apiBases.eventservice +
            this.$store.state.apiEndpoints.eventadd[0]

          return this.$http
            .post(addurl, this.sprEvent)
            .then((resp) => {
              this.sprEvent.id = Number(resp.body)
              this.updateContacts(this.contactNotifications)
              // send messages to the users
              return this.sendEventAdminMessages()
            })
            .catch((fail) => {
              this.postError = fail.body
              return this.focusOnErrors()
            })
            .then((resp) => {
              if (!this.postError) {
                this.$router.replace(`/event/${this.sprEvent.id}`)
              }
              this.isUpdating = false
            })
        } else {
          const updateurl =
            this.$store.state.apiBases.eventservice +
            this.$store.state.apiEndpoints.eventupdate[0]

          this.$http
            .put(updateurl, this.sprEvent)
            .then(() => {
              return this.sendEventAdminMessages()
            })
            .then((resp) => {
              this.isUpdating = false

              this.updateContacts(this.contactNotifications)

              this.loadEvent(this.sprEvent.id, null, true)
              this.setEditMode()
            })
        }
      },
      sendEventAdminMessages () {
        if (this.addedAdmins?.length) {
          const url = `${
            this.$store.state.apiBases.messageservice +
            this.$store.state.apiEndpoints.sendneweventadminmessages[0]
          }`
          const newEventAdminMessageDto = {
            emails: this.addedAdmins,
            eventId: this.sprEvent.id
          }
          return this.$http.post(url, newEventAdminMessageDto)
        }
      },
      showMap () {
        this.geocodeFailed = false
        this.proxiedGeocode(this.formattedGeocodeLocation).then(
          (resp) => {
            if (resp.body.results.length) {
              const place = resp.body.results[0].geometry.location
              var marker = {
                id: 1,
                position: {
                  lat: place.lat,
                  lng: place.lng
                }
              }
              this.$set(this.markers, 0, marker)
              this.mapCenter = marker.position
              this.mapZoom = 13
              this.mapIsVisible = true
            } else {
              this.mapIsVisible = true
              this.geocodeFailed = true
            }
          },
          (fail) => {
            this.mapIsVisible = true
            this.geocodeFailed = true
          }
        )
      },
      onBackgroundFileChange: function (e) {
        var files = e.target.files || e.dataTransfer.files
        if (!files.length) {
          return
        }
        this.createImage(files[0], 'mainimage')
      },
      onThumbFileChange: function (e) {
        var files = e.target.files || e.dataTransfer.files
        if (!files.length) {
          return
        }
        this.createImage(files[0], 'thumbimage')
      },
      createImage: function (file, saveAs) {
        var reader = new FileReader()
        reader.onload = (e) => {
          if (saveAs === 'mainimage') {
            this.sprEvent.image = e.target.result
          } else {
            this.sprEvent.imageThumb = e.target.result
          }
        }
        reader.readAsDataURL(file)
      },
      eventEditButton: function () {
        this.eventEditOpen = !this.eventEditOpen
      },
      eventCopyButton: function () {
        // Let the creation process of event know that we are copying. This is for the purpose of preserving valid start/end date combinations.
        this.isCopying = true
        // Create a copy
        this.addNewEvent()
        // Post process the new copied event, with the purpose of removing existing id's.
        this.cleanUpAfterCopying()
      },
      cleanUpAfterCopying () {
        // when copying only accept one occurrence.
        this.sprEvent.occurrence = 1

        // month -1, because moment months start counting at 0
        this.sprEvent.registrationDeadline = moment.utc([this.sprEvent.startTime.year, this.sprEvent.startTime.month - 1, this.sprEvent.startTime.day, 0, 0, 0, 0])

        // remove all participants from the available roles and remove the role id's from the roles, to prevent inserting existing id's to the database
        if (this.sprEvent.eventRoles) {
          this.sprEvent.eventRoles.forEach(eventRole => {
            delete eventRole.id
            this.sprEvent.eventRoles.forEach(eventRole => {
              // clear all participants
              eventRole.participants = []
            })
          })
        }
      },
      eventCancelButton: function () {
        if (this.isAdd) {
          this.$router.replace('/events')
          return
        }
        this.loadEvent(this.sprEvent.id, null, true)
        this.setEditMode()
        this.isEdit = true
      },
      eventRemoveButton: function () {
        this.removeEvent = true
      },
      confirmEventRemoval: function () {
        this.showModal = false
        var self = this
        var deleteUrl =
          self.$store.state.apiBases.eventservice +
          this.$store.state.apiEndpoints.eventdelete[0]
            .replace('{id}', self.sprEvent.id)
            .replace('{recurring}', this.recurringDeleteModeToBoolean)

        return this.$http.delete(deleteUrl).then((resp) => {
          this.$router.push('/events')
        })
      },
      onDownloadParticipants () {
        var fields = [
          'firstname',
          'lastname',
          'created',
          'phone',
          'email',
          'zipcode',
          'role',
          'participated',
          'more info'
        ]
        var participants = []
        for (var i = 0; i < this.sprEvent.eventRoles.length; i++) {
          var role = this.sprEvent.eventRoles[i]
          for (var j = 0; j < role.participants.length; j++) {
            var participant = role.participants[j]
            participants.push({
              firstname: participant.firstName,
              lastname: participant.lastName,
              created: moment
                .utc(participant.created)
                .local()
                .format('YYYY-MM-DD HH:mm'),
              phone: `${participant.phone}`,
              email: participant.email,
              zipcode: `${participant.zipCode}`,
              role: role.name,
              participated: participant.participated,
              'more info': participant.moreInfo
            })
          }
        }
        const csv =
          '\ufeffSEP=,\n' +
          json2csv.parse(participants, { fields: fields, delimiter: ',' })

        var textFileAsBlob = new Blob([csv], { type: 'text/csv;charset=utf-8' })
        var eventTimeNormalised = moment().format('YYYY-MM-DD-HHMM')
        var eventNameNormalised = this.currentTranslation.name
          .toLowerCase()
          .replace(' ', '_')
          .replace(/[^a-z0-9\s]/gi, '')
        var fileNameToSaveAs = `${eventTimeNormalised}-${eventNameNormalised}-participants.csv`

        // avoid memory leak from people continually clicking
        if (this.participantDownloadFile !== null) {
          window.URL.revokeObjectURL(this.participantDownloadFile)
        }

        this.participantDownloadFile = window.URL.createObjectURL(textFileAsBlob)

        if (window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveBlob(textFileAsBlob, fileNameToSaveAs)
        } else {
          var downloadLink = document.createElement('a')
          downloadLink.download = fileNameToSaveAs
          downloadLink.innerHTML = 'Download file'
          downloadLink.href = this.participantDownloadFile
          downloadLink.onclick = function (event) {
            document.body.removeChild(event.target)
          }
          downloadLink.style.display = 'none'
          document.body.appendChild(downloadLink)
          downloadLink.click()
        }
      },
      onRemoveParticipants (val) {
        const p = _.find(
          _.find(this.sprEvent.eventRoles, ['name', val.role]).participants,
          ['id', val.id]
        )
        // Check if p.id exists in the participantsToRemove array
        if (this.participantsToRemove.includes(p.id)) {
          // If it exists, remove it from the list
          const indexToRemove = this.participantsToRemove.indexOf(p.id)
          if (indexToRemove !== -1) {
            this.participantsToRemove.splice(indexToRemove, 1)
          }
        } else {
          // If it doesn't exist, add it to the list
          this.participantsToRemove.push(p.id)
        }
        //this.save()
      },
      removeParticipants () {
        if (confirm(this.$t('eventPage.removalConfirm').toString())) {
          var removalUrl = `${
            this.$store.state.apiBases.eventservice +
            this.$store.state.apiEndpoints.eventremoveparticipants[0]}`

          return this.$http.post(removalUrl, this.participantsToRemove).then((resp) => {
            this.showModal = false
            this.isUpdating = false
            this.loadEvent(this.sprEvent.id, null, true)
          })
        }
      },
      onParticipationChanged (val) {
        const p = _.find(
          _.find(this.sprEvent.eventRoles, ['name', val.role]).participants,
          ['id', val.id]
        )
        p.participated = !p.participated
        this.save()
      },
      onEventJoin (participant) {
        this.isUpdating = true
        var joinurl = `${
          this.$store.state.apiBases.eventservice +
          this.$store.state.apiEndpoints.eventjoin[0]
        }`
        var data = {
          roleIds: participant.roleIds,
          moreInfo: participant.moreinfo
        }
        const payload = { payload1: data, payload2: this.sprEvent }
        return this.$http.post(joinurl, payload).then((resp) => {
          this.showModal = false
          this.isUpdating = false
          this.loadEvent(this.sprEvent.id, null, true)
        })
      },
      onEventJoinPublic (participant) {
        this.isUpdating = true
        const joinurl = `${
          this.$store.state.apiBases.eventservice +
          this.$store.state.apiEndpoints.eventjoinpublic[0]
        }`
        const payload = { payload1: participant, payload2: this.sprEvent }
        return this.$http.post(joinurl, payload).then((resp) => {
          const eventInfo = {
            eventId: this.sprEvent.id,
            name: this.sprEvent.name,
            roleIds: resp.body.roleIds,
            lightProfileId: resp.body.lightProfileId,
            moreInfo: resp.body.moreInfo,
            cancellationToken: resp.body.cancellationToken,
            firstName: resp.body.firstName,
            lastName: resp.body.lastName,
            email: resp.body.email,
            phone: resp.body.phone,
            zipCode: resp.body.zipCode
          }

          // store the eventInfo if there are any roles (left over)
          if (resp.body.roleIds && resp.body.roleIds.length > 0) {
            this.$store.commit('addPublicEvent', eventInfo)
          } else {
            this.$store.commit('removePublicEvent', eventInfo)
            this.$router.replace(`/event/cancel/${resp.body.cancellationToken}`)
          }

          this.showModal = false
          this.isUpdating = false
          this.loadEvent(this.sprEvent.id, null, true)
        }, (error) => {
          if (error.body === 'eventPage.errorAnonymousParticipation') {
            this.showAlertError(this.$t(error.body))
            this.showModal = false
            this.isUpdating = false

            this.loadEvent(this.sprEvent.id, null, true)
          }
        })
      },
      onAddAdmin (adminemail) {
        var newAdmin = {
          email: adminemail,
          isCreator: false,
          eventId: this.sprEvent.id
        }
        this.sprEvent.eventAdmins.push(newAdmin)
        this.addedAdmins.push(adminemail)
      },
      onRemoveAdmin (admin) {
        this.sprEvent.eventAdmins = this.sprEvent.eventAdmins.filter(
          (x) => x !== admin
        )
        this.addedAdmins = this.addedAdmins.filter((x) => x !== admin.email)
      },
      onEventOrganizerChanged (eventOrganizer) {
        this.sprEvent.eventOrganizer = eventOrganizer
      },
      adminsEditButton () {
        this.isEditAdminsOpen = !this.isEditAdminsOpen
      },
      timeEditButton: function () {
        this.timeEditorOpen = !this.timeEditorOpen
      },
      locationEditButton: function () {
        this.locationEditorOpen = !this.locationEditorOpen
      },
      feeEditButton: function () {
        this.feeEditorOpen = !this.feeEditorOpen
      },
      contactEditButton: function () {
        this.contactEditorOpen = !this.contactEditorOpen
      },
      contactNotificationsButton: function () {
        this.contactNotificationsOpen = !this.contactNotificationsOpen
      },
      descriptionEditButton: function () {
        this.descriptionEditorOpen = !this.descriptionEditorOpen
      },
      getTimeArr (strTime) {
        if (!strTime) return [0, 0]
        const startTimeArr = strTime.split(':')

        if (startTimeArr.length !== 2) {
          startTimeArr[0] = 0
          startTimeArr[1] = 0
        }

        let hr = Number(startTimeArr[0])
        let min = Number(startTimeArr[1])

        if (isNaN(hr)) hr = 0
        if (isNaN(min)) min = 0

        if (hr > 23) hr = 0
        if (min > 59) min = 0

        return [hr, min]
      },
      formatTime (hr, min) {
        let strHr = hr.toString()
        if (hr <= 9) strHr = `0${hr}`

        let strMin = min.toString()
        if (min <= 9) strMin = `0${min}`

        return `${strHr}:${strMin}`
      },
      nextHour: function () {
        var m = moment()
        var roundUp =
          m.minute() || m.second() || m.millisecond()
            ? m.add(1, 'hour').startOf('hour')
            : m.startOf('hour')
        return roundUp
      },
      onConfirmDelete (value) {
        this.confirmDelete = value
      },
      onRecurringEventChange (isRecurring) {
        if (isRecurring) {
          // Update recurrence fields with whichever frequency was set (initially 2).
          this.onFrequencyUpdate(this.recurrenceFrequency)
        } else {
          // Set event model to single occurrence.
          this.sprEvent.occurance = 1
        }
        this.isRecurring = isRecurring
      },
      onFrequencyUpdate (freq) {
        // Set occurrence to chosen frequency.
        this.recurrenceFrequency = this.sprEvent.occurance = freq
        // Update recurring until field based on whatever repetition count was set (initially 1).
        this.onRepetitionsUpdate(this.sprEvent.repeat)
      },
      onRecurringUntilUpdate (untilDateString) {
        this.recurringUntil = untilDateString
        this.sprEvent.repeat = this.calculateRepetitions(
          this.recurrenceFrequency,
          untilDateString
        )
      },
      onRepetitionsUpdate (reps) {
        this.sprEvent.repeat = reps
        this.recurringUntil = this.calculateRecurringUntil(
          this.startTimeDate,
          this.recurrenceFrequency,
          reps
        ).format('DD.MM.YYYY')
      },
      calculateRecurringUntil (start, frequency, repetitions) {
        const untilDate = moment(start)
        repetitions -= 1
        switch (frequency) {
        case 2:
          untilDate.add(repetitions, 'weeks')
          break
        case 3:
          untilDate.add(repetitions * 2, 'weeks')
          break
        case 4:
          untilDate.add(repetitions, 'months')
          break
        }
        return untilDate
      },
      calculateRepetitions (frequency, until) {
        const fromDate = moment(this.startTimeDate).startOf('day')
        const untilDate = moment(until, 'DD.MM.YYYY')
        let repetitions = 1
        switch (frequency) {
        case 2:
          repetitions = untilDate.diff(fromDate, 'weeks')
          break
        case 3:
          repetitions = untilDate.diff(fromDate, 'weeks') / 2
          break
        case 4:
          repetitions = untilDate.diff(fromDate, 'months')
          break
        }
        return repetitions + 1
      },
      onRegularEventClick () {
        this.isVolunteerTask = false
        this.sprEvent.isPublic = true
        if (this.sprEvent.type === this.volunteerTaskTypeId) {
          this.sprEvent.type = 11 // Other = 11
        }
      },
      onVolunteerTaskClick () {
        this.isVolunteerTask = true
        this.sprEvent.isPublic = true
      }
    }
  }
</script>

<style lang="scss">
@import "../../assets/scss/_variables.scss";
.contact-notifications {
  .pill {
    margin-right: 5px;

    &.readonly {
      border-color: $brand-grey7;
      color: $brand-grey7;
      background-color: $brand-grey1;
      cursor: not-allowed;
    }
  }

  .frequency-selector {
    .comp-radio-group-key {
      padding-top: 10px;
      .spr-simple-radio {
        padding-left: 20px;
      }
    }
  }

  .emailinput {
    display: inline-block;
    width: 100%;
  }
  .add-button {
    display: inline-block;
  }
  .is-edit {
    color: $brand-red;
    padding: 0 20px;
  }
  .frequency {
    color: $brand-red;
    font-family: $font-bold;
    padding: 0 20px;
  }
  .editor-header {
    a {
      color: $brand-red;
      font-family: $font-bold;
      cursor: pointer;
      text-decoration: none;
      text-transform: uppercase;
    }

    .action-icon {
      color: $brand-red;
      font-family: $font-bold;
      cursor: pointer;
      text-decoration: none;
      text-transform: uppercase;
      margin-right: 5px;

      .saveChanges {
        color: $brand-success;
      }
    }
  }

  .editor-area {
    margin-bottom: 10px;
    textarea {
      width: calc(100% - 15px);
      resize: none;
      margin-top: 10px;
      font-family: "Signa-Book", Sans-Serif;
      font-size: 14px;
      line-height: 20px;
    }
  }

  .spr-danger {
    border: 2px solid $brand-red !important;
  }
}
.message-full {
  margin-top: 10px;
  height: 60px; /* Set a higher container height */
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $brand-white;
  /* Optionally, for visualization purposes */
  border: 1px solid $brand-white;
  .message-text {
    text-align: center;
    color: $brand-red;
  }
}
.subheader {
    color:$brand-red;
    text-transform: uppercase;
    font-family: $font-bold;
    margin: 0px 0 20px 0;
    display:block;
    font-size: 14px;
  }

.vertical-container {
  display: flex;
  justify-content: flex-start;
  align-content: center;
  flex-direction: column;
}
.cap-label-language {
  flex: 1 0 auto;
}
.language-content {
  flex: 1 0 auto;
  align-self: flex-start;
  margin-bottom: 30px;
  margin-left: 1px;
}

.cap-label {
  white-space: nowrap;
}

.search-item {
  margin-top: unset;
  margin-left: unset;
}

.multiselect__single {
  font-weight: normal;
}

.multiselect__tags {
  min-height: 37px;
  border: solid 2px $brand-grey3;
  input {
    border: none;
    outline: none;
  }
}

.top-space {
  margin-top: 10px;
}

.modal-container {
  .button-width {
    width: 300px;
    max-width: 100%;
  }
}

.modal-footer {
  .close-link {
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
}

.event-page {
  .custom-file {
    border: 2px solid $brand-grey3;
    border-radius: 5px;
    position: relative;

    span {
      position: absolute;
      top: 0px;
      left: 0px;
      bottom: 0px;
      right: 0px;

      line-height: 34px;
      padding-left: 0px;
    }
  }

  .space-between-row {
    display: flex;
    justify-content: space-between;
  }

  .cap-label {
    margin-bottom: 8px;
  }

  .button-width {
    min-width: 300px;
    max-width: 100%;
  }

  .select-thumb {
    width: 100%;
    border-radius: 5px;
    box-sizing: border-box;
    border: 2px solid $brand-white;

    &.selected {
      border: 2px solid $brand-red;
      box-shadow: 0 0 10px $brand-red;
    }
  }

  .recurring {
    margin-bottom: 25px;
  }

  .back-header {
    color: $brand-red;

    svg {
      padding-right: 7px;
    }

    &:hover {
      text-decoration: underline;
    }
  }

  .translation {
    display: block;
    padding: 3px;

    button {
      display: inline-block;
      background: none;
      border: none;
      color: $brand-red;
      font-size: 18px;
      font-family: Signa-Light;
      padding: 7px;
      cursor: pointer;

      &.disabled {
        cursor: not-allowed;
        background: $brand-grey1 !important;
        border: 2px solid $brand-grey1 !important;
        color: black !important;
      }

      &.language-name {
        border-radius: 30px;
        border: 2px solid $brand-red;

        &:hover {
          background: $brand-red;
          color: $brand-white;
        }

        &.selected {
          background: $brand-red;
          color: $brand-white;
        }
      }

      &.remove-language {
        color: $brand-red;
        font-size: 14px;
        padding-left: 10;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  width: 100%;
  background: $brand-white;

  .updating {
    position: fixed;
    display: flex;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    height: 100%;
    width: 100%;
    z-index: 10000000;
    background-color: rgba(255, 255, 255, 0.8);

    .spinner {
      margin: auto;
      color: $brand-red;
    }
  }

  .portrait-size {
    width: 60px;
    height: 60px;
    border: 2px solid $brand-white;
  }
  .hero {
    min-height: 300px;
    background-image: url(https://sprvatistorage.blob.core.windows.net/eventimages/defaultevent_12.jpg);
    background-repeat: no-repeat;
    background-position: center center;

    color: $brand-white;
    position: relative;

    .event-title {
      background: $brand-white;
      color: $brand-red;
    }

    .eventimage {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      background-color: $brand-grey10;
      background-repeat: no-repeat;
      background-position: center center;
      display: none;
    }

    .thumbimage {
      position: absolute;
      width: 100px;
      height: 100px;
      right: 50px;
      top: 50px;
      background-size: cover;
      border: 2px solid $brand-red;
      background-color: $brand-grey10;
      background-repeat: no-repeat;
      background-position: center center;
      display: none;
    }

    &:before {
      content: "";
      display: block;
      background: rgba(0, 0, 0, 0.4);
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 0;
    }
    .container {
      z-index: 1;
      position: relative;
    }
    h1 {
      width: 60%;
      text-transform: uppercase;
      font-family: Signa-Black !important;
      margin: 20px 0;
      display: block;
      min-height: 62px;
    }

    .category {
      min-height: 20px;
    }

    .type {
      font-size: 15px;
    }

    .mode,
    .category,
    .department {
      display: block;
      font-family: Signa-Bold;
      font-size: 18px;
    }
    .actions {
      text-align: left;
      margin: 40px 0 0 0;
      display: block;
      position: relative;

      .organizer {
        float: right;
        text-transform: uppercase;
        font-weight: bold;
        font-family: signa-light;
        letter-spacing: 1px;
        width: 230px;
      }
    }
    .spr-button {
      flex: 1 0 auto;
      border: 1px solid rgba(0, 0, 0, 0.5);
      background: $brand-grey10;
      color: $brand-white;
      height: 45px;
      &:hover {
        color: $brand-grey15;
      }
    }
  }

  .event-content {
    width: 100%;
    .edit-row {
      padding: 20px 0;
      border-top: 5px solid $brand-red;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);

      &.hero-area {
        .upload {
          margin-bottom: 5px;
        }
      }

      .volunteertask-filter {
        padding: 0 30px 15px;

        a {
          color: $brand-grey8;
          cursor: pointer;
          padding: 10px 5px;
          display: inline-block;

          &.selected {
            color: $brand-red;
            font-weight: bold;
          }
        }
      }
    }
    .col-border {
      border-left: 1px solid $brand-grey1;
      border-right: 1px solid $brand-grey1;
      padding: 0 25px;
      .event-block {
        font-family: Signa-Book;
        font-weight: normal;
      }
    }
    .event-block {
      font-family: Signa-Light;
      font-weight: bold;
      padding: 25px 0;
      position: relative;
      border-bottom: 1px solid $brand-grey1;
       &.secondary {
        font-family: Signa-Book;
        font-weight: normal;
      }
      &:first-of-type {
        padding-top: 40px;
        .edit-corner {
          top: 28px;
        }
      }
      &:last-of-type {
        border: 0;
      }
      &.time {
        .add-to-calendars {
          svg {
            margin-right: 5px;
          }
        }
        padding-bottom: 9px;
      }
      button {
        margin-bottom: 10px;
      }
      .icon {
        color: $brand-red;
        font-size: 48px;
        line-height: 48px;
      }
      .grey-text {
        color: $brand-grey7;
        font-size: 14px;
      }
    }

    .event-fee .icon {
      border-radius: 50%;
      border: 2px solid $brand-red;
      font-size: 22px;
      line-height: 22px;
      height: 40px;
      width: 40px;
      display: inline-block;
      svg {
        margin: 7px 10px 10px 10px;
      }
    }

    .contact {
      .contact-person {
        margin-bottom: 10px;
        font-weight: bold;
      }
    }

    .cap-label {
      color: $brand-red;
      margin-bottom: 10px;
      display: block;
    }
  }
  .edit-corner {
    position: absolute;
    right: 0px;
    top: 15px;
    z-index: 2;
    padding: 10px;
  }

  .spr-datepicker {
    margin-bottom: 15px;

    .datepickbox {
      position: relative;
      input {
        position: relative;
        z-index: 1;
        box-shadow: none !important;
        border: solid 2px $brand-grey3 !important;
        background: none !important;
        width: 100%;
      }
    }
  }
  .spr-timepicker {
    margin-bottom: 15px;
  }
  .spr-simple-checkbox {
    font-weight: normal;
  }

  .edit-area {
    .edit-label {
      color: #000 !important;
      margin-bottom: 20px !important;
      display: block;
    }
    .field-row {
      padding-top: 20px;
    }
    .repetitions {
      font-weight: normal;

      .wrap {
        margin-top: 27px;
      }

      @media (max-width: $media-sm-max) {
        .wrap {
          margin-top: 0;
        }
      }

      .spr-simple-numberinput {
        margin: 0 15px;
        max-width: 80px;
        display: inline-block;

        input {
          height: 44px;
        }

        @media (max-width: $media-sm-max) {
          label {
            display: none;
          }
        }
      }
      .prefix {
        margin: 15px 0 0 0;
        @media (max-width: $media-sm-max) {
          margin: 0 0 16px 15px;
        }
      }
      .suffix {
        margin: 15px 0 0 0;
        @media (max-width: $media-sm-max) {
          margin: 0 0 16px 0;
        }
      }
    }
  }
  .editor-header a {
    text-transform: uppercase;
    font-family: Signa-Light;
    font-weight: bold;
  }

  .spr-simple-textarea {
    .cap-label {
      padding-left: 0;
    }
    textarea {
      color: #000 !important;
      line-height: 18px;
      border-radius: 0 !important;
    }
  }

  .participants {
    margin-top: 30px;
  }

  .footer-actions {
    padding-top: 30px;
    padding-bottom: 15px;
    text-align: center;
    border-bottom: solid 1px $brand-grey3;
  }

  @media (max-width: $media-sm-max) {
    .hero {
      padding: 60px 0;
      .actions {
        top: 48px;
      }
    }
    .col-border {
      border: 0 !important;
      padding: 0 15px !important;
    }

    .footer-actions {
      .spr-button {
        margin-bottom: 5px;
      }
    }
  }

  @media (max-width: $media-xs-max) {
    .hero {
      padding: 1px 0;
      h1 {
        width: 100%;
        font-size: 30px !important;
        line-height: 34px !important;
      }
      .actions {
        top: 27px;
        .spr-button {
          margin-bottom: 5px;
        }
        .organizer {
          width: 100%;
          padding: 15px;
          .col-xs-8 {
            padding-left: 15px !important;
          }
        }
      }
    }
    .event-content .col-border {
      padding: 0 20px;
    }
  }

  @media (min-width: $media-sm-max) {
    .hero {
      padding: 60px 0;
      .actions {
        top: 48px;
      }
    }
  }
}
</style>
