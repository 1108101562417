import { render, staticRenderFns } from "./CustomerPreview.vue?vue&type=template&id=41664626"
import script from "./CustomerPreview.vue?vue&type=script&lang=ts"
export * from "./CustomerPreview.vue?vue&type=script&lang=ts"
import style0 from "./CustomerPreview.vue?vue&type=style&index=0&id=41664626&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports