import Vue from 'vue'
import Vuex from 'vuex'
import JwtDecode from 'jwt-decode'
import { GroupType } from '../types/index'
require('es6-promise/auto')
Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    selectedContact: [],
    selectedVolunteers: [],
    optionalBooleanValues: [
      { id: 0, text: 'notDefined' },
      { id: 1, text: 'true' },
      { id: 2, text: 'false' }
    ],
    sectionRoles: [],
    enableRegisterToJoinFlow: true,
    translations: {},
    adminBack: [null, null, null, null],
    sections: [],
    districts: [],
    institutions: [],
    netFriendshipGroups: [],
    language: sessionStorage.getItem('locale')
      ? sessionStorage.getItem('locale')
      : 'fi',
    languages: ['fi', 'sv', 'en', 'none'],
    languagesJoin: ['fi', 'sv', 'en'],
    allLanguages: null,
    profilePersonalSkills: [
      { id: 1, text: 'meetingPeople' },
      { id: 2, text: 'cookingAndBaking' },
      { id: 3, text: 'eventHosting' },
      { id: 4, text: 'publicPerformances' },
      { id: 5, text: 'organizational' },
      { id: 6, text: 'teamGuide' },
      { id: 7, text: 'trainPeople' },
      { id: 8, text: 'eventOrganizaton' },
      { id: 9, text: 'photographyAndVideo' },
      { id: 10, text: 'digitalCompetence' },
      { id: 11, text: 'communicationAndMarketing' },
      { id: 12, text: 'financeAndAccounting' },
      { id: 13, text: 'healthCareOrFirstAid' },
      { id: 14, text: 'rescue' }
    ],
    profileAreasOfInterest: [
      { id: 1, text: 'workWithYoung' },
      { id: 2, text: 'workWithElderly' },
      { id: 3, text: 'immigrantSupport' },
      { id: 4, text: 'guideAndTeach' },
      { id: 5, text: 'organiseActivities' },
      { id: 6, text: 'promoteWellbeing' },
      { id: 7, text: 'collectDonations' },
      { id: 8, text: 'workInATeam' },
      { id: 9, text: 'firstAid' },
      { id: 10, text: 'helpInCrisis' },
      { id: 11, text: 'workInMulticulturalEnvironment' },
      { id: 12, text: 'foodHelp' }
    ],
    profileState: [
      { id: 1, text: 'activeOpen' },
      { id: 2, text: 'activeNoContact' },
      { id: 3, text: 'activeNoNew' },
      { id: 4, text: 'inactive' }
    ],
    registrationLanguages: [
      'fi',
      'sv',
      'en',
      'ru',
      'et',
      'so',
      'ar',
      'fa'
    ],
    // Todo: Obsolete these, use customerLanguages
    friendCustomerLanguages: [
      { value: 'fi', text: 'langFinnish' },
      { value: 'sv', text: 'langSwedish' },
      { value: 'en', text: 'langEnglish' },
      { value: 'ru', text: 'langRussian' },
      { value: 'so', text: 'langSomali' },
      { value: 'ar', text: 'langArab' },
      { value: 'fa', text: 'langFars' },
      { value: 'uk', text: 'langUkrainian' },
      { value: 'none', text: 'langNone' }
    ],
    customerLanguages: [],
    groupLanguages: [],
    genders: [],
    preferredGenders: [],
    preferredAge: [],
    preferredContactMethods: [],
    availabilityFrequency: [],
    reportFrequency: [
      { id: 1, text: 'Daily' },
      { id: 7, text: 'Weekly' }
    ],
    notificationFrequency: [
      { id: 0, text: 'None' },
      { id: 1, text: 'Daily' },
      { id: 7, text: 'Weekly' }
    ],
    eventRegistration: [
      { id: 0, text: 'Oma' },
      { id: 1, text: 'External' },
      { id: 2, text: 'None' }
    ],
    databaseAvailabilityTimes: [],
    availabilityTimes: [],
    customerTypes: [],
    netCustomerTypes: [],
    friendshipTypes: [],
    netFriendshipTypes: [],
    customerInterests: [],
    customerStates: [],
    involvedEventCategories: [],
    eventCategories: [],
    zipCodes: [],
    groupTypes: function () {
      return Object.keys(GroupType).map(key => { return { id: Number(key), text: GroupType[key] } })
    },
    eventTypes: [
      { id: 1, text: 'event' },
      { id: 2, text: 'practice' },
      { id: 4, text: 'collection' },
      { id: 8, text: 'club' },
      { id: 16, text: 'meeting' },
      { id: 32, text: 'coursesAndTraining' },
      { id: 64, text: 'camp' },
      { id: 128, text: 'groupMeeting' },
      { id: 1024, text: 'volunteerTask' },
      { id: 256, text: 'publicEvent' },
      { id: 512, text: 'other' }
    ],
    eventRecurrenceFrequencies: [
      { id: 2, text: 'weekly' },
      { id: 3, text: 'biweekly' },
      { id: 4, text: 'monthly' }
    ],
    eventBackgrounds: [
      { url: 'https://sprvatistorage.blob.core.windows.net/eventimages/defaultevent_1.jpg', prev: 'https://sprvatistorage.blob.core.windows.net/eventimages/defaultevent_1t.jpg' },
      { url: 'https://sprvatistorage.blob.core.windows.net/eventimages/defaultevent_2.jpg', prev: 'https://sprvatistorage.blob.core.windows.net/eventimages/defaultevent_2t.jpg' },
      { url: 'https://sprvatistorage.blob.core.windows.net/eventimages/defaultevent_3.jpg', prev: 'https://sprvatistorage.blob.core.windows.net/eventimages/defaultevent_3t.jpg' },
      { url: 'https://sprvatistorage.blob.core.windows.net/eventimages/defaultevent_4.jpg', prev: 'https://sprvatistorage.blob.core.windows.net/eventimages/defaultevent_4t.jpg' },
      { url: 'https://sprvatistorage.blob.core.windows.net/eventimages/defaultevent_5.jpg', prev: 'https://sprvatistorage.blob.core.windows.net/eventimages/defaultevent_5t.jpg' },
      { url: 'https://sprvatistorage.blob.core.windows.net/eventimages/defaultevent_6.jpg', prev: 'https://sprvatistorage.blob.core.windows.net/eventimages/defaultevent_6t.jpg' },
      { url: 'https://sprvatistorage.blob.core.windows.net/eventimages/defaultevent_7.jpg', prev: 'https://sprvatistorage.blob.core.windows.net/eventimages/defaultevent_7t.jpg' },
      { url: 'https://sprvatistorage.blob.core.windows.net/eventimages/defaultevent_8.jpg', prev: 'https://sprvatistorage.blob.core.windows.net/eventimages/defaultevent_8t.jpg' },
      { url: 'https://sprvatistorage.blob.core.windows.net/eventimages/defaultevent_9.jpg', prev: 'https://sprvatistorage.blob.core.windows.net/eventimages/defaultevent_9t.jpg' },
      { url: 'https://sprvatistorage.blob.core.windows.net/eventimages/defaultevent_10.jpg', prev: 'https://sprvatistorage.blob.core.windows.net/eventimages/defaultevent_10t.jpg' },
      { url: 'https://sprvatistorage.blob.core.windows.net/eventimages/defaultevent_11.jpg', prev: 'https://sprvatistorage.blob.core.windows.net/eventimages/defaultevent_11t.jpg' },
      { url: 'https://sprvatistorage.blob.core.windows.net/eventimages/defaultevent_12.jpg', prev: 'https://sprvatistorage.blob.core.windows.net/eventimages/defaultevent_12t.jpg' },
      { url: 'https://sprvatistorage.blob.core.windows.net/eventimages/defaultevent_13.jpg', prev: 'https://sprvatistorage.blob.core.windows.net/eventimages/defaultevent_13t.jpg' },
      { url: 'https://sprvatistorage.blob.core.windows.net/eventimages/defaultevent_14.jpg', prev: 'https://sprvatistorage.blob.core.windows.net/eventimages/defaultevent_14t.jpg' },
      { url: 'https://sprvatistorage.blob.core.windows.net/eventimages/defaultevent_15.jpg', prev: 'https://sprvatistorage.blob.core.windows.net/eventimages/defaultevent_15t.jpg' },
      { url: 'https://sprvatistorage.blob.core.windows.net/eventimages/defaultevent_16.jpg', prev: 'https://sprvatistorage.blob.core.windows.net/eventimages/defaultevent_16t.jpg' },
      // { url: 'https://sprvatistorage.blob.core.windows.net/eventimages/defaultevent_17.jpg', prev: 'https://sprvatistorage.blob.core.windows.net/eventimages/defaultevent_17t.jpg' },
      // { url: 'https://sprvatistorage.blob.core.windows.net/eventimages/defaultevent_18.jpg', prev: 'https://sprvatistorage.blob.core.windows.net/eventimages/defaultevent_18t.jpg' },
      // { url: 'https://sprvatistorage.blob.core.windows.net/eventimages/defaultevent_19.jpg', prev: 'https://sprvatistorage.blob.core.windows.net/eventimages/defaultevent_19t.jpg' },
      // { url: 'https://sprvatistorage.blob.core.windows.net/eventimages/defaultevent_20.jpg', prev: 'https://sprvatistorage.blob.core.windows.net/eventimages/defaultevent_20t.jpg' },
      // { url: 'https://sprvatistorage.blob.core.windows.net/eventimages/defaultevent_21.jpg', prev: 'https://sprvatistorage.blob.core.windows.net/eventimages/defaultevent_21t.jpg' },
      // { url: 'https://sprvatistorage.blob.core.windows.net/eventimages/defaultevent_22.jpg', prev: 'https://sprvatistorage.blob.core.windows.net/eventimages/defaultevent_22t.jpg' },
      // { url: 'https://sprvatistorage.blob.core.windows.net/eventimages/defaultevent_23.jpg', prev: 'https://sprvatistorage.blob.core.windows.net/eventimages/defaultevent_23t.jpg' },
      // { url: 'https://sprvatistorage.blob.core.windows.net/eventimages/defaultevent_24.jpg', prev: 'https://sprvatistorage.blob.core.windows.net/eventimages/defaultevent_24t.jpg' },
      // { url: 'https://sprvatistorage.blob.core.windows.net/eventimages/defaultevent_25.jpg', prev: 'https://sprvatistorage.blob.core.windows.net/eventimages/defaultevent_25t.jpg' },
      // { url: 'https://sprvatistorage.blob.core.windows.net/eventimages/defaultevent_26.jpg', prev: 'https://sprvatistorage.blob.core.windows.net/eventimages/defaultevent_26t.jpg' },
      // { url: 'https://sprvatistorage.blob.core.windows.net/eventimages/defaultevent_27.jpg', prev: 'https://sprvatistorage.blob.core.windows.net/eventimages/defaultevent_27t.jpg' },
      // { url: 'https://sprvatistorage.blob.core.windows.net/eventimages/defaultevent_28.jpg', prev: 'https://sprvatistorage.blob.core.windows.net/eventimages/defaultevent_28t.jpg' },
      // { url: 'https://sprvatistorage.blob.core.windows.net/eventimages/defaultevent_29.jpg', prev: 'https://sprvatistorage.blob.core.windows.net/eventimages/defaultevent_29t.jpg' },
      // { url: 'https://sprvatistorage.blob.core.windows.net/eventimages/defaultevent_30.jpg', prev: 'https://sprvatistorage.blob.core.windows.net/eventimages/defaultevent_30t.jpg' },
      // { url: 'https://sprvatistorage.blob.core.windows.net/eventimages/defaultevent_31.jpg', prev: 'https://sprvatistorage.blob.core.windows.net/eventimages/defaultevent_31t.jpg' }
      { url: 'https://sprvatistorage.blob.core.windows.net/eventimages/System/2021_01_1.jpg', prev: 'https://sprvatistorage.blob.core.windows.net/eventimages/System/2021_01_1t.jpg' },
      { url: 'https://sprvatistorage.blob.core.windows.net/eventimages/System/2021_01_2.jpg', prev: 'https://sprvatistorage.blob.core.windows.net/eventimages/System/2021_01_2t.jpg' },
      { url: 'https://sprvatistorage.blob.core.windows.net/eventimages/System/2021_01_3.jpg', prev: 'https://sprvatistorage.blob.core.windows.net/eventimages/System/2021_01_3t.jpg' },
      { url: 'https://sprvatistorage.blob.core.windows.net/eventimages/System/2021_01_4.jpg', prev: 'https://sprvatistorage.blob.core.windows.net/eventimages/System/2021_01_4t.jpg' },
      { url: 'https://sprvatistorage.blob.core.windows.net/eventimages/System/2021_01_5.jpg', prev: 'https://sprvatistorage.blob.core.windows.net/eventimages/System/2021_01_5t.jpg' },
      { url: 'https://sprvatistorage.blob.core.windows.net/eventimages/System/2021_01_6.jpg', prev: 'https://sprvatistorage.blob.core.windows.net/eventimages/System/2021_01_6t.jpg' },
      { url: 'https://sprvatistorage.blob.core.windows.net/eventimages/System/2021_05_1.jpg', prev: 'https://sprvatistorage.blob.core.windows.net/eventimages/System/2021_05_1t.jpg' }
    ],
    eventThumbs: [
      'https://sprvatistorage.blob.core.windows.net/eventimages/defaultevent_1t.jpg',
      'https://sprvatistorage.blob.core.windows.net/eventimages/defaultevent_2t.jpg',
      'https://sprvatistorage.blob.core.windows.net/eventimages/defaultevent_3t.jpg',
      'https://sprvatistorage.blob.core.windows.net/eventimages/defaultevent_4t.jpg',
      'https://sprvatistorage.blob.core.windows.net/eventimages/defaultevent_5t.jpg',
      'https://sprvatistorage.blob.core.windows.net/eventimages/defaultevent_6t.jpg',
      'https://sprvatistorage.blob.core.windows.net/eventimages/defaultevent_7t.jpg',
      'https://sprvatistorage.blob.core.windows.net/eventimages/defaultevent_8t.jpg',
      'https://sprvatistorage.blob.core.windows.net/eventimages/defaultevent_9t.jpg',
      'https://sprvatistorage.blob.core.windows.net/eventimages/defaultevent_10t.jpg',
      'https://sprvatistorage.blob.core.windows.net/eventimages/defaultevent_11t.jpg',
      'https://sprvatistorage.blob.core.windows.net/eventimages/defaultevent_12t.jpg',
      'https://sprvatistorage.blob.core.windows.net/eventimages/defaultevent_13t.jpg',
      'https://sprvatistorage.blob.core.windows.net/eventimages/defaultevent_14t.jpg',
      'https://sprvatistorage.blob.core.windows.net/eventimages/defaultevent_15t.jpg',
      'https://sprvatistorage.blob.core.windows.net/eventimages/defaultevent_16t.jpg',
      // 'https://sprvatistorage.blob.core.windows.net/eventimages/defaultevent_17t.jpg',
      // 'https://sprvatistorage.blob.core.windows.net/eventimages/defaultevent_18t.jpg',
      // 'https://sprvatistorage.blob.core.windows.net/eventimages/defaultevent_19t.jpg',
      // 'https://sprvatistorage.blob.core.windows.net/eventimages/defaultevent_21t.jpg',
      // 'https://sprvatistorage.blob.core.windows.net/eventimages/defaultevent_22t.jpg',
      // 'https://sprvatistorage.blob.core.windows.net/eventimages/defaultevent_23t.jpg',
      // 'https://sprvatistorage.blob.core.windows.net/eventimages/defaultevent_24t.jpg',
      // 'https://sprvatistorage.blob.core.windows.net/eventimages/defaultevent_25t.jpg',
      // 'https://sprvatistorage.blob.core.windows.net/eventimages/defaultevent_26t.jpg',
      // 'https://sprvatistorage.blob.core.windows.net/eventimages/defaultevent_27t.jpg',
      // 'https://sprvatistorage.blob.core.windows.net/eventimages/defaultevent_28t.jpg',
      // 'https://sprvatistorage.blob.core.windows.net/eventimages/defaultevent_29t.jpg',
      // 'https://sprvatistorage.blob.core.windows.net/eventimages/defaultevent_30t.jpg',
      // 'https://sprvatistorage.blob.core.windows.net/eventimages/defaultevent_31t.jpg'
      'https://sprvatistorage.blob.core.windows.net/eventimages/System/2021_01_1t.jpg',
      'https://sprvatistorage.blob.core.windows.net/eventimages/System/2021_01_2t.jpg',
      'https://sprvatistorage.blob.core.windows.net/eventimages/System/2021_01_3t.jpg',
      'https://sprvatistorage.blob.core.windows.net/eventimages/System/2021_01_4t.jpg',
      'https://sprvatistorage.blob.core.windows.net/eventimages/System/2021_01_5t.jpg',
      'https://sprvatistorage.blob.core.windows.net/eventimages/System/2021_01_6t.jpg',
      'https://sprvatistorage.blob.core.windows.net/eventimages/System/2021_05_1t.jpg'
    ],
    eventDefault: {
      hero:
        'https://sprvatistorage.blob.core.windows.net/eventimages/defaultevent_15.jpg',
      thumb:
        'https://sprvatistorage.blob.core.windows.net/eventimages/defaultevent_15t.jpg'
    },
    transportation: [
      { id: 1, text: 'own' },
      { id: 2, text: 'public' },
      { id: 3, text: 'walk' },
      { id: 4, text: 'bike' }
    ],
    poolStatus: {
      ACTIVE: 1,
      REMOVED: 2
    },
    wizardModel: {
      email: '',
      phoneNumber: '',
      firstName: '',
      lastName: '',
      streetAddress: '',
      zipCode: '',
      city: '',
      country: 'Suomi',
      age: '',
      travelTime: '',
      nativeLanguage: '',
      preferredContactMethods: 0,
      acceptMarketing: false,
      areasOfInterest: [],
      InvolvedEventCategories: 0,
      availabilityTimes: [],
      spokenLanguages: [],
      personalSkills: [],
      transportation: [],
      workingZipCode: '',
      sectionId: null,
      profileState: 1,
      story: null,
      volunteerOnline: false
    },
    searchFilter: {
      searchText: {
        customer: '',
        volunteer: ''
      },
      skip: 0,
      pagesize: 10,
      searchType: 'CUSTOMER',
      sortBy: 'NEWEST',
      customerStates: [],
      customerTypes: [],
      interests: [],
      friendshipTypes: [],
      languages: [],
      motherTongs: [],
      ages: []
    },
    publicEvents: [],
    selectedCustomer: {},
    selectedVolunteer: {},
    apiBases: {
      contentservice: 'https://spr-vati-oma-webapi.azurewebsites.net',
      userservice: 'https://spr-vati-oma-webapi.azurewebsites.net',
      friendservice: 'https://spr-vati-oma-webapi.azurewebsites.net',
      messageservice: 'https://spr-vati-oma-webapi.azurewebsites.net',
      eventservice: 'https://spr-vati-oma-webapi.azurewebsites.net',
      paymentservice: 'https://spr-vati-oma-webapi.azurewebsites.net',
      externalformservice: 'https://spr-vati-oma-webapi.azurewebsites.net'

    },
    apiEndpoints: {
      // Oma Web API
      omaWebApiVersion: ['/api/omaversion', 'GET', false],

      // Payment
      checkout: ['/api/payment/checkout', 'POST', false],
      paymentoptions: ['/api/payment/options/', 'GET', false],
      paymentcancel: ['/api/payment/cancel/', 'DELETE', false],
      producteditor: ['/api/product/editor', 'GET', true],
      productsave: ['/api/product', 'PUT', true],
      productlist: ['/api/product', 'GET', true],
      confirminvoice: ['/api/payment/invoice/', 'POST', true],
      membershipcustomer: ['/api/customer/', 'GET', true],

      // Content
      translations: ['/api/content/translations', 'POST', false],
      translationscached: ['/api/content/translations/cached', 'GET', false],
      allsections: ['/api/content/organization/sections', 'GET', false],
      joinablezipcodes: ['/api/content/organization/joinablezipcodes', 'GET', false],
      joinsections: ['/api/content/organization/joinsections', 'GET', false],
      alldistricts: ['/api/content/organization/districts', 'GET', false],
      allinstitutions: ['/api/content/organization/institutions', 'GET', false],
      transportations: ['/api/content/transportation', 'GET', false],
      personalskills: ['/api/content/personalskills', 'GET', false],
      languages: ['/api/content/languages', 'GET', false],
      areasofinterest: ['/api/content/areasofinterest', 'GET', false],
      genders: ['/api/content/enums/genders', 'GET', false],
      preferredgenders: ['/api/content/enums/preferredgenders', 'GET', false],
      preferredages: ['/api/content/enums/preferredages', 'GET', false],
      preferredcontactmethods: ['/api/content/enums/preferredcontactmethods', 'GET', false],
      availabilityfrequencies: ['/api/content/enums/availabilityfrequencies', 'GET', false],
      databaseAvailabilitytimes: ['/api/content/availabilitytimes', 'GET', false], // To be used with Profile.AvailabilityTimes
      availabilitytimes: ['/api/content/enums/availabilitytimes', 'GET', false],
      customertypes: ['/api/content/enums/customertypes', 'GET', false],
      netcustomertypes: ['/api/content/enums/netcustomertypes', 'GET', false],
      friendshiptypes: ['/api/content/enums/friendshiptypes', 'GET', false],
      netfriendshiptypes: ['/api/content/enums/netfriendshiptypes', 'GET', false],
      interests: ['/api/content/enums/interests', 'GET', false],
      customerstates: ['/api/content/enums/customerstates', 'GET', false],
      customerlanguages: ['/api/content/languages/customerlanguages', 'GET', false],
      eventcategories: ['/api/content/enums/eventcategories', 'GET', false],
      involvedeventcategories: ['/api/content/enums/involvedeventcategories', 'GET', false],

      // Authorisation
      login: ['/api/users/accounts/getlogintoken', 'POST', false],
      register: ['/api/users/accounts/register', 'POST', false],
      userupdate: ['/api/users/accounts/update', 'POST', true],
      adminupdate: ['/api/users/accounts/updateany/{email}', 'POST', true],
      expiredpasswordupdate: ['/api/users/accounts/updateexpiredpassword', 'POST', true],
      emailexists: ['/api/users/accounts/emailexists', 'GET', true],
      adauth: ['/api/users/accounts/adauthorize?token={token}&sessionState={session_state}', 'GET', false],
      adregister: ['/api/users/accounts/adregister', 'POST', true],
      restore: ['/api/users/accounts/restore/{email}', 'GET', false],
      resetpassword: ['/api/users/accounts/resetpassword', 'POST', true],
      logout: ['/api/users/accounts/logout', 'GET', true],

      // Users
      userbyprofileid: ['/api/users/user/byprofileid/{0}', 'GET', true],
      profile: ['/api/users/user/profile/{0}', 'GET', true],
      find: ['/api/users/user/find?from={from}&id={id}&purpose={purpose}&skip={skip}&take={take}&search={search}&exnid={exnid}', 'GET', true],
      findIdentOnly: ['/api/users/user/find?from={from}&id={id}&purpose={purpose}&skip={skip}&take={take}&exnid=true&search={search}', 'GET', true],
      findGroupVolunteers: ['/api/users/user/findgroupvolunteers?search={search}&skip={skip}&take={take}', 'GET', true],
      findSimpleIdentOnly: ['/api/users/user/simplefind?from={from}&id={id}&skip={skip}&take={take}&exnid=true&search={search}', 'GET', true],
      findEmployees: ['/api/users/user/employees?skip={skip}&take={take}&search={search}', 'GET', true],
      currentuser: ['/api/users/current', 'GET', true],
      updateidentuser: ['/api/users/user/{0}', 'PUT', true],
      updateownprofile: ['/api/users/user/updateownprofile', 'PUT', true],
      deleteuser: ['/api/users/user', 'DELETE', true],
      usermyprofileusage: ['/api/users/user/getmyprofileusage', 'GET', true],
      userprofileusagebyid: ['/api/users/user/getprofileusagebyid/', 'GET', true],
      deletemyprofile: ['/api/users/user/deletemyprofile', 'DELETE', true],
      profiledeleterequests: ['/api/users/user/getprofiledeleterequests', 'GET', true],
      sections: ['/api/users/organization/sections', 'GET', true],
      sectionsexport: ['/api/users/organization/sections/export', 'POST', true],
      sectionbyid: ['/api/users/organization/sections/{0}', 'GET', true],
      mysection: ['/api/users/organization/mysection', 'GET', true],
      section: ['/api/users/organization/section', 'GET', true],
      sectionsbydistrict: ['/api/users/organization/sections/from-district/{0}', 'GET', true],
      districts: ['/api/users/organization/districts', 'GET', true],
      district: ['/api/users/organization/districts/{0}', 'GET', true],
      setDistrictDescription: ['/api/users/organization/sections/set-description/{0}', 'POST', true],
      getSectionReport: ['/api/users/organization/sections/get-sectionreport/{0}', 'GET', true],
      setSectionReport: ['/api/users/organization/sections/set-sectionreport/{0}', 'POST', true],
      findbyfriendship: ['/api/users/user/findbyfriendshipstate?state={state}&skip={skip}&take={take}&search={search}', 'POST', true],
      roles: ['/api/users/user/roles/{0}', 'GET', true],
      membership: ['/api/crm/crmdata/getbyprofileid/{0}', 'GET', true],
      markcontacted: ['/api/users/user/markcontacted/{0}', 'GET', true],
      markuncontacted: ['/api/users/user/markuncontacted/{0}', 'GET', true],
      findsectionvolunteers: ['/api/users/organization/sectionVolunteers', 'POST', true],
      finddistrictvolunteers: ['/api/users/organization/districtvolunteers', 'POST', true],

      // Events
      eventadd: ['/api/events/event/add', 'POST', true],
      eventget: ['/api/events/event/get/{id}', 'GET', true],
      eventall: ['/api/events/event/getall?search={search}&locale={locale}&eventorganizertype={eventorganizertype}&organizerid={organizerid}&eventcategory={eventcategory}&eventtype={eventtype}&isonline={isonline}&take={take}&skip={skip}', 'GET', true],
      eventallpublic: ['/api/events/event/getallpublic?search={search}&locale={locale}&eventorganizertype={eventorganizertype}&organizerid={organizerid}&eventcategory={eventcategory}&eventtype={eventtype}&isonline={isonline}&take={take}&skip={skip}', 'GET', true],
      eventsbycriteria: ['/api/events/event/getbycriteria', 'POST', true],
      eventsbycriteriapublic: ['/api/events/event/getbycriteriapublic', 'POST', true],
      eventupdate: ['/api/events/event/update', 'PUT', true],
      eventdelete: ['/api/events/event/delete/{id}/{recurring}', 'DELETE', true],
      eventjoin: ['/api/events/event/join', 'POST', true],
      eventleave: ['/api/events/event/leave', 'POST', true],
      eventremoveparticipants: ['/api/events/event/removeparticipants', 'POST', true],
      eventjoinpublic: ['/api/events/event/joinpublic', 'POST', true],
      eventleavepublic: ['/api/events/event/leave?cancellationToken={cancellationToken}', 'GET', true],
      eventupcoming: ['/api/events/event/upcoming', 'GET', true],
      eventrecommended: ['/api/events/event/recommended', 'GET', true],
      eventnearby: ['/api/events/event/near', 'GET', true],
      eventpopular: ['/api/events/event/popular', 'GET', true],
      eventvolunteertasks: ['/api/events/event/volunteertasks', 'GET', true],
      eventorganised: ['/api/events/event/organized', 'GET', true],
      eventparticipated: ['/api/events/event/participated', 'GET', true],
      geocode: ['/api/events/event/geocode', 'GET', false],
      distance: ['/api/events/event/distance', 'GET', false],
      trainings: ['/api/events/event/gettraining/{0}', 'GET', false],
      externaleventlog: ['/api/events/event/externaleventclicked', 'POST', false],
      getContactNotifications: ['/api/events/event/GetNotifiedContacts/contacts/get-notifications/{0}', 'GET', true],
      setContactNotifications: ['/api/events/event/SetNotifiedContacts/contacts/set-notifications/{0}', 'POST', true],

      // External Forms
      externalformsadd: ['/api/ExternalForms/Add', 'POST', true],
      externalformsupdate: ['/api/ExternalForms/Update/{0}', 'PUT', true],
      externalformsdelete: ['/api/ExternalForms/Delete/{0}', 'DELETE', true],
      externalformsget: ['/api/ExternalForms/Get/{0}', 'GET', true],
      externalformsall: ['/api/ExternalForms/GetAll', 'Get', true],

      // Groups
      groupsall: ['/api/users/groups/all', 'GET', true],
      groupspersonal: ['/api/users/groups/getpersonalgroups', 'POST', true],
      groupsbysection: ['/api/users/groups/section/{0}', 'GET', true],
      groupsbydistrict: ['/api/users/groups/district/{0}', 'GET', true],
      groupmessages: ['/api/message/message/getbygroupid/{0}', 'GET', true],
      groupsectionadd: ['/api/users/groups/addtosection', 'POST', true],
      groupdistrictadd: ['/api/users/groups/addtodistrict', 'POST', true],
      groupjoin: ['/api/users/groups/join', 'PUT', true],
      grouprequestmembership: ['/api/users/groups/requestmembership', 'PUT', true],
      groupleave: ['/api/users/groups/leave/{0}', 'PUT', true],
      groupmakeadmin: ['/api/users/groups/makeadmin', 'PUT', true],
      groupremoveadmin: ['/api/users/groups/removeadmin', 'PUT', true],
      groupmygroups: ['/api/users/groups/mygroups', 'GET', true],
      groupgetbyid: ['/api/users/groups/getbyid', 'GET', true],
      groupisadmin: ['/api/users/groups/isgroupadmin', 'GET', true],
      groupgetbyidlight: ['/api/users/groups/getbyidlight', 'GET', true],
      groupupdate: ['/api/users/groups/update', 'PUT', true],
      groupdelete: ['/api/users/groups/delete/{id}', 'POST', true],
      groupaddmembers: ['/api/users/groups/addmembers', 'PUT', true],
      groupremmembers: ['/api/users/groups/removemembers', 'POST', true],
      groupremmembershiprequest: ['/api/users/groups/removemembershiprequest', 'POST', true],
      groupacceptinvitation: ['/api/users/groups/acceptinvitation/{0}', 'PUT', true],
      groupgethupsislink: ['/api/users/groups/gethupsislink/{iv}', 'GET', true],
      grouplanguages: ['/api/content/enums/grouplanguages', 'GET', false],

      // Friend - Friendship Management
      customeraddstandard: ['/api/friends/customer/standard/add', 'POST', true],
      customeraddnet: ['/api/friends/customer/net/add', 'POST', true],
      anonymousnetcustomeradd: ['/api/friends/customer/net/addcustomeranonymously', 'PUT', true],
      anonymousstandardcustomeradd: ['/api/friends/customer/standard/addcustomeranonymously', 'PUT', true],
      customerdeletestandard: ['/api/friends/customer/standard/delete/{groupid}/{customerid}', 'DELETE', true],
      customerdeletenet: ['/api/friends/customer/net/delete/{groupid}/{customerid}', 'DELETE', true],
      customerupdatestandard: ['/api/friends/customer/standard/update', 'PUT', true],
      customerupdatenet: ['/api/friends/customer/net/update', 'PUT', true],
      customerupdateprivatenotesnet: ['/api/friends/customer/net/updatenotes', 'PUT', true],
      customerupdateprivatenotesstandard: ['/api/friends/customer/standard/updatenotes', 'PUT', true],
      customergetstandardbyid: ['/api/friends/customer/standard/{groupid}/{customerid}', 'GET', true],
      customergetnetbyid: ['/api/friends/customer/net/{groupid}/{customerid}', 'GET', true],
      customergetnetsummarybykey: ['/api/friends/customer/net/summary/{key}', 'GET', true],
      customergetstandardsummarybykey: ['/api/friends/customer/standard/summary/{key}', 'GET', true],
      customersearchstandard: ['/api/friends/customer/standard/find', 'POST', true],
      customersearchnet: ['/api/friends/customer/net/find', 'POST', true],
      volunteersearchstandard: ['/api/friends/volunteer/standard/find', 'POST', true],
      volunteerexportstandard: ['/api/friends/volunteer/standard/getvolunteerexportfile', 'POST', true],
      volunteersearchnet: ['/api/friends/volunteer/net/find', 'POST', true],
      volunteerexportnet: ['/api/friends/volunteer/net/getvolunteerexportfile', 'POST', true],
      volunteersimplesearch: ['/api/friends/volunteer/simplefind', 'POST', true],
      volunteergetnetprofilebyid: ['/api/friends/volunteer/net', 'POST', true],
      volunteergetstandardprofilebyid: ['/api/friends/volunteer/standard', 'POST', true],
      addnonidentstandardfriendvolunteer: ['/api/friends/volunteer/addnonidentstandardfriendvolunteer', 'POST', true],
      addnonidentnetfriendvolunteer: ['/api/friends/volunteer/addnonidentnetfriendvolunteer', 'POST', true],
      updatenonidentstandardfriendvolunteer: ['/api/friends/volunteer/updatenonidentstandardfriendvolunteer', 'PUT', true],
      updatenonidentnetfriendvolunteer: ['/api/friends/volunteer/updatenonidentnetfriendvolunteer', 'PUT', true],
      updateidentstandardprofile: ['/api/friends/volunteer/updateidentstandardprofile', 'PUT', true],
      updateidentstandardfriendvolunteer: ['/api/friends/volunteer/updateidentstandardfriendvolunteer', 'PUT', true],
      updateidentnetfriendvolunteer: ['/api/friends/volunteer/updateidentnetfriendvolunteer', 'PUT', true],
      volunteerupdateprivatenotes: ['/api/friends/volunteer/updateprivatenotes', 'PUT', true],
      volunteerupdateprivatenotesnet: ['/api/friends/volunteer/net/updateprivatenotes', 'PUT', true],
      volunteerupdateprivatenotesstandard: ['/api/friends/volunteer/standard/updateprivatenotes', 'PUT', true],
      customerfindnetmatch: ['/api/friends/customer/net/findmatch/{groupid}/{customerid}', 'GET', true],
      customerfindstandardmatch: ['/api/friends/customer/standard/findmatch/{groupid}/{customerid}', 'GET', true],
      volunteerfindnetmatch: ['/api/friends/volunteer/net/findmatch/{groupid}/{volunteerid}', 'GET', true],
      volunteerfindstandardmatch: ['/api/friends/volunteer/standard/findmatch/{groupid}/{volunteerid}', 'GET', true],
      friendshipinitiatenet: ['/api/friends/friendship/addnetfriendship', 'POST', true],
      friendshipinitiatestandard: ['/api/friends/friendship/addstandardfriendship', 'POST', true],
      friendshipactivatenet: ['/api/friends/friendship/activatenetfriendship', 'POST', true],
      friendshipactivatestandard: ['/api/friends/friendship/activatestandardfriendship', 'POST', true],
      friendshipcancelnet: ['/api/friends/friendship/cancelnetfriendship', 'POST', true],
      friendshipcancelstandard: ['/api/friends/friendship/cancelstandardfriendship', 'POST', true],
      friendshipterminatenet: ['/api/friends/friendship/terminatenetfriendship', 'POST', true],
      friendshipterminatestandard: ['/api/friends/friendship/terminatestandardfriendship', 'POST', true],
      friendshipmarkinappropriatenet: ['/api/friends/friendship/markinappropriatenetfriendship', 'POST', true],
      friendshipmarkinappropriatestandard: ['/api/friends/friendship/markinappropriatestandardfriendship', 'POST', true],
      friendshipendinappropriatenet: ['/api/friends/friendship/endinappropriatenetfriendship', 'POST', true],
      friendshipendinappropriatestandard: ['/api/friends/friendship/endinappropriatestandardfriendship', 'POST', true],
      setFriendTraining: ['/api/friends/volunteer/settraining', 'POST', true],
      friendOrganization: ['/api/friends/organization/organization', 'GET', false],
      friendOrganizationById: ['/api/friends/organization/organization/{0}', 'GET', false],
      fmgContactInfo: ['/api/friends/organization/fmgcontactinfo/{0}', 'GET', false],
      friendOrganizationFindStandardBrokers: ['/api/friends/organization/findstandardbrokers', 'POST', true],
      friendOrganizationFindNetBrokers: ['/api/friends/organization/findnetbrokers', 'POST', true],
      updateFmg: ['/api/friends/organization/update/', 'PUT', true],
      markactivevolunteerstandard: ['/api/friends/volunteer/markactivestandard/{groupid}/{volunteerid}', 'POST', false],
      markactivevolunteernet: ['/api/friends/volunteer/markactivenet/{groupid}/{volunteerid}', 'POST', false],
      markactivecustomerstandard: ['/api/friends/customer/markactivestandard/{groupid}/{customerid}', 'POST', false],
      markactivecustomernet: ['/api/friends/customer/markactivenet/{groupid}/{customerid}', 'POST', false],
      markinactivevolunteerstandard: ['/api/friends/volunteer/markinactivestandard/{groupid}/{volunteerid}', 'POST', false],
      markinactivevolunteernet: ['/api/friends/volunteer/markinactivenet/{groupid}/{volunteerid}', 'POST', false],
      markinactivecustomerstandard: ['/api/friends/customer/markinactivestandard/{groupid}/{customerid}', 'POST', false],
      markinactivecustomernet: ['/api/friends/customer/markinactivenet/{groupid}/{customerid}', 'POST', false],
      friendvolunteerdeletenet: ['/api/friends/volunteer/deletenetprofile/{groupId}/{friendVolunteerNetProfileId}', 'DELETE', false],
      friendvolunteerdeletestandard: ['/api/friends/volunteer/deletestandardprofile/{groupId}/{friendVolunteerStandardProfileId}', 'DELETE', false],
      friendnetgroups: ['/api/friends/organization/getnetfriendshipmanagementgroups', 'GET', false],
      getfriendmanagementpoolsstandard: ['/api/friends/pool/getfriendmanagementgrouppoolsstandard/{fmgid}', 'GET', false],
      getfriendmanagementpoolsnet: ['/api/friends/pool/getfriendmanagementgrouppoolsnet/{fmgid}', 'GET', false],
      addfriendpoolstandard: ['/api/friends/pool/addfriendpoolstandard', 'POST', true],
      addfriendpoolnet: ['/api/friends/pool/addfriendpoolnet', 'POST', true],
      updatefriendpoolstandard: ['/api/friends/pool/updatefriendpoolstandard', 'PUT', true],
      updatefriendpoolnet: ['/api/friends/pool/updatefriendpoolnet', 'PUT', true],
      getfriendpoolstandard: ['/api/friends/pool/getfriendpoolstandard/{poolid}', 'GET', true],
      getfriendpoolnet: ['/api/friends/pool/getfriendpoolnet/{poolid}', 'GET', true],
      addcustomertopoolstandard: ['/api/friends/pool/addcustomertopoolstandard/{poolid}/{customerid}', 'POST', true],
      addcustomertopoolnet: ['/api/friends/pool/addcustomertopoolnet/{poolid}/{customerid}', 'POST', true],
      addvolunteertopoolstandard: ['/api/friends/pool/addvolunteertopoolstandard/{poolid}/{volunteerid}', 'POST', true],
      addvolunteertopoolnet: ['/api/friends/pool/addvolunteertopoolnet/{poolid}/{volunteerid}', 'POST', true],
      removecustomerfrompoolstandard: ['/api/friends/pool/removecustomerfrompoolstandard/{poolid}/{customerid}', 'DELETE', true],
      removecustomerfrompoolnet: ['/api/friends/pool/removecustomerfrompoolnet/{poolid}/{customerid}', 'DELETE', true],
      removevolunteerfrompoolstandard: ['/api/friends/pool/removevolunteerfrompoolstandard/{poolid}/{volunteerid}', 'DELETE', true],
      removevolunteerfrompoolnet: ['/api/friends/pool/removevolunteerfrompoolnet/{poolid}/{volunteerid}', 'DELETE', true],
      setfriendpoolstatusstandard: ['/api/friends/pool/setfriendpoolstatusstandard/{poolid}/{status}', 'PUT', true],
      setfriendpoolstatusnet: ['/api/friends/pool/setfriendpoolstatusnet/{poolid}/{status}', 'PUT', true],
      deletefriendmanagementpoolstandard: ['/api/friends/pool/deletestandard/{poolid}', 'DELETE', true],
      deletefriendmanagementpoolnet: ['/api/friends/pool/deletenet/{poolid}', 'DELETE', true],
      volunteeridentadd: ['/api/friends/volunteer/addidentfriendvolunteer', 'POST', true],
      volunteeridentupdate: ['/api/friends/volunteer/updateidentfriendvolunteer', 'PUT', true],

      // Blob
      uploadimage: ['/api/userimage/upload', 'POST', true],

      // ZipCodes
      zipcodes: ['/api/content/zipcodes', 'GET', false],
      zipcode: ['/api/content/zipcodes/{0}', 'GET', false],

      // UserRoles
      profileroles: ['/api/user/roles/roles/{0}', 'GET', true],
      setroles: ['/api/user/roles/setuserroles', 'POST', true],

      // FriendManagementRoles
      setnetbrokerrole: ['/api/friends/friendshiproles/grantnetbrokerrole', 'POST', true],
      setstandardbrokerrole: ['/api/friends/friendshiproles/grantstandardbrokerrole', 'POST', true],
      revokenetbrokerrole: ['/api/friends/friendshiproles/revokenetbrokerrole', 'POST', true],
      revokestandardbrokerrole: ['/api/friends/friendshiproles/revokestandardbrokerrole', 'POST', true],

      // Messages
      sendmessage: ['/api/message/message/sendprivate', 'POST', true],
      sendstandardfriendshipvolunteermessage: ['/api/message/message/sendstandardfriendshipvolunteermessage', 'POST', true],
      sendnetfriendshipvolunteermessage: ['/api/message/message/sendnetfriendshipvolunteermessage', 'POST', true],
      getMessagesByUserId: ['/api/message/message/getbyuserid/{0}', 'GET', true],
      getmessagesbysectionid: ['/api/message/message/getbysectionid/{0}', 'GET', true],
      getmessagesbydistrictid: ['/api/message/message/getbydistrictid/{0}', 'GET', true],
      fmgmessages: ['/api/message/message/getbyfriendmanagementgroupid/{0}', 'GET', true],
      sendgroupmessage: ['/api/message/message/sendnotification', 'POST', true],
      sendfmgmessage: ['/api/message/message/sendfmgmessage', 'POST', true],
      sendtosectiongroupmanagers: ['/api/message/message/sendtosectiongroupmanagers', 'POST', true],
      sendtoselectedcontact: ['/api/message/message/sendtoselectedvolunteer', 'POST', true],
      updatemessagestatus: ['/api/message/message/updatestatus/{id}/{statusid}', 'POST', true],
      marknotificationasread: ['/api/message/message/marknotificationasread/{notificationid}', 'POST', true],
      applytoactiongroup: ['/api/message/message/applytojoinactiongroup', 'POST', true],
      removenotification: ['/api/message/message/removenotification', 'POST', true],
      removefmgmessage: ['/api/message/message/removefmgmessage', 'POST', true],
      getSubStatus: ['/api/message/message/getsubstatus', 'GET', true],
      setSubStatus: ['/api/message/message/setsubstatus', 'POST', true],
      sendnewprofileeventadminmessages: ['/api/message/message/sendnewprofileeventadminmessages', 'POST', true],
      sendneweventadminmessages: ['/api/message/message/sendneweventadminmessages', 'POST', true]
    },
    supportEmail: 'Oma.tuki@redcross.fi',
    googleMapsApiKey: 'AIzaSyDO90r2afD0-4BS18qa1fdAxumddjgCutY',
    profile: null,
    aduri: 'https://login.microsoftonline.com/redcross.fi/oauth2/authorize?client_id=bee999a3-efda-4386-a5a5-6bfd7379e935&response_type=id_token&scope=openid&nonce=70178cee-bc09-48f7-98f4-4db874335c9e&redirect_uri=https://oma.punainenristi.fi/adsignin&response_mode=fragment',
    sharethisPropertyId: '5a6083b04fc3d70011b2ee01', // public
    //sharethisPropertyId: '5a7c0b6308e4bc00136bbc2a', // localhost
    hupsisUrl: {
      sv: 'https://sv.hupsis.eu', // https://sv.hupsis.eu for live
      fi: 'https://hupsis.eu', // https://hupsis.eu for live
      en: 'https://en.hupsis.eu' // https://en.hupsis.eu/ for live
    },
    ossiUrl: {
      sv: 'https://ossi.punainenristi.fi/',
      fi: 'https://ossi.punainenristi.fi/',
      en: 'https://ossi.punainenristi.fi/'
    },
    messages: [],
    cache: {},
    gettingProfile: false,
    registerId: null,
    token: {
      //refresh: 'http://localhost:3758/api/users/accounts/claims',
      refresh: 'https://spr-vati-oma-webapi.azurewebsites.net/api/users/accounts/claims',
      // These Regex exceptions will be called with bare claimless token, should include the urls that are called
      // on page load to prevent a round-trip to get the claims token and thus lenghtening the load time...
      exceptions: [
        new RegExp(/.+\/api\/content\/translations\/cached/),
        new RegExp(/.+\/api\/content\/translations\/dynamic/),
        new RegExp(/.+\/api\/content\/languages/),
        new RegExp(/.+\/api\/content\/organization\/sections/),
        new RegExp(/.+\/api\/content\/organization\/districts/),
        new RegExp(/.+\/api\/users\/accounts\/getlogintoken/),
        new RegExp(/https:\/\/maps\.googleapis\.com\/maps\/api\//),
        new RegExp(/.+\/api\/users\/accounts\/adauthorize/),
        new RegExp(/.+\/api\/users\/accounts\/emailexists/),
        new RegExp(/.+\/api\/users\/accounts\/claims/),
        new RegExp(/.+\/api\/friends\/organization\/getnetfriendshipmanagementgroups/)
      ],
      isRefreshing: false
    },
    sessionJwtToken: undefined
  },
  actions: {
    setSessionJwtToken (context, token) {
      context.commit('setSessionJwtToken', JwtDecode(token))
    }
  },
  mutations: {
    setRegisterId (state, idObj) {
      if (idObj && idObj.id && idObj.id !== 0) {
        state.registerId = idObj.id
      }
    },
    setLanguage (state, lang) {
      state.language = lang
    },
    setSectionRoles (state, roles) {
      state.sectionRoles = roles
    },
    setWizardInterests (state, model) {
      state.wizardModel.areasOfInterest = model
    },
    setWizardInvolved (state, model) {
      state.wizardModel.InvolvedEventCategories = model
    },
    setWizardSkills (state, model) {
      state.wizardModel.personalSkills = model
    },
    setWizardLanguages (state, model) {
      state.wizardModel.spokenLanguages = model
    },
    setWizardZipcode (state, model) {
      state.wizardModel.zipCode = model
    },
    setWizardWorkingZip (state, model) {
      state.wizardModel.workingZipCode = model
    },
    setWizardSectionId (state, model) {
      state.wizardModel.sectionId = model
    },
    setWizardTransportation (state, model) {
      state.wizardModel.transportation = model
    },
    setWizardTravelTime (state, model) {
      state.wizardModel.travelTime = model
    },
    setWizardBestTimes (state, model) {
      state.wizardModel.availabilityTimes = model
    },
    setWizardCity (state, model) {
      state.wizardModel.city = model
    },
    setWizardBasicInformation (state, model) {
      state.wizardModel.firstName = model.firstName
      state.wizardModel.lastName = model.lastName
      state.wizardModel.email = model.email
      state.wizardModel.phoneNumber = model.phone
      state.wizardModel.acceptMarketing = model.market
    },
    setWizardContactInformation (state, model) {
      state.wizardModel.streetAddress = model.streetaddress
      state.wizardModel.zipCode = model.zipcode
      state.wizardModel.city = model.city
      state.wizardModel.country = model.country
      state.wizardModel.age = model.age
      state.wizardModel.nativeLanguage = model.language
      state.wizardModel.preferredContactMethods = model.preferredContactMethods
    },
    setWizardNumberInformation (state, model) {
      state.wizardModel.age = model.Age
      state.wizardModel.travelTime = model.TravelTime
    },
    setWizardVolunteerOnline (state, model) {
      state.wizardModel.volunteerOnline = model
    },
    setTranslations (state, model) {
      state.translations = model
    },
    setSections (state, model) {
      state.sections = model
    },
    setJoinSections (state, model) {
      state.joinsections = model
    },
    setJoinableZips (state, model) {
      state.joinablezipcodes = model
    },
    setDistricts (state, model) {
      state.districts = model
    },
    setInstitutions (state, model) {
      state.institutions = model
    },
    setNetFriendshipGroups (state, model) {
      state.netFriendshipGroups = model
    },
    setEventCategories (state, model) {
      state.eventCategories = model
    },
    setGenders (state, model) {
      state.genders = model
    },
    setInvolvedEventCategories (state, model) {
      state.involvedEventCategories = model
    },
    setPreferredGenders (state, model) {
      state.preferredGenders = model
    },
    setPreferredAges (state, model) {
      state.preferredAge = model
    },
    setPreferredContactMethods (state, model) {
      state.preferredContactMethods = model
    },
    setAvailabilityFrequencies (state, model) {
      state.availabilityFrequency = model
    },
    setDatabaseAvailabilityTimes (state, model) {
      state.databaseAvailabilityTimes = model
    },
    setAvailabilityTimes (state, model) {
      state.availabilityTimes = model
    },
    setCustomerTypes (state, model) {
      state.customerTypes = model
    },
    setNetCustomerTypes (state, model) {
      state.netCustomerTypes = model
    },
    setFriendshipTypes (state, model) {
      state.friendshipTypes = model
    },
    setNetFriendshipTypes (state, model) {
      state.netFriendshipTypes = model
    },
    setCustomerInterests (state, model) {
      state.customerInterests = model
    },
    setCustomerStates (state, model) {
      state.customerStates = model
    },
    setCustomerLanguages (state, model) {
      state.customerLanguages = model
    },
    setGroupLanguages (state, model) {
      state.groupLanguages = model
    },
    setZipCodes (state, model) {
      state.zipCodes = model
    },
    setProfile (state, model) {
      state.profile = model
      state.gettingProfile = false
    },
    setProfileFriendVolunteer (state, model) {
      state.profile.friendInfo = model
    },
    setProfileState (state, model) {
      state.wizardModel.profileState = model
    },
    setStory (state, model) {
      state.wizardModel.story = model
    },
    profileEdit (state, model) {
      state.profile.story = model.story
      state.profile.motto = model.motto
      state.profile.profileState = model.profileState
      state.profile.preferredContactMethods = model.preferredContactMethods
      state.profile.involvedEventCategories = model.involvedEventCategories
    },
    profileEditInvolvedEventCategories (state, value) {
      state.profile.involvedEventCategories = value
    },
    profileFetchStarted (state) {
      state.gettingProfile = true
    },
    profileskills (state, model) {
      state.profile.personalSkills = model
    },
    profileinterest (state, model) {
      state.profile.areasOfInterest = model
    },
    profilelanguages (state, model) {
      state.profile.spokenLanguages = model
    },
    profilepersonal (state, model) {
      state.profile.firstName = model.firstName
      state.profile.lastName = model.lastName
      state.profile.phoneNumber = model.phoneNumber
      state.profile.streetAddress = model.streetAddress
      state.profile.zipCode = model.zipCode
      state.profile.city = model.city
      state.profile.country = model.country
      state.profile.age = model.age
      state.profile.nativeLanguage = model.nativeLanguage
    },
    profilelocation (state, model) {
      state.profile.availabilityTimes = model
    },
    profiletransport (state, model) {
      state.profile.transportation = model
    },
    profiletravel (state, model) {
      state.profile.travelTime = model
    },
    profileworkingzip (state, model) {
      state.profile.workingZipCode = model
    },
    profilesection (state, model) {
      state.profile.sectionId = model
    },
    profileeventadmins (state, model) {
      state.profile.eventAdmins = model
    },
    profileVolunteerOnline (state, model) {
      state.profile.volunteerOnline = model
    },
    setAllLanguages (state, model) {
      state.allLanguages = model
    },
    setSearchFilter (state, filter) {
      state.searchFilter = filter
    },
    setMessages (state, model) {
      state.messages = model
    },
    logout (state) {
      state.gettingProfile = false
      state.profile = null
    },
    updateProfilePicture (state, pictureUri) {
      state.profile.portrait = pictureUri
    },
    setAdminBack (state, model) {
      state.adminBack = model
    },
    addPublicEvent (state, value) {
      var index = _.findIndex(state.publicEvents, function (role) {
        return role.id === value.id
      })

      if (index !== -1) {
        // If a matching object is found, replace it with the new value
        state.publicEvents[index] = value
      } else {
        // If no matching object is found, add the new value to the array
        state.publicEvents.push(value)
      }
    },
    removePublicEvent (state, value) {
      _.remove(state.publicEvents, function (role) {
        return role.id === value.id
      })
    },
    toCache (state, model) {
      if (model && model.key && model.exp) {
        state.cache[model.key] = { value: model.value, exp: model.exp }
      } else {
        console.log('malformed commit to cache', state, model)
      }
    },
    nullProfile (state) {
      state.profile = null
    },
    setSessionJwtToken (state, value) {
      state.sessionJwtToken = value
    },
    clearSessionJwtToken (state) {
      state.sessionJwtToken = undefined
    },
    setTokenRefreshing (state, value) {
      state.token.isRefreshing = value
    }
  }
})

export default store
