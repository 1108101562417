<template>
  <div class="comp-admin-page col-12">
    <backbar :title="$t('userAdmin.adminHeader')" />
    <div class="container">
      <!-- Ingress -->
      <!--
    <div class="row">
      <div class="col-12">
        <p>{{$t('userAdmin.adminIngress')}}</p>
      </div>
    </div>
    -->
      <div v-for="s in state.el" :key="s.id" class="row">
        <div :is="s"
             :id="id"
             :primary="primary"
             :filter="filter ? filter : 'all'"
             :purpose="purpose"
             class="col-12"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import usersearch from './UserSearch.vue'
  import usersearchCheck from './UserSearchCheckBox.vue'
  import brokervolunteers from './BrokerVolunteers.vue'
  import sectionmessages from './SectionMessages.vue'
  import districtmessages from './DistrictMessages.vue'

  import usereditor from './UserEditor.vue'
  import districtlist from './DistrictList.vue'
  import deleteprofilelist from './DeleteProfileList.vue'
  import organizationadmin from './OrganizationAdmin.vue'
  import nationalAdmin from './NationalAdmin.vue'
  import nationalEmployee from './NationalEmployee.vue'
  import districtadmin from './DistrictAdmin.vue'
  import sectionadmin from './SectionAdmin.vue'
  import friendshipadmin from './FriendshipAdmin.vue'
  import sectionlist from './SectionList.vue'
  import sectiongroups from './SectionGroups.vue'
  import districtgroups from './DistrictGroups.vue'
  import groupdetail from './GroupDetail.vue'
  import error from './ErrorAdmin.vue'
  import friendshipselection from './FriendshipManagementList.vue'
  import groupmasterlist from './GroupMasterList.vue'
  import productadmin from './ProductsAdmin.vue'
  import externalforms from './ExternalForms.vue'
  import backbar from '../BackBar.vue'
  import currentroles from './CurrentSectionRoles.vue'
  import poolsadmin from './PoolsAdmin.vue'

  export default {
    name: 'Admin',
    components: { backbar },
    props: {
      primary: {
        type: String,
        default: ''
      },
      id: {
        type: String,
        default: ''
      },
      filter: {
        type: String,
        default: ''
      }
    },
    data () {
      return {
        legalActions: ['user', 'district', 'section', 'group', 'event', 'roles', 'deleteprofile'],
        from: null,
        purpose: 1
      }
    },
    computed: {
      state: function () {
        if (!this.jwtToken) {
          this.alertAndRedirect()
          return
        }
        // this is for /admin url
        // This mechanism enforces role-based access control for different sections of the application.
        if (!this.primary && !this.filter && !this.id) {
          // If the user lacks roles 'Admin', 'NationalAdmin', or 'NationalEmployee', triggers an alert and redirects, denying access.
          if (!this.hasRole('Admin') && !this.hasRole('NationalAdmin') && !this.hasRole('NationalEmployee')) {
            this.alertAndRedirect()
            return { el: [error], level: 4 }
          }
          // For users with 'Admin' role, grants access to organizationadmin and districtlist elements with top-level access (level 0).
          if (this.hasRole('Admin')) return { el: [organizationadmin, districtlist], level: 0 }
          // Users with 'NationalAdmin' role or 'national Employee' role, also get access to nationalAdmin and districtlist elements with top-level access.
          else return { el: [nationalAdmin, districtlist], level: 0 }
        }

        // Organization level listings
        // this is for /admin/user, /admin/district, /admin/section, /admin/friendship, /admin/group, /admin/products
        if (this.primary && !this.filter && !this.id) {
          // forbid non-admins
          if (!this.hasRole('Admin') && !this.hasRole('NationalAdmin') && !this.hasRole('NationalEmployee')) {
            // allow FriendshipAdmins to access the listing too
            if (this.primary === 'friendship' && this.hasAdminToFriendship(Number(this.jwtToken.DistrictMembership))) {
              return { el: [friendshipselection], level: 0 }
            }
            this.alertAndRedirect()
            return { el: [error], level: 4 }
          }

          var rv = []
          // if the user has the 'Admin' role, grants access to organizationadmin element with top-level access.
          if (this.hasRole('Admin')) rv = [organizationadmin]
          // if the user has the 'NationalAdmin' role, grants access to nationalAdmin element with top-level access.
          else if (this.hasRole('NationalAdmin')) rv = [nationalAdmin]
          // if the user has the 'NationalEmployee' role, grants access to nationalEmployee element with top-level access.
          else if (this.hasRole('NationalEmployee')) rv = [nationalEmployee]

          if (this.primary === 'user') {
            rv.push(usersearch)
          } else if (this.primary === 'district') {
            rv.push(districtlist)
          } else if (this.primary === 'section') {
            rv.push(sectionlist)
          } else if (this.primary === 'friendship') {
            rv.push(friendshipselection)
          } else if (this.primary === 'group') {
            rv.push(groupmasterlist)
          } else if (this.primary === 'deleteprofile') {
            rv.push(deleteprofilelist)
          } else if (this.primary === 'products') {
            rv.push(productadmin)
          } else if (this.primary === 'externalforms') {
            rv.push(externalforms)
          } else if (this.primary === 'employee') {
            rv.push(usersearch)
          }
          return { el: rv, level: 0 }
        }

        /*
          'editor' views, not all of these make sense
        */
        // for urls /admin/{primary}/{id}
        // for example /admin/user/123, /admin/group/123, /admin/training/1
        if (this.primary && this.id && !this.filter) {
          // auth
          let hasPermissions = this.hasRole('Admin')
          if (!hasPermissions) {
            switch (this.primary) {
            case 'user':
              // doesn't do a back end call to check - the back end will prevent loading
              hasPermissions = this.hasRole('DistrictAdmin') || this.hasRole('Employee') || this.hasRole('J1') || this.hasRole('J2') || this.hasRole('NationalAdmin') || this.hasRole('NationalEmployee')
              break
            case 'group':
              hasPermissions = true
              break
            case 'friendship':
              hasPermissions = false // not a valid page
              break
            case 'training':
              hasPermissions = this.hasRole('Admin') || this.hasRole('DistrictAdmin') || this.hasRole('NationalAdmin') || this.hasRole('FriendshipAdmin') || this.hasRole('DistrictFriendshipAdmin') || this.hasRole('FriendshipTrainer')
              break
            case 'products':
              hasPermissions = false // not a valid page
              break
            default :
              hasPermissions = false
              break
            }
          }

          if (!hasPermissions) {
            this.alertAndRedirect()
            return { el: rv, level: 0 }
          }

          if (this.primary === 'user') {
            return { el: [usereditor], level: 3 }
          } else if (this.primary === 'group') {
            return { el: [groupdetail], level: 3 }
          } else if (this.primary === 'training') {
            return { el: [usersearch], level: 3 }
          } else if (this.primary === 'employee') {
            return { el: [usereditor], level: 3 }
          }
        }

        /*
          filtered listings. sectionadmin components also contains editor for
          section.
        */
        // aargh! For when filters are set, the primary switches round, so its /admin/{filter}/{id}/{primary}
        // for example /admin/district/123/section, admin/section/9/user, admin/friendship/72/clients
        if (this.primary && this.id && this.filter) {
          var frv = []
          if (this.filter === 'section') { // eg /admin/section/9/user
            if (!this.hasAdminToSection(this.id, true)) {
              this.alertAndRedirect()
              return { el: [error], level: 4 }
            }
            frv.push(sectionadmin)
          } else if (this.filter === 'district') { // eg /admin/district/1/section
            if (!this.hasAdminToDistrict(this.id)) {
              this.alertAndRedirect()
              return { el: [error], level: 4 }
            }
            frv.push(districtadmin)
          } else if (this.filter === 'friendship') { // eg /admin/friendship/6/clients
            if (!this.hasAdminToFriendManagementGroup(this.id)) {
              this.alertAndRedirect()
              return { el: [error], level: 4 }
            }
            frv.push(friendshipadmin)
          } else {
            this.alertAndRedirect()
            return { el: [error], level: 4 }
          }

          // these are for the additional components added into the pages above, eg /admin/section/123/user adds usersearch
          // no need for auth because above should have caught that
          if (this.primary === 'user') {
            frv.push(usersearchCheck)
          } else if (this.primary === 'group' && this.filter === 'section') {
            frv.push(sectiongroups)
          } else if (this.primary === 'group' && this.filter === 'district') {
            frv.push(districtgroups)
          } else if (this.primary === 'messages' && this.filter === 'section') {
            frv.push(sectionmessages)
          } else if (this.primary === 'messages' && this.filter === 'district') {
            frv.push(districtmessages)
          } else if (this.primary === 'section' && this.filter === 'district') {
            frv.push(sectionlist)
          } else if (this.primary === 'clients') {
            frv.push(usersearch)
          } else if (this.primary === 'volunteers') {
            frv.push(usersearch)
          } else if (this.primary === 'brokers') {
            frv.push(usersearch)
            frv.push(brokervolunteers)
          } else if (this.primary === 'pools') {
            frv.push(poolsadmin)
          } else if (this.primary === 'roles') {
            frv.push(currentroles)
            frv.push(usersearch)
          }

          return { el: frv, level: this.filter === 'district' ? 1 : 2 }
        }

        this.alertAndRedirect()
        return { el: [error], level: 'forbid' }
      }
    },
    watch: {
      primary (newVal) {
        if (newVal === 'brokers') {
          this.purpose = 5
        }
      }
    },
    mounted: function () {
      var prev = this.$store.state.adminBack

      if (!prev) {
        prev = [null, null, null, null]
      }
      prev[this.state.level] = this.$route.path
      for (var j = this.state.level + 1; j < prev.length; j++) {
        prev[j] = null
      }

      if (this.state.level >= 1 && this.state.level <= 4) {
        for (var i = this.state.level - 1; i >= 0; i--) {
          if (prev[i]) {
            this.from = prev[i]
            break
          }
        }
      }

      this.$store.commit('setAdminBack', prev)
      this.$bus.$on('request-admin-back-after-delete', this.handleBackRequest)
    },
    beforeDestroy: function () {
      this.$bus.$off('request-admin-back-after-delete')
    },
    methods: {
      validateAction: function (action) {
        if (action) {
          return this.legalActions.indexOf(action) >= 0
        }
        return false
      },
      handleBackRequest: function () {
        if (this.from) {
          this.$router.push(this.from)
        } else {
          if (this.hasRole('Admin') || this.hasRole('NationalAdmin') || this.hasRole('NationalEmployee')) {
            this.$router.push('/admin/district')
            return
          }
          if (this.hasRole('Employee')) {
            this.$router.push('/admin/district/' + this.jwtToken.DistrictMembership + '/section')
            return
          }
          if (this.hasRole('J1') || this.hasRole('J2')) {
            this.$router.push('/admin/section/' + this.jwtToken.SectionMembership + '/user')
          }
        }
      },
      alertAndRedirect () {
        this.showAlertPermissionDenied().then(() => {
          this.$router.push('/profile')
        })
      }
    }
  }
</script>

<style lang="scss">
@import '~@/assets/scss/_variables.scss';
  .comp-admin-page {

    .nav-header {
      background-color: $brand-grey1;
      overflow-wrap: break-word;
      margin-bottom: 30px;
      border-bottom: solid 1px $brand-grey3;

    .back {
      color: $brand-black;
      cursor: pointer;

    svg {
      color: $brand-red;
      margin-right: 5px;
    }

    &:hover {
      color: $brand-red;
      text-decoration: none;
    }
    }
    h3 {
      color: $brand-red;
    }
  }

  .back-link {
      font-size: 18px;
    }

  .space-chevron {
    padding-right: 6px;
  }
}
</style>
