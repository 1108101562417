<template>
  <div class="spr-timepicker">
    <label :for="_uid" class="cap-label">{{ title }}</label>
    <input
      :id="_uid"
      v-model="strTime"
      v-validate="'colontime'"
      type="text"
      name="sprtime"
      :class="{ 'spr-danger': errors.has('sprtime'), 'form-control spr-roundbox-1': true }"
      @input="updateTime($event.target.value)"
    >
  </div>
</template>

<script>
  import colontime from '../mixins/validator-colontime.js'
  import { Validator } from 'vee-validate'

  Validator.extend('colontime', colontime)

  export default {
    name: 'Sprtime',
    props: {
      title: {
        type: String,
        default: ''
      },
      isValid: Boolean,
      value: {
        type: Object,
        required: true
      }
    },
    data () {
      return {
        strTime: '00:00',
        objTime: {
          hour: 0,
          minute: 0
        }
      }
    },
    watch: {
      value (newVal) {
        if (newVal) this.setTime(newVal)
      }
    },
    mounted () {
      this.setTime(this.value)
    },
    methods: {
      setTime (val) {
        // Converts the input object to string
        this.objTime.hour = this.value.hour
        this.objTime.minute = this.value.minute

        var hrs = this.objTime.hour > 9 ? this.objTime.hour : `0${this.objTime.hour}`
        var mins = this.objTime.minute > 9 ? this.objTime.minute : `0${this.objTime.minute}`
        this.strTime = `${hrs}:${mins}`
      },
      updateTime (val) {
        var regex = RegExp(/^([01]\d|2[0-3]):([0-5]\d)$/)
        if (!regex.test(this.strTime)) return
        var time = this.strTime.split(':')
        var hrs = Number(time[0])
        var mins = Number(time[1])
        if (hrs > 23 || hrs < 0) return
        if (mins > 59 || mins < 0) return
        this.objTime.hour = hrs
        this.objTime.minute = mins
        this.$emit('input', this.objTime)
      }
    }
  }
</script>

<style lang="scss">
  @import "../assets/scss/_variables.scss";

  .spr-timepicker {
    label {
      color:$brand-red;
      margin-left: 20px;
    }

    input {
      display: block;
      box-sizing: border-box;
      font-family: "Signa-Light", Sans-Serif;
      border: 2px solid $brand-grey3;
      padding: 10px 20px;
      height: 44px;

      &.spr-danger {
        border: 2px solid $brand-red !important;
      }
    }
  }

</style>
