<template>
  <div class="container">
    <div class="profilenonidentstandardvolunteerform">
      <div class="row">
        <div class="col-12">
          <h2>{{ addEditTitle }}</h2>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <backbar />
        </div>
      </div>
      <div class="row">
        <div class="col-12 text-center py-2">
          <validation
            v-if="friendProfile"
            :id="'validationTarget' + _uid"
            ref="validationtarget"
            :validation-errors="validationErrors"
            exclude-field-reference
            include-title
          />
        </div>
      </div>
      <div class="row feature-panel">
        <div class="col-12 col-sm-5 col-md-4">
          <forminput
            v-if="friendProfile"
            v-model="friendProfile.phoneNumber"
            v-validate="'required'"
            :hint="$t('volunteerForm.phone')"
            :is-valid="!errors.has('volunteerForm.phone')"
            name="volunteerForm.phone"
            :required="true"
          />
        </div>
        <div class="col-12 col-sm-7 col-md-4">
          <forminput
            v-if="friendProfile"
            v-model="friendProfile.email"
            v-validate="'email'"
            :hint="$t('profile.email')"
            :is-valid="!errors.has('volunteerForm.email')"
            name="volunteerForm.email"
          />
        </div>
      </div>
      <div v-if="friendProfile" class="row feature-panel">
        <div class="col-12">
          <ProfileInfoForm
            v-if="friendProfile"
            :profile="friendProfile"
            :is-net-profile="false"
            @input="onFriendProfileChanged"
          />
        </div>
      </div>
      <div class="row feature-panel">
        <div class="col-12">
          <VolunteerProfileCommon
            v-if="friendProfile"
            :volunteer="friendProfile.friendInfo"
            @input="onVolunteerChanged"
          />
        </div>
      </div>
      <div class="row feature-panel">
        <div class="col-12">
          <VolunteerProfileStandard
            v-if="friendProfile"
            :volunteer="friendProfile.friendInfo.standardProfile"
            @input="onVolunteerStandardProfileChanged"
          />
        </div>
      </div>
      <div class="row feature-panel">
        <div class="col-12 sectionheader">
          <h3>{{ $t("nonIdentProfileForm.applicationState") }}</h3>
        </div>
        <div class="col-12">
          <InterviewForm
            v-if="friendProfile"
            :value="computedInterviewData"
            :disabled="isStateChangeDisabled"
            @input="onInterviewDataChanged"
          />
        </div>
        <div class="col-12">
          <OnHoldForm
            v-if="friendProfile"
            :value="computedOnHoldData"
            :disabled="isStateChangeDisabled"
            @input="onOnHoldDataChanged"
          />
        </div>
      </div>
      <div class="row feature-panel">
        <div class="col-12">
          <textareainput
            v-if="friendProfile"
            v-model="friendProfile.friendInfo.standardProfile.privateNotes"
            :title="$t('volunteerForm.privateNotes')"
            :rows="6"
          />
        </div>
      </div>
      <div class="row feature-panel buttons">
        <div class="col-6 cancel">
          <SprButton type="button"
                     :size="1"
                     :title="$t('volunteerForm.cancel')"
                     click-event="onCancel"
                     @onCancel="cancel"
          />
        </div>
        <div class="col-6 save">
          <SprButton type="button"
                     :size="1"
                     :disabled="isSaving"
                     :title="$t('volunteerForm.save')"
                     click-event="onSave"
                     @onSave="save"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import Vue from 'vue'
  import { LocaleMessage } from 'vue-i18n'
  import SprButton from '@/components/SPRButton.vue'
  import forminput from '../SPRSimpleText.vue'
  import VolunteerProfileCommon from '@/components/friends/VolunteerProfileCommon.vue'
  import ProfileInfoForm from '@/components/friends/ProfileInfoForm.vue'
  import VolunteerProfileStandard from '@/components/friends/VolunteerProfileStandard.vue'
  import InterviewForm from '@/components/friends/InterviewForm.vue'
  import OnHoldForm from '@/components/friends/OnHoldForm.vue'
  import textareainput from '../SPRSimpleTextArea.vue'
  import backbar from '../BackBar.vue'
  import validation from '../SPRValidationError.vue'
  import Alerts from '@/mixins/alerts.js'
  import { ErrorBag, FieldError } from 'vee-validate'
  import { UserProfileDto, FriendProfileDto, FriendVolunteerDto, FriendVolunteerProfileStandardDto, CustomerState, PreferredGenders, FriendshipTypes, NetFriendshipTypes, GetFriendProfileByFriendVolunteerIdCriteria } from '@/types/index'
  import { InterviewData, OnHoldData } from '@/types/custom'

  // the data object
  export interface TemplateComponentData {
    friendProfile: FriendProfileDto | undefined,
    isSaving: boolean
  }

  // This is the component for managing adding and updating an ident users Friend profile.
  export default Vue.extend({
    name: 'ProfileNonIdentStandardVolunteerForm',
    components: { validation, backbar, ProfileInfoForm, VolunteerProfileCommon, VolunteerProfileStandard, InterviewForm, OnHoldForm, SprButton, textareainput, forminput },
    mixins: [Alerts],
    props: {
      profileId: {
        type: Number,
        default: 0
      },
      groupId: {
        type: Number,
        required: true
      }
    },
    data (): TemplateComponentData {
      return {
        friendProfile: undefined,
        isSaving: false
      }
    },
    computed: {
      isAdd (): boolean {
        return Boolean(!this.profileId)
      },
      isEdit (): boolean {
        return Boolean(this.profileId)
      },
      addEditTitle (): string | LocaleMessage {
        return this.isEdit
          ? this.$t('volunteerForm.updateTitle')
          : this.$t('volunteerForm.addTitle')
      },
      validationErrors (): Array<Array<string>> {
        const errs: ErrorBag = this.errors ? this.errors : []
        if (errs.items.length) {
          return errs.items.map((err: FieldError) => {
            return [`error_${err.field}`, err.rule ? err.rule : '', err.msg ? err.msg : '']
          })
        }
        return []
      },
      computedInterviewData (): InterviewData | null {
        if (!this.friendProfile?.friendInfo?.standardProfile) {
          return {
          interviewed: false,
          interviewedBy: '',
          interviewedDate: undefined
        } as InterviewData
        }

        var d = new Date()
        var defaultDate = new Date(`${d.getUTCFullYear()}-${d.getUTCMonth() + 1}-${d.getUTCDate() < 10 ? '0' + d.getUTCDate() : d.getUTCDate().toString()}T00:00:00Z`) // must be in UTC date format
        const p = this.friendProfile.friendInfo.standardProfile
        return {
            interviewed: p.volunteerInterviewed,
            interviewedBy: p.volunteerInterviewedBy,
            interviewedDate: p.volunteerInterviewedOnDate || defaultDate
          } as InterviewData
      },
      computedOnHoldData (): OnHoldData | null {
        if (!this.friendProfile?.friendInfo?.standardProfile) return null

        const p = this.friendProfile.friendInfo.standardProfile
        return {
            onHold: Boolean(p.onHoldDeadline),
            onHoldDeadline: p.onHoldDeadline,
            onHoldDetails: p.onHoldDetails
          } as OnHoldData
      },
      isStateChangeDisabled (): boolean {
        if (!this.friendProfile?.friendInfo?.standardProfile) return false
        /// Cannot change the state if the customer has friendships
        return this.friendProfile?.friendInfo?.standardProfile?.state === CustomerState.PendingFriend ||
          this.friendProfile?.friendInfo?.standardProfile?.state === CustomerState.ActiveFriendship ||
          Boolean(this.friendProfile?.friendInfo?.standardProfile?.friendships?.length)
      }
    },
    mounted (): void {
      if (this.isEdit) {
        var criteria: GetFriendProfileByFriendVolunteerIdCriteria = {
          friendManagementGroupId: this.groupId,
          friendVolunteerId: this.profileId,
          includeFriendships: false,
          includeFriendPools: false
        }
        var url = (this.$store.state.apiBases.friendservice + this.$store.state.apiEndpoints.volunteergetstandardprofilebyid[0])
        this.$http.post(url, criteria).then(s => {
          if (s.ok) {
            // @ts-ignore
            this.friendProfile = s.body as FriendProfileDto
            return
          }
          this.failLoad()
        }, f => this.failLoad())
      } else {
        this.friendProfile = {
          age: new Date(),
          availabilityTimes: [],
          city: '',
          country: '',
          email: '',
          firstName: '',
          hasFriendInfo: true,
          otherLanguages: '',
          involvedEventCategories: 0,
          friendInfo: {
            city: '',
            desiredAge: 0,
            friendManagementGroupIds: [],
            friendshipTypes: [],
            friendVolunteerNetId: undefined,
            friendVolunteerStandardId: undefined,
            gender: 1,
            hasHealthCareTraining: false,
            hasNetProfile: false,
            hasStandardProfile: false,
            healthCareTrainingFreetext: '',
            id: 0,
            netProfile: {
              availabilityDetails: '',
              availabilityFrequence: 0,
              availabilityTimes: 0,
              customerTypes: 0,
              friendManagementGroupId: this.groupId,
              friendships: [],
              friendshipTypes: (NetFriendshipTypes.OneToOne),
              friendPools: [],
              id: 0,
              interests: 0,
              lastFriendshipEndDate: new Date(),
              multipleCustomers: false,
              onHold: false,
              onHoldDeadline: undefined,
              onHoldDetails: '',
              otherInterests: '',
              preferredAges: 0,
              preferredGenders: (PreferredGenders.Other | PreferredGenders.Male | PreferredGenders.Female),
              shortDescription: '',
              startedPending: new Date(),
              state: CustomerState.PendingApproval,
              volunteerInterviewed: false,
              volunteerInterviewedBy: '',
              volunteerInterviewedDate: '',
              volunteerInterviewedOnDate: new Date(),
              econtactDiscord: '',
              econtactEmail: '',
              econtactFreetext: '',
              econtactInstagram: '',
              econtactKik: '',
              econtactSkype: '',
              econtactTelegram: '',
              privateNotes: ''
            },
            otherLanguages: '',
            phone: '',
            postalCode: '',
            profileId: 0,
            standardProfile: {
              availabilityDetails: '',
              availabilityFrequence: 0,
              customerTypes: 0,
              friendManagementGroupId: this.groupId,
              friendships: [],
              friendshipTypes: (FriendshipTypes.OneToOne),
              friendPools: [],
              id: 0,
              interests: 0,
              lastFriendshipEndDate: new Date(),
              multipleCustomers: false,
              onHold: false,
              onHoldDeadline: undefined,
              onHoldDetails: '',
              otherInterests: '',
              preferredAges: 0,
              preferredGenders: (PreferredGenders.Other | PreferredGenders.Male | PreferredGenders.Female),
              shortDescription: '',
              startedPending: new Date(),
              state: CustomerState.PendingApproval,
              volunteerInterviewed: false,
              volunteerInterviewedBy: '',
              volunteerInterviewedDate: '',
              availabilityTimes: 0,
              hasAlternativeAddress: false,
              alternativeCity: '',
              alternativeStreet: '',
              alternativeZipCode: '',
              privateNotes: ''
            },
            streetAddress: ''
          },
          id: 0,
          isNonIdent: true,
          lastName: '',
          nativeLanguage: 'fi',
          phoneNumber: '',
          spokenLanguages: [],
          streetAddress: '',
          transportation: [],
          travelTime: '',
          zipCode: ''
        } as FriendProfileDto
      }
    },
    provide (): Record<string, any> {
      return {
        $validator: this.$validator // allow child components to have validator injected
      }
    },
    methods: {
      failLoad (): void {
        this.displayError(this.$t('common.fetchFailed'))
      },
      onFriendProfileChanged (newFriendProfile: FriendProfileDto): void {
        this.friendProfile = newFriendProfile
      },
      onVolunteerChanged (newVolunteer: FriendVolunteerDto): void {
        if (!this.friendProfile) return

        this.friendProfile.friendInfo = newVolunteer
      },
      onVolunteerStandardProfileChanged (newProfile: FriendVolunteerProfileStandardDto): void {
        if (!this.friendProfile) return

        this.friendProfile.friendInfo.standardProfile = newProfile
      },
      onInterviewDataChanged (newData: InterviewData): void {
        if (!this.friendProfile) return

        this.friendProfile.friendInfo.standardProfile.volunteerInterviewed = newData.interviewed
        this.friendProfile.friendInfo.standardProfile.volunteerInterviewedBy = newData.interviewedBy
        this.friendProfile.friendInfo.standardProfile.volunteerInterviewedOnDate = newData.interviewedDate
      },
      onOnHoldDataChanged (newData: OnHoldData): void {
        if (!this.friendProfile) return

        this.friendProfile.friendInfo.standardProfile.onHoldDeadline = newData.onHold ? newData.onHoldDeadline : undefined
        this.friendProfile.friendInfo.standardProfile.onHoldDetails = newData.onHold ? newData.onHoldDetails : ''
      },
      cancel (): void {
        const url = this.isEdit
          ? `/friend/volunteerpreview/${this.groupId}/${this.profileId}`
          : `/friend/search/${this.groupId}/volunteer`
        this.$router.push(url)
      },
      save (): void {
        if (this.friendProfile?.friendInfo?.standardProfile?.preferredAges === 0) {
          this.friendProfile.friendInfo.standardProfile.preferredAges = 1
          this.$nextTick(() => {
            if (!this.friendProfile) return
            this.friendProfile.friendInfo.standardProfile.preferredAges = 0
            this.validateAndSave()
          })
        } else {
          this.validateAndSave()
        }
      },
      validateAndSave (): void {
        this.$validator.validateAll().then(result => {
          // check any child controls have not added errors
          if (!result || (this.validationErrors && this.validationErrors.length)) {
            var target = this.$refs['validationtarget']
            if (target) {
              window.scrollTo({
                // @ts-ignore
                top: target.$el.parentElement.clientHeight,
                left: 0,
                behavior: 'smooth'
              })
            }
            return
          }
          if (this.friendProfile) {
            const data = this.friendProfile
            // @ts-ignore
            data.friendInfo.netProfile = null
            if (this.isAdd) {
              this.addFriendProfile(data)
            } else {
              this.updateFriendProfile(data)
            }
          }
        })
      },
      addFriendProfile (data: FriendProfileDto): void {
        if (!data) return

        this.isSaving = true
        const addUrl = this.$store.state.apiBases.friendservice + this.$store.state.apiEndpoints.addnonidentstandardfriendvolunteer[0]
        this.$http.post(addUrl, data).then((result: any) => {
          this.isSaving = false
          this.$router.push(`/friend/search/${this.groupId}/volunteer`)
        }, (err: Response | any) => {
          this.isSaving = false
          this.displayError(err)
        })
      },
      updateFriendProfile (data: FriendProfileDto): void {
        if (!data) return

        this.isSaving = true
        const updateUrl = this.$store.state.apiBases.friendservice + this.$store.state.apiEndpoints.updatenonidentstandardfriendvolunteer[0]
        this.$http.put(updateUrl, data).then((result: any) => {
          this.isSaving = false
          this.$router.push(`/friend/search/${this.groupId}/volunteer`)
        }, (err: Response | any) => {
          this.isSaving = false
          this.displayError(err)
        })
      },
      displayError (err: Response | any): void {
        if (!err) return

        if (Array.isArray(err.body)) {
          err.body.forEach(x => {
            // @ts-ignore
            this.showAlertError(x.errorMessage)
          })
        } else if (err.body && err.body.errorMessage) {
          // @ts-ignore
          this.showAlertError(err.errorMessage)
        } else if (err.bodyText) {
          // @ts-ignore
          this.showAlertError(err.bodyText)
        } else {
          // @ts-ignore
          this.showAlertError(this.$t('common.fetchFailed'))
        }
      }
    }
  })
</script>

<style lang="scss">
  @import "../../assets/scss/_variables.scss";
  .profilenonidentstandardvolunteerform {
    .feature-panel {
      background-color: $brand-white;
      margin-bottom: 30px;
      padding-top: 15px;

      &.buttons {
        margin-top: 30px;
        padding-top: 15px;
        padding-bottom: 15px;
        .cancel {
          text-align: left;
        }
        .save {
          text-align: right;
        }
      }
    }

    .profilehead {
      margin-top: 25px;
      margin-bottom: 0;
      padding-top: 10px;
      padding-bottom: 10px;
      background-color: $brand-white;
    }

    .subheader {
      margin-top: 15px;
      margin-bottom: 15px;
      display: block;
    }

    .profile-button {
      margin-top: 20px;
      display: flex;
      justify-content: center;
    }

    .fa-exclamation-triangle {
      color: $brand-red;
    }
  }
</style>
