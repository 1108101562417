<template>
  <div
    v-if="showSite"
    class="container-fluid"
  >
    <div class="row">
      <sprheader class="header" />
    </div>
    <div class="row">
      <router-view :key="$route.path" />
    </div>
    <div class="row">
      <sprfooter class="footer" />
    </div>
  </div>
  <div
    v-else
    class="loading-container"
  >
    <div class="loading-icon">
      <h1>SPR</h1>
    </div>
    <div v-if="loadfailed" class="loadfailed">
      <span><font-awesome-icon icon="exclamation-triangle" />Lataus epäonnistui</span>
    </div>
  </div>
</template>

<script lang="ts">
  import Vue from 'vue'
  import sprheader from './components/Header.vue'
  import sprfooter from './components/Footer.vue'
  import messages from './mixins/messages.js'

  // the data object
  export interface TemplateComponentData {
      mounted: boolean,
      token: string,
      failed: boolean,
      refreshMessagesInterval: any
    }
  export default Vue.extend({
    name: 'App',
    components: { sprheader, sprfooter },
    mixins: [messages],
    props: {
      loadfailed: { // param passed in if the site fails to initialize (eg back end services not running, or unable to connect.)
        type: Boolean,
        default: false
      }
    },
    data () {
      return {
        mounted: false,
        token: '',
        failed: false,
        refreshMessagesInterval: null
      }
    },
    computed: {
      showSite: function (): boolean {
        if (!this.mounted) {
          // mounting has not finished, show loading screen instead
          return false
        }

        // better not to combine absolutely everything into one mega boolean evaluation...
        return this.profileLoaded && this.languageResourceLoaded
      },
      profileLoaded: function (): boolean {
        // Profile does not need to be loaded, or is not null anymore in vuix => profile is loaded if needed
        return ((this.token == null || this.token === '') || this.$store.state.profile != null)
      },
      languageResourceLoaded: function (): boolean {
        return this.$store.state.allLanguages != null
      }
    },
    beforeDestroy: function (): void {
      // @ts-ignore
      clearInterval(this.refreshMessagesInterval)
    },
    mounted: function (): void {
      // @ts-ignore
      this.token = sessionStorage.getItem('authtoken')

      this.requestProfile(20, this)
      // Request languages... re-try 3 times before giving up
      this.requestLanguage(3, this)

      this.mounted = true

      // basic application insights logging.
      this.$appInsights.trackEvent('SiteLoaded', { value: 'true' })
    },
    methods: {
      requestProfile: function (count: number, self: any): void {
        if (self.token && self.token !== '' && !self.$store.state.profile) {
          self.fetchProfile((response: any) => {
          }, (response: any) => {
            if (count > 0) {
              self.requestProfile(count - 1, self)
            } else {
              self.logout('/login')
            }
          })
        }
      },
      requestLanguage: function (count: number, self: any): void {
        this.$http.get(this.$store.state.apiBases.contentservice + this.$store.state.apiEndpoints.languages[0]).then(
          response => {
            // @ts-ignore
            self.$store.commit('setAllLanguages', response.body.body)
          }, response => {
            if (count > 0) {
              self.requestLanguage(count - 1, self)
            } else {
              self.failed = true
            }
          })
      }
    }
  })
</script>

<style lang="scss">
@import '../node_modules/bootstrap/dist/css/bootstrap.css';
@import '../node_modules/bootstrap-vue/dist/bootstrap-vue.css';
@import "./assets/scss/_variables.scss";
@import "./assets/scss/_typography.scss";
@import "./assets/scss/_alerts.scss";
@import './assets/fonts.scss';

.loading-container {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .loading-icon {
    margin: auto;
    height: 150px;
    width: 150px;
    border-radius: 50%;
    background: $brand-red;
    color: $brand-white;
    display: flex;

    h1 {
      margin: auto;
    }
  }

  .loadfailed {
    font-weight: bold;
    margin: 30px auto 150px auto;
    text-align: center;
    color: $brand-red;
    svg {
      margin-right: 5px;
    }
  }
}

html, body {
  padding: 0;
  margin: 0;
}
.app-container {
  margin: 0;
  padding: 0;
}

/* Aria headers into blocks*/
span[role=heading] {
  display: block;
}

body {
  background: $brand-grey1;
  max-width: 100% !important;
  overflow-x: hidden;
  ::-webkit-scrollbar {
    width: auto;
  }
  ::-webkit-scrollbar-track {
    background: #E3E3E3;
  }
  ::-webkit-scrollbar-thumb {
    background: #C1C1C1;
    border-radius: 2px;
  }
}

body,
p {
  font-family: $font-light;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.3px
}

  .brand-white {
    color: $brand-white;
  }

  .brand-red {
    color: $brand-red;
  }

  .brand-black {
    color: $brand-black;
  }

  .brand-grey0 {
    color: $brand-grey0;
  }

  .brand-grey1 {
    color: $brand-grey1;
  }

  .brand-grey2 {
    color: $brand-grey2;
  }

  .brand-grey3 {
    color: $brand-grey3;
  }

  .brand-grey4 {
    color: $brand-grey4;
  }

  .brand-grey5 {
    color: $brand-grey5;
  }

  .brand-grey6 {
    color: $brand-grey6;
  }

  .brand-grey7 {
    color: $brand-grey7;
  }

  .brand-grey8 {
    color: $brand-grey8;
  }

  .brand-grey9 {
    color: $brand-grey9;
  }

  .brand-grey10 {
    color: $brand-grey10;
  }

  .brand-grey11 {
    color: $brand-grey11;
  }

  .brand-grey12 {
    color: $brand-grey12;
  }

  .brand-grey13 {
    color: $brand-grey13;
  }

  .brand-grey14 {
    color: $brand-grey14;
  }

  .brand-grey15 {
    color: $brand-grey15;
  }

  .brand-grey16 {
    color: $brand-grey16;
  }

.fill-height {
  height: 100%;
}

.fill-width {
  width: 100%;
}

.vertical-center {
  display: block;
  margin-top: auto;
  margin-bottom: auto;
}

.spr-shadow {
  box-shadow: 2px 2px 5px $brand-grey13;
}

.link {
  text-decoration: none;
  font-family: "Signa-Light", Sans-Serif;
  cursor: pointer;

  &:hover {
  background: $brand-red;
  color: $brand-white;
}

}

.spr-redborder {
  border: 1px solid red;
}
.spr-greyborder {
  border: 1px solid $brand-grey9;
}

.spr-button {
  margin: 0 2px;
}

.btn.spr-roundbox-1,
.spr-roundbox-1 {
  border-radius: 60px;
}

.btn.spr-roundbox-2,
.spr-roundbox-2 {
  border-radius: 60px;
}

.btn.spr-roundbox-3,
.spr-roundbox-3 {
  border-radius: 60px;
}
</style>
