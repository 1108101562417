// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import VueI18n from 'vue-i18n'
import VeeValidate from 'vee-validate'
import VueResource from 'vue-resource'
import BootstrapVue from 'bootstrap-vue'
import CommonMixins from './mixins/common.js'
import Alerts from './mixins/alerts.js'
import store from './store'
import VueMeta from 'vue-meta'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon, FontAwesomeLayers } from '@fortawesome/vue-fontawesome'

// Font awesome icons used in the applications

import { faAngleDoubleLeft } from '@fortawesome/free-solid-svg-icons/faAngleDoubleLeft'
import { faAngleDoubleRight } from '@fortawesome/free-solid-svg-icons/faAngleDoubleRight'
import { faAngleDown } from '@fortawesome/free-solid-svg-icons/faAngleDown'
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons/faAngleLeft'
import { faAt } from '@fortawesome/free-solid-svg-icons/faAt'
import { faBan } from '@fortawesome/free-solid-svg-icons/faBan'
import { faBars } from '@fortawesome/free-solid-svg-icons/faBars'
import { faBinoculars } from '@fortawesome/free-solid-svg-icons/faBinoculars'
import { faCalendar } from '@fortawesome/free-solid-svg-icons/faCalendar'
import { faCaretDown } from '@fortawesome/free-solid-svg-icons/faCaretDown'
import { faCaretRight } from '@fortawesome/free-solid-svg-icons/faCaretRight'
import { faCheck } from '@fortawesome/free-solid-svg-icons/faCheck'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons/faCheckCircle'
import { faCheckCircle as faCheckCircleO } from '@fortawesome/free-regular-svg-icons/faCheckCircle'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons/faChevronDown'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons/faChevronLeft'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons/faChevronRight'
import { faChevronUp } from '@fortawesome/free-solid-svg-icons/faChevronUp'
import { faCircle } from '@fortawesome/free-solid-svg-icons/faCircle'
import { faClock as faClockO } from '@fortawesome/free-regular-svg-icons/faClock'
import { faComment as faCommentO } from '@fortawesome/free-regular-svg-icons/faComment'
import { faCopyright } from '@fortawesome/free-solid-svg-icons/faCopyright'
import { faEdit } from '@fortawesome/free-solid-svg-icons/faEdit'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons/faEnvelope'
import { faEuroSign } from '@fortawesome/free-solid-svg-icons/faEuroSign'
import { faEye } from '@fortawesome/free-solid-svg-icons/faEye'
import { faEyeSlash } from '@fortawesome/free-solid-svg-icons/faEyeSlash'
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons/faExclamationTriangle'
import { faFacebookF } from '@fortawesome/free-brands-svg-icons/faFacebookF'
import { faFrown } from '@fortawesome/free-regular-svg-icons/faFrown'
import { faSave as faSaveO } from '@fortawesome/free-regular-svg-icons/faSave'
import { faGlobe } from '@fortawesome/free-solid-svg-icons/faGlobe'
import { faGoogle } from '@fortawesome/free-brands-svg-icons/faGoogle'
import { faHandshake as faHandshakeO } from '@fortawesome/free-regular-svg-icons/faHandshake'
import { faHeart as faHeartO } from '@fortawesome/free-regular-svg-icons/faHeart'
import { faHome } from '@fortawesome/free-solid-svg-icons/faHome'
import { faHospital } from '@fortawesome/free-regular-svg-icons/faHospital'
import { faIdBadge } from '@fortawesome/free-solid-svg-icons/faIdBadge'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons/faInfoCircle'
import { faLightbulb as faLightbulbO } from '@fortawesome/free-regular-svg-icons/faLightbulb'
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons/faMapMarkerAlt'
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons/faPencilAlt'
import { faPhone } from '@fortawesome/free-solid-svg-icons/faPhone'
import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus'
import { faPuzzlePiece } from '@fortawesome/free-solid-svg-icons/faPuzzlePiece'
import { faQuoteLeft } from '@fortawesome/free-solid-svg-icons/faQuoteLeft'
import { faQuoteRight } from '@fortawesome/free-solid-svg-icons/faQuoteRight'
import { faRedo } from '@fortawesome/free-solid-svg-icons/faRedo'
import { faSync } from '@fortawesome/free-solid-svg-icons/faSync'
import { faSearch } from '@fortawesome/free-solid-svg-icons/faSearch'
import { faSmile as faSmileO } from '@fortawesome/free-regular-svg-icons/faSmile'
import { faSpinner } from '@fortawesome/free-solid-svg-icons/faSpinner'
import { faStar } from '@fortawesome/free-solid-svg-icons/faStar'
import { faStar as faStarO } from '@fortawesome/free-regular-svg-icons/faStar'
import { faTv } from '@fortawesome/free-solid-svg-icons/faTv'
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes'
import { faTimesCircle as faTimesCircleO } from '@fortawesome/free-regular-svg-icons/faTimesCircle'
import { faXTwitter } from '@fortawesome/free-brands-svg-icons/faXTwitter'
import { faTwitter } from '@fortawesome/free-brands-svg-icons/faTwitter'
import { faUser } from '@fortawesome/free-solid-svg-icons/faUser'
import { faUserPlus } from '@fortawesome/free-solid-svg-icons/faUserPlus'
import { faVenusMars } from '@fortawesome/free-solid-svg-icons/faVenusMars'
import { faWifi } from '@fortawesome/free-solid-svg-icons/faWifi'
import validatorHasOptionSelected from './mixins/validator-hasOptionSelected'
import validatorHasCheckboxSelected from './mixins/validator-hasCheckboxSelected'
import { fab } from '@fortawesome/free-brands-svg-icons'
// require rather than import as there is no ts declaration file for these modules
import * as Vuebar from 'vuebar'
import * as VueGoogleMaps from 'vue2-google-maps'
import * as linkify from 'vue-linkify'
// eslint-disable-next-line
import VueAppInsights from 'vue-application-insights'
library.add(fab)
library.add(faAngleDoubleLeft)
library.add(faAngleDoubleRight)
library.add(faAngleDown)
library.add(faAngleLeft)
library.add(faAt)
library.add(faBan)
library.add(faBars)
library.add(faBinoculars)
library.add(faCalendar)
library.add(faCaretDown)
library.add(faCaretRight)
library.add(faCheck)
library.add(faCheckCircle)
library.add(faCheckCircleO)
library.add(faChevronDown)
library.add(faChevronLeft)
library.add(faChevronRight)
library.add(faChevronUp)
library.add(faCircle)
library.add(faClockO)
library.add(faCommentO)
library.add(faCopyright)
library.add(faEdit)
library.add(faEnvelope)
library.add(faEuroSign)
library.add(faEye)
library.add(faEyeSlash)
library.add(faExclamationTriangle)
library.add(faFacebookF)
library.add(faFrown)
library.add(faSaveO)
library.add(faGlobe)
library.add(faGoogle)
library.add(faHandshakeO)
library.add(faHeartO)
library.add(faHome)
library.add(faHospital)
library.add(faIdBadge)
library.add(faInfoCircle)
library.add(faLightbulbO)
library.add(faMapMarkerAlt)
library.add(faPencilAlt)
library.add(faPhone)
library.add(faPlus)
library.add(faPuzzlePiece)
library.add(faQuoteLeft)
library.add(faQuoteRight)
library.add(faRedo)
library.add(faSync)
library.add(faSearch)
library.add(faSmileO)
library.add(faSpinner)
library.add(faStar)
library.add(faStarO)
library.add(faTv)
library.add(faTimes)
library.add(faTimesCircleO)
library.add(faXTwitter)
library.add(faTwitter)
library.add(faUser)
library.add(faUserPlus)
library.add(faVenusMars)
library.add(faWifi)

Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.component('font-awesome-layers', FontAwesomeLayers)

// Add web config in here to force Webpack to pick it up
// import WebConfig from './web.config' // eslint-disable-line no-unused-vars

Vue.config.productionTip = false

// set up global event bus
var eventBus = new Vue()
Object.defineProperties(Vue.prototype, {
  $bus: {
    get: function () {
      return eventBus
    }
  }
})

// vue modules
Vue.use(VueI18n)
Vue.use(VeeValidate)

VeeValidate.Validator.extend('option-selected', validatorHasOptionSelected)
VeeValidate.Validator.extend('checkbox-selected', validatorHasCheckboxSelected)

Vue.use(Vuebar)
Vue.use(BootstrapVue)
// The HTTP client for Vue.js (https://github.com/pagekit/vue-resource/blob/develop/docs/http.md)
Vue.use(VueResource)
Vue.use(VueMeta)
// Google maps for directions
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyDO90r2afD0-4BS18qa1fdAxumddjgCutY', // public key for development purposes only'AIzaSyAOVYRIgupAurZup5y1PRh8Ismb1A3lLao',
    libraries: 'places,marker' // include 'marker' library
  },
  installComponents: true // for auto-registration of components
})

Vue.use(VueAppInsights, {
  // id: 'd38ae400-607d-45d0-80d5-40384e7a5562', // test
  id: '19dc96a8-7e96-4fd0-a334-f3c3a6afe67c', // production
  router: router,
  baseName: 'Oma-SPR'
})

// add lodash to all components. Not ideal way of doing this
// @ts-ignore
window._ = require('lodash')
// require('lodash')
require('./router/interceptor.js')

// add any mixins that are required for the whole site
Vue.mixin(CommonMixins)
Vue.mixin(Alerts)
Vue.directive('linkified', linkify)

if (process.env.NODE_ENV === 'production') {
  const script = window.document.createElement('script')
  script.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
   new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
   j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
   })(window,document,'script','dataLayer','GTM-T23XL4M');`
  // eslint-disable-next-line no-unused-expressions
  window.document.querySelector('head')?.append(script)
}

// load static content
var promiseDistricts = Vue.http.get(store.state.apiBases.contentservice + store.state.apiEndpoints.alldistricts[0])
var promiseInstitutions = Vue.http.get(store.state.apiBases.contentservice + store.state.apiEndpoints.allinstitutions[0])
var promiseNetFriendshipGroups = Vue.http.get(store.state.apiBases.friendservice + store.state.apiEndpoints.friendnetgroups[0])
var promiseSections = Vue.http.get(store.state.apiBases.contentservice + store.state.apiEndpoints.allsections[0])
var prosimiseJoinableZips = Vue.http.get(store.state.apiBases.contentservice + store.state.apiEndpoints.joinablezipcodes[0])
var promiseJoinSections = Vue.http.get(store.state.apiBases.contentservice + store.state.apiEndpoints.joinsections[0])
var promiseTranslations = Vue.http.get(store.state.apiBases.contentservice + store.state.apiEndpoints.translationscached[0])

// load enum values
var promiseAvailabilityFrequency = Vue.http.get(store.state.apiBases.contentservice + store.state.apiEndpoints.availabilityfrequencies[0])
var promiseAvailabilityTimes = Vue.http.get(store.state.apiBases.contentservice + store.state.apiEndpoints.availabilitytimes[0])
var promiseCustomerStates = Vue.http.get(store.state.apiBases.contentservice + store.state.apiEndpoints.customerstates[0])
var promiseCustomerTypes = Vue.http.get(store.state.apiBases.contentservice + store.state.apiEndpoints.customertypes[0])
var promiseCustomerLanguages = Vue.http.get(store.state.apiBases.contentservice + store.state.apiEndpoints.customerlanguages[0])
var promiseFriendshipTypes = Vue.http.get(store.state.apiBases.contentservice + store.state.apiEndpoints.friendshiptypes[0])
var promiseEventCategories = Vue.http.get(store.state.apiBases.contentservice + store.state.apiEndpoints.eventcategories[0])
var promiseGenders = Vue.http.get(store.state.apiBases.contentservice + store.state.apiEndpoints.genders[0])
var promiseInterests = Vue.http.get(store.state.apiBases.contentservice + store.state.apiEndpoints.interests[0])
var promiseInvolvedEventCategories = Vue.http.get(store.state.apiBases.contentservice + store.state.apiEndpoints.involvedeventcategories[0])
var promiseNetCustomerTypes = Vue.http.get(store.state.apiBases.contentservice + store.state.apiEndpoints.netcustomertypes[0])
var promiseNetFriendshipTypes = Vue.http.get(store.state.apiBases.contentservice + store.state.apiEndpoints.netfriendshiptypes[0])
var promisePreferredGenders = Vue.http.get(store.state.apiBases.contentservice + store.state.apiEndpoints.preferredgenders[0])
var promisePreferredAges = Vue.http.get(store.state.apiBases.contentservice + store.state.apiEndpoints.preferredages[0])
var promisePreferredContactMethods = Vue.http.get(store.state.apiBases.contentservice + store.state.apiEndpoints.preferredcontactmethods[0])
var promiseGroupLanguages = Vue.http.get(store.state.apiBases.contentservice + store.state.apiEndpoints.grouplanguages[0])
var promiseZipCodes = Vue.http.get(store.state.apiBases.contentservice + store.state.apiEndpoints.zipcodes[0])
// load other static content
var promiseDatabaseAvailabilityTimes = Vue.http.get(store.state.apiBases.contentservice + store.state.apiEndpoints.databaseAvailabilitytimes[0])

Promise.all([
  promiseTranslations,
  promiseSections,
  promiseDistricts,
  promiseInstitutions,
  promiseNetFriendshipGroups,
  promiseGenders,
  promisePreferredGenders,
  promisePreferredAges,
  promiseAvailabilityFrequency,
  promiseAvailabilityTimes,
  promiseCustomerTypes,
  promiseNetCustomerTypes,
  promiseFriendshipTypes,
  promiseNetFriendshipTypes,
  promiseInterests,
  promiseCustomerStates,
  promiseCustomerLanguages,
  promiseDatabaseAvailabilityTimes,
  promisePreferredContactMethods,
  promiseEventCategories,
  promiseInvolvedEventCategories,
  promiseGroupLanguages,
  promiseZipCodes,
  promiseJoinSections,
  prosimiseJoinableZips
]).then(
  response => {
    store.commit('setTranslations', response[0].body)
    store.commit('setSections', response[1].body)
    store.commit('setDistricts', response[2].body)
    store.commit('setInstitutions', response[3].body)
    store.commit('setNetFriendshipGroups', response[4].body)
    store.commit('setGenders', response[5].body)
    store.commit('setPreferredGenders', response[6].body)
    store.commit('setPreferredAges', response[7].body)
    store.commit('setAvailabilityFrequencies', response[8].body)
    store.commit('setAvailabilityTimes', response[9].body)
    store.commit('setCustomerTypes', response[10].body)
    store.commit('setNetCustomerTypes', response[11].body)
    store.commit('setFriendshipTypes', response[12].body)
    store.commit('setNetFriendshipTypes', response[13].body)
    store.commit('setCustomerInterests', response[14].body)
    store.commit('setCustomerStates', response[15].body)
    store.commit('setCustomerLanguages', response[16].body)
    store.commit('setDatabaseAvailabilityTimes', response[17].body.body)
    store.commit('setPreferredContactMethods', response[18].body)
    store.commit('setEventCategories', response[19].body)
    store.commit('setInvolvedEventCategories', response[20].body)
    store.commit('setGroupLanguages', response[21].body)
    store.commit('setZipCodes', response[22].body)
    store.commit('setJoinSections', response[23].body)
    store.commit('setJoinableZips', response[24].body)

    if (window.location.host.indexOf('rodakorset') >= 0) {
      store.commit('setLanguage', 'sv')
    }
    if (window.location.host.indexOf('localhost') >= 0) {
      store.commit('setLanguage', 'en')
    }
    if (window.location.host.indexOf('redcross') >= 0) {
      store.commit('setLanguage', 'en')
    }

    const i18n = new VueI18n({
      // @ts-ignore
      locale: store.state.language,
      fallbackLocale: 'fi', // if you have no message set for another language, use this one
      messages: store.state.translations
    })

    router.beforeEach(function (to, from, next) {
      // handle join wizard paging
      // TODO(JanneF): Perhaps we need paging for register wizard too?
      if (to.name === 'join' && from.name === 'join') {
        next(false)
        window.history.pushState(null, '', to.fullPath)
      } else {
        next()
      }
    })

    /* eslint-disable no-new */
    new Vue({
      el: '#app',
      i18n: i18n,
      store,
      router,
      render: h => h(App),
      template: '<App/>'
    })
  },
  _ => {
    // This is for offline development only. This branch
    // reached when there is an exception in connecting
    // to services in azure.
    const json = {}
    store.commit('setTranslations', json)

    const i18n = new VueI18n({
      // @ts-ignore
      locale: store.state.language,
      fallbackLocale: 'fi', // if you have no message set for another language, use this one
      messages: store.state.translations
    })

    /* eslint-disable no-new */
    new Vue({
      el: '#app',
      i18n: i18n,
      store,
      router,
      render: h => h(App),
      template: '<App :loadfailed="true"/>'
    })
  }
).catch(err => {
  console.log('Failed to load', err)
})
