<template>
  <div class="groupedit">
    <div class="space-between-row">
      <div class="left-outer">
        <forminput v-model="group.name"
                   v-validate="'required'"
                   :hint="$t('groups.name')"
                   name="group.name"
                   :required="true"
                   :is-valid="!errors.has('group.name')"
        />
      </div>
      <div class="simple-text right-outer">
        <div class="cap-label">
          {{ $t('groups.language') }}
        </div>
        <pillbox :items="languages" :selected="selectedlanguages" @change="onLanguageChanged">
        </pillbox>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <textareainput
          v-model="group.description"
          :title="$t('groups.description')"
          :rows="5"
        />
      </div>
    </div>
    <div class="row">
      <div v-if="group.groupType === 1" class="col-12 col-sm-6 col-md-4">
        <sprdropdownnumberkey
          v-model="group.category"
          v-validate="'required'"
          :title="$t('groups.category')"
          name="group.category"
          :items="groupCategories"
          :required="true"
          :is-valid="!errors.has('group.category')"
        />
      </div>
    </div>
    <div class="row">
      <div v-if="group.groupType === 1 && externalFormsAvailable" class="col-12 col-sm-6 col-md-4">
        <div>
          <dropdowninput
            v-model="selectedExternalForm"
            :title="$t('externalForm.addLinkTitle')"
            name="externalForm"
            :items="stringifiedItems"
            :required="false"
            is-valid
          />
        </div>
      </div>
    </div>
    <div class="row national">
      <div class="col-12">
        <simplecheckbox
          v-model="group.isAllLocations"
          :title="$t('groups.isAllLocations')"
          :aria-label="$t('groups.isAllLocations')"
        />
      </div>
    </div>
    <!-- Group Organizer -->
    <div v-if="isActionGroup()">
      <div class="row grouporganizer">
        <div class="col-12">
          <groupOrganizer
            v-validate="'hasOptionalGroupOrganizer'"
            :hint="$t('userAdmin.groupOrganizer')"
            name="userAdmin.groupOrganizer"
            :value="group.groupOrganizer"
            :required="false"
            :is-valid="!errors.has('userAdmin.groupOrganizer')"
            @input="onGroupOrganizerChanged"
          />
        </div>
      </div>
    </div>
    <div class="row button-row">
      <div class="col-12">
        <div class="d-flex justify-content-end">
          <sprbutton
            type="submit"
            :size="1"
            :title="$t('groups.save')"
            icon="chevron-right"
            class="spr-redborder button-width"
            click-event="saveGroup"
            @saveGroup="onSaveGroup"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang='ts'>
  import Vue from 'vue'
  import * as _ from 'lodash'
  import sprbutton from '../SPRButton.vue'
  import forminput from '../SPRSimpleText.vue'
  import textareainput from '../SPRSimpleTextArea.vue'
  import simplecheckbox from '../SPRSimpleCheckbox.vue'
  import groupOrganizer from '../SPRGroupOrganizersDropdown.vue'
  import sprdropdownnumberkey from '../SPRDropdownNumberKey.vue'
  import dropdowninput from '../../components/SPRSimpleDropdown.vue'
  import { ExternalFormUpdateDto } from '@/types'

  import pillbox from '../common/Pill/PillBox.vue'

  import { Validator } from 'vee-validate'
  import hasOptionalGroupOrganizer from '../../mixins/validator-hasOptionalGroupOrganizer.js'

  Validator.extend('hasOptionalGroupOrganizer', hasOptionalGroupOrganizer)

  // the data object
  export interface TemplateComponentData {
    updated: boolean,
    languages: any,
    externalForms: Array<ExternalFormUpdateDto>,
    selectedExternalForm: any,
    selectedlanguages: any,
    groupCategories: any
  }

  export default Vue.extend({
    name: 'GroupEdit',
    components: { sprbutton, textareainput, forminput, simplecheckbox, groupOrganizer, dropdowninput, sprdropdownnumberkey, pillbox },
    props: {
      group: {
        type: Object,
        default: null
      }
    },
    data (): TemplateComponentData {
      return {
        updated: false,
        languages: [],
        externalForms: [],
        selectedExternalForm: undefined,
        selectedlanguages: [],
        groupCategories: null

      }
    },
    computed: {
      stringifiedItems (): any {
        const items = _.map(this.externalForms, function (form) {
          return { value: form.id.toString(), text: form.name }
        })
        const noForm = { value: -1, text: this.$t('externalForm.noFormSelected') }

        items.unshift(noForm)
        return items
      },
      externalFormsAvailable (): boolean {
        return this.externalForms && this.externalForms.length > 0
      }
    },
    watch: {
      selectedExternalForm (newValue, oldValue) {
        if (this.externalForms) {
          //@ts-ignore
          const ef = this.externalForms.find(form => form.id.toString() === newValue)
          this.group.externalForm = ef
        }
      }
    },
    mounted () {
      const lang = this.$store.state.customerLanguages
      this.languages = this.$store.state.groupLanguages.filter((l) => l.id !== 0).map((v) => {
        return { id: v.id, text: 'actionLanguage.' + lang.find((l) => l.displayName.toUpperCase() === v.text.toUpperCase()).isoCode }
      })
      this.selectedlanguages = this.languages.filter((v) => v.id === null || v.id === 0 ? false : (v.id & this.group.languages) === v.id)
    },
    created () {
      this.getCategories()
      this.fetchExternalForms()
    },
    methods: {
      fetchExternalForms () {
        try {
          const url =
            this.$store.state.apiBases.externalformservice +
            this.$store.state.apiEndpoints.externalformsall[0]
          const response = this.$http.get(url).then((response) => {
            this.externalForms = response.data as Array<ExternalFormUpdateDto>
            if (this.group.externalForm) {
              this.selectedExternalForm = this.group.externalForm.id.toString()
            } else {
              this.selectedExternalForm = '-1'
            }
          })
        } catch (error) {
          console.error('Error fetching external forms:', error)
        }
      },
      onLanguageChanged (e) {
        this.group.languages = e.reduce((b, v) => b + v.id, 0)
        this.selectedlanguages = e
      },
      isActionGroup () {
        return this.group.groupType === 1 // public enum GroupType: 1-ActionGroup, 2-StandardGroup (OpenGroup)
      },
      getCategories () {
        var self = this
        this.groupCategories = _.map(this.$store.state.eventCategories, function (val, key) {
          return { value: val.id, text: self.$t('eventCategory.' + val.text) }
        })
      },
      onGroupOrganizerChanged (groupOrganizer) {
        this.group.groupOrganizer.groupOrganizerType = groupOrganizer.groupOrganizerType
        this.group.groupOrganizer.organizerId = groupOrganizer.organizerId
      },
      onSaveGroup () {
        var self = this
        this.$validator.validateAll().then(result => {
          if (result) {
            self.$emit('saveGroup', self.group)
          }
        })
      }
    }
  })
</script>

<style lang="scss">
  @import '~@/assets/scss/_variables.scss';
  .row {
    display: flex;

  }
  .space-between-row{
    display: flex;
    justify-content: space-between;
  }
  .left-outer {
    padding-left: 15px;
  }
  .right-outer {
    margin-right: 20px;
  }
  .cap-label{
    margin-bottom: 8px;
  }
  .multiselect{
    margin: 0px;
    min-height: "";
  }
  .multiselect__tags{
    min-height: 37px;
    border: solid 1px #DDDDDD;
  }
  .multiselect__single{
    font-size: 16px;
    font-family: "Signa-Light", Sans-Serif;
  }
  .groupedit {
    .cancel-button {
      margin-right: 5px;
    }
    .update-success {
      color:$brand-success;
      display:inline-block;
      margin-right:10px;
      margin-top:15px;
    }

    .national {
      margin-left:5px;
    }

    .update-leave-active {
      transition: opacity .5s;
    }
    .update-leave-to /* .fade-leave-active below version 2.1.8 */ {
      opacity: 0;
    }
  }
</style>
