// mixins used on all pages of the site
import JwtDecode from 'jwt-decode'
import Vue from 'vue'
import Store from '../store/index'
import { ProfileDeleteRequestOrigin } from '../types/index'
export default {
  methods: {
    // this is for updating your OWN profile
    // todo: Another one for updating other people's profile (maybe not needed - see where it is used)
    updateProfile: function (successCallback, failureCallback) {
      var profile = this.$store.state.profile
      if (profile) {
        var uri = this.$store.state.apiBases.userservice + this.$store.state.apiEndpoints.updateidentuser[0]
        return this.$http.put(uri, profile)
          .then(resp => {
            return this.fetchProfilePromise()
          })
          .then(resp => {
            return this.getShortToken()
          })
          .then(
            response => {
              if (successCallback != null) {
                successCallback(response)
              }
            },
            failure => {
              if (failureCallback != null) {
                failureCallback(failure)
              }
            })
      }
    },
    updateOwnProfile: function (successCallback, failureCallback) {
      var profile = this.$store.state.profile
      if (profile) {
        var uri = this.$store.state.apiBases.userservice + this.$store.state.apiEndpoints.updateownprofile[0]
        return this.$http.put(uri, profile)
          .then(resp => {
            return this.fetchProfilePromise()
          })
          .then(resp => {
            return this.getShortToken()
          })
          .then(
            response => {
              if (successCallback != null) {
                successCallback(response)
              }
            },
            failure => {
              if (failureCallback != null) {
                failureCallback(failure)
              }
            })
      }
    },
    getShortToken: function (longToken) {
      return new Promise((resolve, reject) => {
        Vue.http.get(Store.state.token.refresh, { headers: { Authorization: 'bearer ' + longToken } }).then(result => {
          sessionStorage.setItem('shorttoken', result.body)
          Store.dispatch('setSessionJwtToken', result.body)
          resolve(result.body)
        }, f => {
          console.log(f)
          if (f.data === 'User has logged out.') {
            this.logout('/login')
          }
        })
      })
    },
    logout: function (redirect) {
      this.$store.state.profile = {}
      this.$store.commit('logout')
      var token = sessionStorage.getItem('authtoken')
      this.$http.get(this.$store.state.apiBases.userservice + this.$store.state.apiEndpoints.logout[0] + '?authtoken=' + token).then(response => {
        sessionStorage.removeItem('authtoken')
        sessionStorage.removeItem('shorttoken')
        if (redirect) {
          this.$router.push(redirect)
        }
        location.reload()
      },
      response => {
        console.log('error while logging out', response)
        sessionStorage.removeItem('authtoken')
        sessionStorage.removeItem('shorttoken')
        if (redirect) {
          this.$router.push(redirect)
        }
        location.reload()
      })
    },
    fetchProfile: function (successCallback, failureCallback) {
      if (!this.$store.state.gettingProfile) {
        this.$store.commit('profileFetchStarted')
        var self = this
        this.$http.get(this.$store.state.apiBases.userservice + this.$store.state.apiEndpoints.currentuser[0]).then(response => {
          self.$store.commit('setProfile', response.body)
          if (successCallback !== null) {
            successCallback(response)
          }
        },
        response => {
          if (failureCallback !== null) {
            failureCallback(response)
          }
        })
      }
    },
    fetchProfilePromise () {
      return new Promise((resolve, reject) => {
        this.fetchProfile((response) => {
          resolve(response)
        }, (err) => {
          reject(err)
        })
      })
    },
    getProfileDeleteRequestOriginText: function (id) {
      if (!id) { return 'Oma' }

      return ProfileDeleteRequestOrigin[id]
    },
    getMembershipStatusText: function (id) {
      // MembershipStatus {
      //  Active = 2,
      //  Deceased = 4,
      //  Terminated = 3,
      //  Transfer = 1,
      //  Undefined = 0
      // }

      switch (id.toString()) {
        case '0': return this.$t('membershipStatus.undefined')
        case '1': return this.$t('membershipStatus.transfer')
        case '2': return this.$t('membershipStatus.active')
        case '3': return this.$t('membershipStatus.terminated')
        case '4': return this.$t('membershipStatus.deceased')
      }
    },
    getAreaOfInterestText: function (id) {
      return _.find(this.$store.state.profileAreasOfInterest, function (o) { return o.id === id }).text
    },
    getInvolvedEventCategories: function (id) {
      return _.find(this.$store.state.involvedEventCategories, function (o) { return o.id === id }).text
    },
    getPersonalSkillsText: function (id) {
      return _.find(this.$store.state.profilePersonalSkills, function (o) { return o.id === id }).text
    },
    normalizeTranslation: function (isoCode) {
      if (!isoCode) {
        return ''
      }
      var index = Object.keys(this.$store.state.translations[this.firstLanguage].languages).indexOf(isoCode)
      if (index !== -1) {
        return this.$t('languages.' + isoCode)
      } else {
        var el = _.find(this.$store.state.allLanguages, x => {
          return x.isoCode === isoCode
        })
        return el.displayName
      }
    },
    formatDate: function (isoDate) {
      var d = new Date(isoDate)
      return d.getDate() + '.' + (d.getMonth() + 1) + '.' + d.getFullYear()
    },
    lcFirst: function (str) {
      return str.charAt(0).toLowerCase() + str.slice(1)
    },
    toLowerCamelCase: function (key) {
      if (!key) return null
      var keyLowerCamel = key.charAt(0).toLowerCase() + key.slice(1)
      return keyLowerCamel
    },
    databaseIdToText: function (id, collection) {
      if (isNaN(id)) return id
      var item = _.find(collection, function (x) { return x.id === id })
      if (!item) return id
      var key = item.name
      var keyLowerCamel = this.toLowerCamelCase(key)
      return keyLowerCamel
    },
    idToText: function (id, collection) {
      if (isNaN(id)) return id
      var item = _.find(collection, function (x) { return x.id === id })
      if (!item) return id
      return item.text
    },
    prune: function (obj) {
      for (var o in obj) {
        if (!obj[o]) {
          delete obj[o]
        }
      }
      return obj
    },
    hasClaim: function (id, type) {
      var token = this.jwtToken
      if (token) {
        var ids = token[type]
        if (!ids) {
          return false
        }
        if (Array.isArray(ids)) {
          return ids.indexOf(id) >= 0
        }
        if (ids.toString().includes(';')) {
          return ids.split(';').indexOf(id) > -1
        }
        return ids === id
      }
      return false
    },
    hasRole: function (role) {
      return this.hasClaim(role, 'http://schemas.microsoft.com/ws/2008/06/identity/claims/role')
    },
    hasAdminRole () {
      this.hasClaim('Admin', 'http://schemas.microsoft.com/ws/2008/06/identity/claims/role')
      const token = Store.state.sessionJwtToken
      if (!token) return false
      const role = 'Admin'
      const type = 'http://schemas.microsoft.com/ws/2008/06/identity/claims/role'
      if (token) {
        var ids = token['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']
        if (!ids) {
          return false
        }
        if (Array.isArray(ids)) {
          return ids.indexOf(role) >= 0
        }
        if (ids.toString().includes(';')) {
          return ids.split(';').indexOf(role) > -1
        }
        return ids === role
      }
      return false
    },
    inSection: function (section) {
      section = section.toString() // the section claim is a string
      return this.hasClaim(section, 'SectionMembership')
    },
    inDistrict: function (district) {
      district = district.toString()
      return this.hasClaim(district, 'DistrictMembership')
    },
    decodeJwtToken: function (token) {
      return JwtDecode(token)
    },
    allowedInSection: function (section, district) {
      return this.hasRole('Admin') || this.hasRole('NationalAdmin') || this.hasRole('NationalEmployee') ||
      (this.hasRole('Employee') && this.inDistrict(district)) ||
        ((this.hasRole('J1') || this.hasRole('J2')) && this.inSection(section))
    },
    // NOTE : All the methods below are ports of the back end permissions methods. Ensure these match the back end for consistency!
    hasAdmin () {
      return this.hasRole('Admin')
    },
    hasAdminToDistrict (district) {
      return this.hasRole('Admin') ||
            (this.hasRole('Employee') && this.inDistrict(district)) ||
            (this.hasRole('DistrictAdmin') && this.inDistrict(district)) ||
            this.hasRole('NationalAdmin') ||
            this.hasRole('NationalEmployee') //TODO: Do we need to check if the NationalAdmin is inDistrict?
    },
    hasAdminToSection (sectionId, extendToJ2 = false) {
      let districtId = 0
      const section = _.find(this.$store.state.sections, (section) => {
        return Number(section.id) === Number(sectionId)
      })
      if (section) {
        districtId = section.districtId
      } else {
        return false
      }
      return this.hasAdminToDistrict(districtId) ||
             (this.hasRole('J1') && this.inSection(sectionId)) ||
             ((this.hasRole('J2') && extendToJ2) && this.inSection(sectionId))
    },
    hasAdminToFriendship (districtId = 0) {
      return this.hasRole('Admin') ||
             this.hasRole('FriendshipAdmin') ||
             (districtId > 0 && this.hasRole('DistrictFriendshipAdmin') && this.inDistrict(districtId.toString()))
    },
    hasAdminToFriendManagementGroup (fmg) {
      return this.hasAdminToFriendship() ||
             (this.hasRole('FriendshipGroupManager') && this.belongsToFriendshipGroup(fmg)) ||
             (this.hasRole('DistrictFriendshipAdmin') && this.belongsToFriendshipGroup(fmg))
    },
    hasAdminToFriendManagementGroups (fmgs) {
      if (this.hasAdminToFriendship()) return true

      if (this.hasRole('FriendshipGroupManager') || this.hasRole('DistrictFriendshipAdmin')) {
        var found = _.find(fmgs, (gp) => {
          return this.belongsToFriendshipGroup(gp)
        })
        return Boolean(found)
      }
      return false
    },
    hasBrokerRole (fmg) {
      return this.hasAdminToFriendship() ||
             (this.hasRole('DistrictFriendshipAdmin') && this.belongsToFriendshipGroup(fmg)) ||
             (this.hasRole('FriendshipGroupManager') && this.belongsToFriendshipGroup(fmg)) ||
             (this.hasRole('FriendshipBroker') && this.belongsToFriendshipGroup(fmg))
    },
    hasNetBrokerRole (fmg) {
      return this.hasAdminToFriendship() ||
             (this.hasRole('FriendshipGroupManager') && this.belongsToFriendshipGroup(fmg)) ||
             (this.hasRole('NetFriendshipBroker') && this.belongsToFriendshipGroup(fmg) && _.find(this.$store.state.netFriendshipGroups, x => x === Number(fmg)))
    },
    hasTrainingRole () {
      return this.hasRole('Admin') ||
             this.hasRole('NationalAdmin') ||
             this.hasRole('FriendshipAdmin') ||
             this.hasRole('DistrictAdmin') ||
             this.hasRole('DistrictFriendshipAdmin') ||
             this.hasRole('FriendshipTrainer')
    },
    isCurrentUser (profileId) {
      return this.hasClaim(profileId, 'ProfileId')
    },
    belongsToFriendshipGroup (fmg) {
      var token = this.jwtToken
      fmg = Number(fmg)
      if (token) {
        var fmgids = token.FriendshipGroups ? token.FriendshipGroups.split(';') : null
        var netFmgids = token.NetFriendshipGroups ? token.NetFriendshipGroups.split(';') : null

        if (!fmgids) fmgids = []
        if (!netFmgids) netFmgids = []

        if (!fmgids.length && !netFmgids.length) return false

        // get just the ids and dedupe
        fmgids = [...new Set(Array.from(fmgids, x => this.getFmgId(x)))]
        netFmgids = [...new Set(Array.from(netFmgids, x => this.getFmgId(x)))]

        return fmgids.indexOf(fmg) > -1 || netFmgids.indexOf(fmg) > -1
      }
      return false
    },
    getFmgId (fmgclaim) {
      // fmgs are in the format 'fmgname:id' - just return id bit
      var colonIndex = fmgclaim.lastIndexOf(':')
      if (colonIndex === -1) return 0
      return Number(fmgclaim.substring(colonIndex + 1))
    },
    httpPromise: function (verb, uri, payload, self) {
      if (!self) {
        self = this
      }

      return new Promise(function (resolve, reject) {
        self.$http[verb](uri, payload).then(success => {
          resolve(success.data)
        }).catch(error => {
          reject(error)
        })
      })
    }
  },
  computed: {
    authHeader: function () {
      var token = sessionStorage.getItem('authtoken')
      if (token) {
        return { headers: { Authorization: 'Bearer ' + token } }
      } else {
        return null
      }
    },
    firstLanguage: function () {
      return Object.keys(this.$store.state.translations)[0]
    },
    jwtToken: function () {
      var st = Store.state.sessionJwtToken
      // var st = sessionStorage.getItem('shorttoken')
      if (st) {
        // return JwtDecode(st)
        return st
      }
      return null
    }
  }
}
