<template>
  <div class="simpletext" :class="{ 'compact': compact }">
    <label v-if="hint" class="cap-label">{{ hint }}{{ required ? "*" : "" }}</label>
    <div class="input-group">
      <div v-if="icon" class="input-group-prepend">
        <span class="input-group-text"><font-awesome-icon :icon="icon" /></span>
      </div>
      <input ref="inputRef"
             :type="computedType"
             :class="{ 'spr-danger': !isValid, 'spr-roundbox-1 text-input form-control': true, 'center': centerText, 'disabled': disabled }"
             :required="required"
             :placeholder="placeholder"
             :value="value"
             :disabled="disabled"
             :list="list"
             :maxlength="maxlength"
             @input="$emit('input', $event.target.value)"
             @change="$emit('change', $event.value, $event.target.value)"
      >
    </div>
  </div>
</template>

<script>
  /* eslint-disable */
  export default {
    name: 'SprSimpleText',
    props: {
      hint: {
        type: String,
        default: ''
      },
      isValid: {
        type: Boolean,
        default: true
      },
      centerText: Boolean,
      unit: {
        type: String,
        default: ''
      },
      inputType: {
        type: String,
        default: ''
      },
      value: {
        type: String,
        default: ''
      },
      required: {
        type: Boolean,
        default: false
      },
      placeholder: {
        type: String,
        default: ''
      },
      compact: Boolean,
      disabled: Boolean,
      icon: {
        type: [String, Array],
        default: ''
      },
      list: {
        type: [Array, String, null],
        default: null
      },
      maxlength: {
        type: [Number, null],
        default: null
      }
    },
    // @ts-ignore
    $_veeValidate: {
      value () {
        return this.$refs.inputRef.value
      }
    },
    computed: {
      computedType: function () {
        if (this.inputType) {
          return this.inputType
        }
        return 'text'
      }
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
  @import "../assets/scss/_variables.scss";

  
  .simpletext {
    padding-bottom: 1rem;
    label {
      padding: 0 20px;
      color: $brand-red;
    }

    &.compact {
      padding-bottom: 0.5rem;

      label {
        font-size: 0.8em;
        padding: 0 10px;
        margin-bottom: 0;
      }
      .text-input {
        font-size: 0.8em;
        padding: 5px 10px;
      }
    }
    input {
      &:focus-visible {
        color: #495057;
        border: solid 2px $brand-grey5;
        outline: 0;
        box-shadow: 0 0 0 0.2rem $brand-grey3;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      }
    }
    .text-input {
      display: block;
      box-sizing: border-box;
      font-family: "Signa-Light", Sans-Serif;
      border: 2px solid $brand-grey3;
      padding: 10px 20px;

      &.disabled {
        cursor: not-allowed;
      }
    }

    .spr-danger {
      border: 2px solid $brand-red !important;
    }

    .center {
      text-align: center;
    }

  }
</style>
