<style lang="scss">
  @import "../../assets/scss/_variables.scss";
  .eventadmins {
    .pill {
      margin-right: 5px;

      &.readonly {
        border-color:$brand-grey7;
        color:$brand-grey7;
        background-color:$brand-grey1;
        cursor:not-allowed;
      }
    }
    .emailinput {
      display:inline-block;
    }
    .add-button {
      display: inline-block;
    }
  }
</style>

<template>
  <div class="eventadmins">
    <div class="row">
      <div class="col-12">
        <span class="cap-label">{{ $t('eventPage.eventAdminTitle') }}</span>
      </div>
    </div>
    <div v-if="isEditMode" class="row">
      <div class="col-12">
        <div class="emailinput">
          <sprinput
            v-model="inputEmail"
            v-validate="'email'"
            input-type="email"
            :placeholder="placeholdertext"
            name="eventPage.adminEmail"
            :is-valid="!errors.has('eventPage.adminEmail')"
          />
        </div>
        <sprbutton
          :size="5"
          :title="$t('eventPage.addAdmin')"
          click-event="addAdmin"
          class="add-button"
          @addAdmin="onAddAdmin"
        />
      </div>
    </div>
    <div class="row">
      <div v-if="admins" class="col-12">
        <span v-if="isEditMode">
          <pill v-for="admin in filteredAdmins" :key="admin.email" v-model="admin.email" :class="{'readonly': isReadonly(admin)}" :show-delete="!isReadonly(admin)" @remove="onRemoveAdmin(admin)" />
        </span>
        <div v-else>
          <div v-for="admin in filteredAdmins" :key="admin.email">
            {{ admin.email }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import Vue from 'vue'
  import * as _ from 'lodash'
  import sprbutton from '../SPRButton.vue'
  import sprinput from '../SPRSimpleText.vue'
  import pill from '../SPRPill.vue'
  import { EventAdminDto } from '@/types'
  import { TranslateResult } from 'vue-i18n'

  // the data object
  export interface TemplateComponentData {
    inputEmail: string | undefined
  }

  export default Vue.extend({
    name: 'EventAdmins',
    components: {
      sprbutton,
      sprinput,
      pill
    },
    props: {
      admins: {
        type: Array as () => Array<EventAdminDto>,
        default () {
          return [] as Array<EventAdminDto>
        }
      },
      isEditMode: {
        type: Boolean,
        default: false
      }
    },
    data (): TemplateComponentData {
      return {
        inputEmail: undefined
      }
    },
    computed: {
      placeholdertext (): string | TranslateResult {
        return this.$t('eventPage.addAdminEmailPlaceholder')
      },
      filteredAdmins (): Array<EventAdminDto> {
        return _.map(_.groupBy(this.admins, 'email'), emailGroup => {
          if (emailGroup.length === 1) {
            return emailGroup[0]
          } else {
            return _.filter(emailGroup, admin => { return !admin.delegatedByProfileId })[0]
          }
        })
      }
    },
    methods: {
      onAddAdmin (): void {
        this.$validator.validateAll().then(resp => {
          if (!this.inputEmail || !this.inputEmail.trim().length) {
            return
          }
          if (resp) {
            if (!this.admins.find(admin => admin.email.toLowerCase() === this.inputEmail?.toLowerCase() && !admin.delegatedByProfileId)) {
              this.$emit('addAdmin', this.inputEmail.toLowerCase())
            }
          }
          this.inputEmail = undefined
        })
      },
      onRemoveAdmin (admin: EventAdminDto): void {
        this.$emit('removeAdmin', admin)
      },
      isReadonly (admin): void {
        return admin.isCreator || admin.delegatedByProfileId
      }
    }
  })
</script>
