<template>
  <div class="group create col-12">
    <backbar :title="$t('groups.createGroup')" />
    <div class="container">
      <div class="form-container">
        <div class="row">
          <div class="col-md-8 col-sm-12">
            <div class="row">
              <div class="col-12">
                <forminput v-model="group.name"
                           v-validate="'required'"
                           :hint="$t('groups.name')"
                           name="group.name"
                           :required="true"
                           :is-valid="!errors.has('group.name')"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <textareainput
                  v-model="group.description"
                  :title="$t('groups.description')"
                />
              </div>
            </div>
            <div class="simple-text right-outer">
              <div class="cap-label">
                {{ $t('groups.language') }}
              </div>
              <pillbox :items="languages" :selected="selectedLanguages" @change="onLanguageChanged">
              </pillbox>
            </div>

            <div class="row">
              <simplecheckbox
                v-model="group.isAllLocations"
                :title="$t('groups.isAllLocations')"
                :aria-label="$t('groups.isAllLocations')"
              />
            </div>
            <div class="row button-row">
              <div class="col-12">
                <div class="d-flex justify-content-end">
                  <sprbutton
                    type="submit"
                    :size="1"
                    :title="$t('groups.save')"
                    icon="chevron-right"
                    class="spr-redborder button-width"
                    click-event="createGroup"
                    @createGroup="createGroupButton"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import backbar from '../BackBar.vue'
  import sprbutton from '../SPRButton.vue'
  import forminput from '../SPRSimpleText.vue'
  import textareainput from '../SPRSimpleTextArea.vue'
  import simplecheckbox from '../SPRSimpleCheckbox.vue'
  import pillbox from '../common/Pill/PillBox.vue'

  export default {
    name: 'CreateGroup',
    components: { backbar, sprbutton, textareainput, forminput, simplecheckbox, pillbox },
    props: {
      type: {
        type: Number,
        default () {
          return 2 // friendship group
        }
      }
    },
    data () {
      return {
        group: {
          name: '',
          description: '',
          groupCreatorId: -1,
          members: [],
          isAllLocations: false,
          groupType: this.type,
          sectionId: null,
          languages: 0
        },
        languages: [],
        selectedLanguages: []
      }
    },
    mounted () {
      const lang = this.$store.state.customerLanguages
      this.languages = this.$store.state.groupLanguages.filter((l) => l.id !== 0).map((v) => {
        return { id: v.id, text: 'actionLanguage.' + lang.find((l) => l.displayName.toUpperCase() === v.text.toUpperCase()).isoCode }
      })
    },
    created () {
      this.group.sectionId = Number(this.jwtToken.SectionMembership)
    },
    methods: {
      onLanguageChanged (e) {
        this.group.languages = e.reduce((b, v) => b + v.id, 0)
        this.selectedLanguages = e
      },
      createGroupButton: function () {
        var self = this
        this.$validator.validateAll().then((successful) => {
          if (successful) {
            var url = `${self.$store.state.apiBases.userservice + this.$store.state.apiEndpoints.groupsectionadd[0]}`
            this.$http.post(url, self.group).then((resp) => {
              self.$router.push(`/groups/group/${resp.body}`)
            })
          }
        })
      }
    }
  }
</script>

<style lang="scss">
  @import '~@/assets/scss/_variables.scss';

  .group.create {
    background: #fff;

  .spr-searchbox {
    border: 1px solid $brand-grey4;
  }

  .button-row {
    margin-bottom: 40px;
    .spr-button  {
      background: $brand-red;
      color: #fff;
    }
  }

  .spr-simple-checkbox.pb-3 {
    padding-bottom: 0!important;
  }

  .cap-label {
    color: $brand-red;
    display: block;
    margin: 20px 0;
  }

}

</style>
