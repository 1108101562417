<template>
  <div class="volunteer-actions row button-row">
    <div class="col buttons">
      <!-- Copied/moved from VolunteerPreview -->
      <div class="edit-volunteer">
        <sprbutton
          :size="4"
          :title="$t('volunteerPreview.editVolunteerButton')"
          click-event="edit"
          class="edit-button"
          @edit="editVolunteer"
        />
      </div>
      <div v-if="!inFriendship" class="disable-volunteer">
        <sprbutton
          :size="4"
          :title="activityText"
          click-event="disable"
          class="disable-button"
          :class="{ 'button-disabled': loading }"
          @disable="toggleActivity"
        />
      </div>
      <div v-else class="disable-volunteer">
        <div class="nodeletewarning">
          {{ $t('customerSearch.inFriendshipNoDelete') }}
        </div>
        <sprbutton
          :title="this.$t('volunteerPreview.disableVolunteerButton')"
          :size="4"
          class="disable-button button-disabled"
        />
      </div>
      <div class="volunteer-onhold">
        <onhold
          v-if="!inFriendship"
          :on-hold="onHoldValue"
          @update="onHoldUpdate"
        />
        <sprbutton
          v-else
          :title="$t('customerSearch.showHoldCustomerButton')"
          :size="4"
          class="disable-button button-disabled"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import Vue from 'vue'
  import { LocaleMessage } from 'vue-i18n'
  import onhold from './OnHold.vue'
  import sprbutton from '../SPRButton.vue'
  import { UserProfileDto, CustomerState, FriendManagementDto } from '@/types/index'
  import { OnHoldData } from '@/types/custom'

  export default Vue.extend({
    name: 'VolunteerActions',

    components: { onhold, sprbutton },
    props: {
      value: {
        type: Object as () => UserProfileDto,
        required: true
      },
      group: {
        type: Object as () => FriendManagementDto,
        required: true
      },
      loaded: {
        type: Boolean,
        default: false
      },
      loading: {
        type: Boolean,
        default: false
      }
    },
    data () {
      return {
      }
    },
    computed: {
      volunteerState (): CustomerState {
        return this.group.netFriendship ? this.value.friendInfo.netProfile.state : this.value.friendInfo.standardProfile.state
      },
      activityText (): string | LocaleMessage {
        return this.isInactive ? this.$t('volunteerPreview.activateVolunteerButton') : this.$t('volunteerPreview.disableVolunteerButton')
      },
      isInactive (): boolean {
        return this.volunteerState === CustomerState.NonActive
      },
      inactiveText (): string | LocaleMessage {
        if (this.value.friendInfo) {
          switch (this.volunteerState) {
          case CustomerState.PendingApproval:
            return this.$t('customerSearch.noMatchingNotInterviewed')
          case CustomerState.NonActive:
            return this.$t('customerSearch.noMatchingRemoved')
          case CustomerState.OnHold:
            return this.$t('customerSearch.noMatchingOnHold')
          }
        }

        return ''
      },
      inFriendship (): boolean {
        return (this.volunteerState === CustomerState.ActiveFriendship) || (this.volunteerState === CustomerState.PendingFriend)
      },
      onHoldValue (): OnHoldData {
        return {
          onHold: this.group.netFriendship ? this.value.friendInfo.netProfile.state === CustomerState.OnHold : this.value.friendInfo.standardProfile.state === CustomerState.OnHold,
          onHoldDeadline: this.group.netFriendship ? this.value.friendInfo.netProfile.onHoldDeadline : this.value.friendInfo.standardProfile.onHoldDeadline,
          onHoldDetails: this.group.netFriendship ? this.value.friendInfo.netProfile.onHoldDetails : this.value.friendInfo.standardProfile.onHoldDetails
        }
      }
    },
    methods: {
      editVolunteer () {
        var isNonIdent = this.value.isNonIdent
        var isNetGroup = this.group.netFriendship
        var identPath = isNonIdent ? '' : '/ident'
        var groupTypePath = isNetGroup ? 'netprofile' : 'standardprofile'
        this.$router.push(`/friend/${groupTypePath}${identPath}/edit/${this.group.id}/${this.value.friendInfo.id}`)
      },
      onHoldUpdate (onHold: OnHoldData) {
        if (this.group.netFriendship) {
          if (this.value.friendInfo.netProfile.volunteerInterviewed) {
            this.value.friendInfo.netProfile.state = onHold.onHold ? CustomerState.OnHold : CustomerState.Active
          } else {
            this.value.friendInfo.netProfile.state = onHold.onHold ? CustomerState.OnHold : CustomerState.PendingApproval
          }
          this.value.friendInfo.netProfile.onHoldDeadline = onHold.onHoldDeadline
          this.value.friendInfo.netProfile.onHoldDetails = onHold.onHoldDetails
        } else {
          if (this.value.friendInfo.standardProfile.volunteerInterviewed) {
            this.value.friendInfo.standardProfile.state = onHold.onHold ? CustomerState.OnHold : CustomerState.Active
          } else {
            this.value.friendInfo.standardProfile.state = onHold.onHold ? CustomerState.OnHold : CustomerState.PendingApproval
          }
          this.value.friendInfo.standardProfile.onHoldDeadline = onHold.onHoldDeadline
          this.value.friendInfo.standardProfile.onHoldDetails = onHold.onHoldDetails
        }
        this.saveVolunteer()
      },
      saveVolunteer () {
        this.$emit('saveVolunteer')
      },
      toggleActivity: function () {
        this.$emit('toggleActivity')
      }
    }
  })
</script>

<style lang="scss">
  @import "../../assets/scss/_variables.scss";
  .volunteer-actions {
    .disable-volunteer .nodeletewarning {
      color: $brand-red;
      font-size: 13px;
    }
  }
</style>
