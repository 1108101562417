<template>
  <div class="container volunteerpreview">
    <div class="row header">
      <div class="col">
        <HomeButton
          :group-id="groupIdNumber"
        />
        <BackButton :back-url="backUrl" />
      </div>
    </div>

    <div class="row content">
      <div
        v-if="loaded && !group.netFriendship"
        class="col-lg-4 mb-3 mb-lg-0 volunteer-area"
      >
        <VolunteerCardStandard
          v-model="volunteer"
          :group="group"
          :show-actions="true"
          @refresh="refresh"
        />
      </div>
      <div
        v-if="loaded && group.netFriendship"
        class="col-lg-4 mb-3 mb-lg-0 volunteer-area"
      >
        <VolunteerCardNet
          v-model="volunteer"
          :group="group"
          :show-actions="true"
          @refresh="refresh"
        />
      </div>
      <div class="col-lg-8 customer-area">
        <div v-if="inactiveText" class="row">
          <div class="col-12 text-center">
            {{ inactiveText }}
          </div>
        </div>
        <div v-else>
          <div class="row">
            <div class="col-12">
              <h3 class="title">
                {{ $t('customerSearch.friendshipsTitle') }}
              </h3>
            </div>
          </div>
          <div class="volunteeractions">
            <volunteermatchstatus
              v-if="group && volunteer"
              :volunteer="volunteer"
              :group="group"
              :highlightedvustomerid="customerid"
              @addNewMatch="onAddNewMatch"
              @customerMatchSelected="onCustomerMatchSelected"
            />
          </div>
          <div class="row">
            <div class="col-12">
              <h3 class="title">
                {{ $t('customerSearch.poolsTitle') }}
              </h3>
            </div>
          </div>
          <div v-if="loaded" class="row">
            <div class="col-12">
              <VolunteerPoolsNet
                v-if="group.netFriendship"
                :volunteer="volunteer"
                :group="group"
                :is-active="isActive"
                @poolsupdated="onPoolsUpdated"
              />
              <VolunteerPoolsStandard
                v-else
                :volunteer="volunteer"
                :group="group"
                :is-active="isActive"
                @poolsupdated="onPoolsUpdated"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import Vue from 'vue'
  import { LocaleMessage } from 'vue-i18n'
  import _ from 'lodash'
  import BackButton from '../BackButton.vue'
  import HomeButton from '../HomeButton.vue'
  import VolunteerCardStandard from './VolunteerCardStandard.vue'
  import VolunteerCardNet from './VolunteerCardNet.vue'
  import volunteermatchstatus from './VolunteerMatchStatus.vue'
  import VolunteerPoolsStandard from './VolunteerPoolsStandard.vue'
  import VolunteerPoolsNet from './VolunteerPoolsNet.vue'
  import scroll from '@/mixins/scroll'
  import { UserProfileDto, CustomerState, FriendManagementDto, GetFriendProfileByFriendVolunteerIdCriteria, FriendshipNetDto, FriendshipStandardDto } from '@/types/index'

  // the data object
  export interface TemplateComponentData {
    volunteer: UserProfileDto | null,
    group: FriendManagementDto | null,
    loaded: boolean,
    loading: boolean
  }

  export default Vue.extend({
    name: 'VolunteerPreview',
    components: { VolunteerCardStandard, VolunteerCardNet, BackButton, HomeButton, volunteermatchstatus, VolunteerPoolsStandard, VolunteerPoolsNet },
    mixins: [scroll],
    props: {
      id: {
        type: String,
        default: ''
      }, // note this is the id of either the NET or STANDARD profile, not the Volunteer Id.
      groupid: {
        type: String,
        default: ''
      },
      customerid: {
        type: String,
        default: ''
      } // optional. Used to highlight a specific match on loading
    },
    data (): TemplateComponentData {
      return {
        volunteer: {} as UserProfileDto,
        group: {} as FriendManagementDto,
        loaded: false,
        loading: false
      }
    },
    computed: {
      backUrl (): string {
        return '/friend/search/' + this.groupIdNumber + '/volunteer'
      },
      volunteerState (): CustomerState {
        if (!this.volunteer) return CustomerState.PendingApproval
        var isNetGroup = this.group?.netFriendship
        return isNetGroup
          ? this.volunteer?.friendInfo.netProfile.state
          : this.volunteer?.friendInfo.standardProfile.state
      },
      groupIdNumber (): number {
        return Number(this.groupid)
      },
      isActive (): boolean {
        var isNetGroup = this.group?.netFriendship
        return (this.volunteer?.friendInfo && _.includes([CustomerState.Active, CustomerState.PendingFriend, CustomerState.ActiveFriendship], this.volunteerState)) || false
      },
      isInactive (): boolean {
        return this.volunteerState === CustomerState.NonActive
      },
      inactiveText (): string | LocaleMessage {
        if (this.volunteer?.friendInfo) {
          switch (this.volunteerState) {
          case 1:
            return this.$t('customerSearch.noMatchingNotInterviewed')
          case 5:
            return this.$t('customerSearch.noMatchingRemoved')
          case 6:
            return this.$t('customerSearch.noMatchingOnHold')
          }
        }

        return ''
      }
    },
    created (): void {
      var self = this

      var getGroupUrl = `${this.$store.state.apiBases.friendservice + this.$store.state.apiEndpoints.friendOrganizationById[0]}`.replace('{0}', this.groupid)

      this.loaded = false
      this.$http.get(getGroupUrl).then(resp => {
        // @ts-ignore
        this.group = resp.body
        this.loadVolunteer()
      })
    },
    methods: {
      loadVolunteer (): void {
        this.loaded = false
        var criteria: GetFriendProfileByFriendVolunteerIdCriteria = {
          friendManagementGroupId: Number(this.groupid),
          friendVolunteerId: Number(this.id),
          includeFriendships: true,
          includeFriendPools: true
        }
        var getVolunteerUrl = this.group?.netFriendship
          ? `${this.$store.state.apiBases.friendservice + this.$store.state.apiEndpoints.volunteergetnetprofilebyid[0]}`
          : `${this.$store.state.apiBases.friendservice + this.$store.state.apiEndpoints.volunteergetstandardprofilebyid[0]}`
        this.$http.post(getVolunteerUrl, criteria)
          .then(resp => {
            // @ts-ignore
            this.volunteer = resp.body
            this.loaded = true
          })
      },
      onAddNewMatch (): void {
        this.$router.push(`/friend/volunteermatch/${this.groupid}/${this.id}`)
      },
      onCustomerMatchSelected (friendship: FriendshipNetDto | FriendshipStandardDto): void {
        this.$router.push(`/friend/volunteermatch/${this.groupid}/${this.id}/${friendship.customer.id}`)
      },
      onPoolsUpdated (): void {
        this.loadVolunteer()
      },
      refresh (): void{
        window.location.reload()
      }
    }
  })
</script>

<style lang="scss">
  @import "../../assets/scss/_variables.scss";

  .volunteerpreview {
    .header {
      margin-top: 10px;
      margin-bottom: 15px;
      .nav-header {
        border-bottom: 0;
      }
    }

    .content {
      .volunteer-area {
        padding-top: 30px;
        background-color: $brand-white;
        border-left: solid 5px $volunteer-color-strong;
        .button-row {
          text-align: center;
          .load-indicator {
            width: 100%;
            margin-bottom: 15px;
            color: $brand-red;
          }
          button {
            margin-bottom: 15px;
            &.button-disabled {
              border-color: $brand-grey4;
              color: $brand-grey4;
              cursor: not-allowed;
              outline: none;
              &:hover {
                background-color:$brand-white;
              }
            }
          }
        }
      }

    }
  }
</style>
