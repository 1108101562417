<template>
  <div class="comp-login">
    <div class="fill-height fill-width swapped-elements">
      <div class="row bg-top">
        <div class="login-header col-12 text-center">
          <h1>{{ $t('login.loginPageHeader') }}</h1>
        </div>
        <div v-if="validationErrors.length" class="col-12 text-center mb-4">
          <sprvalidation :validation-errors="validationErrors" />
        </div>
        <div v-if="successMessages.length" class="col-12 text-center mb-4">
          <sprsuccess :messages="successMessages" />
        </div>
        <!--
          Intentionally not requiring this to be valid email address
          1) Might have special logins in future
          2) Reduced chance of people not being able to login due regression
          3) No real benefit of validating
      -->
        <form class="d-block col-12">
          <div class="row">
            <div class="col-12 text-center">
              <sprinput v-model="email"
                        v-validate="'required'"
                        :hint="$t('login.emailField')"
                        class="login-info d-inline-block"
                        :is-valid="!errors.has('emailField')"
                        data-vv-delay="15000"
                        name="emailField"
                        :disabled="pending" autocomplete="username email"
                        dark
              />
            </div>
          </div>
          <div class="row">
            <div class="col-12 text-center">
              <sprPassword v-model="password"
                           v-validate="'required'"
                           :hint="$t('login.passwordField')"
                           class="login-info d-inline-block"
                           :is-valid="!errors.has('passwordField')"
                           name="passwordField"
                           :disabled="pending" autocomplete="current-password"
                           dark
              />
            </div>
          </div>
          <div class="row">
            <div v-show="!pending && !success" class="col-12 text-center my-4">
              <SPRButton :size="2"
                         :title="$t('login.loginButton')"
                         :aria-label="$t('login.loginButton')"
                         :tabindex="0"
                         click-event="logIn"
                         class="spr-shadow d-inline-block"
                         type="submit"
                         @logIn="loginClicked"
              />
            </div>
            <div v-show="pending || success" class="col-12 my-4">
              <div class="text-center pending">
                <loading />
              </div>
            </div>
          </div>
        </form>
      </div>
      <div class="row bg-bottom">
        <div class="col-12 text-center my-5">
          <router-link to="/restore" class="login-link" tabindex="0">
            {{ $t('login.forgotPassword') }}
          </router-link>
        </div>
        <div class="col-12 text-center my-3">
          <p>
          </p><div class="pending_dark">
            {{ $t('login.noAccount') }}<br>
          </div>
          <router-link :to="{name: 'wizard'}" class="login-link" tabindex="0">
            {{ $t('login.register') }}
          </router-link>
          </p>
          <br>
          <a class="login-link" @click="onActiveDirectoryLogin">{{ $t('login.adLoginLink') }}</a>
        </div>
      </div>
    </div>

    <div class="common-elements">
      <div class="col-12 text-center mt-5 marginBottom25">
        <a href="https://www.punainenristi.fi/tietosuoja" target="_blank" class="login-link" tabindex="0">{{ $t('wizard.privacyPolicy') }}</a>
      </div>
    </div>
  </div>
</template>

<script>
  import SPRButton from './SPRButton.vue'
  import sprinput from './SPRInput.vue'
  import sprPassword from './SprPassword.vue'
  import sprvalidation from './SPRValidationError.vue'
  import sprsuccess from './SPRSuccess.vue'
  import loading from './SPRLoading.vue'

  export default {
    name: 'Login',
    pageName: 'login-page',
    components: { SPRButton, sprinput, sprPassword, sprvalidation, sprsuccess, loading },
    data () {
      return {
        email: '',
        password: '',
        username: '',
        validatedFields: ['emailField', 'passwordField'],
        failedLoginAttempt: 0,
        pending: false,
        cameFrom: null,
        success: false,
        successMessages: [],
        adFailure: false
      }
    },
    computed: {
      validationErrors: function () {
        var arr = []

        if (this.adFailure) {
          arr.push(['login.attempt', 'adfailed', ''])
        }

        if (!this.validatedFields) {
          return arr
        }

        var self = this
        this.validatedFields.forEach(function (fieldName) {
          var err = _.find(self.errors.items, function (item) {
            return item.field === fieldName
          })
          if (err != null) {
            arr.push(['login.' + err.field, String(err.rule), String(err.msg)])
          }
        })
        if (self.failedLoginAttempt === 1) {
          arr.push(['login.attempt', 'unauthorized', ''])
        }
        if (self.failedLoginAttempt === 2) {
          arr.push(['login.attempt', 'exception', ''])
        }
        return arr
      }
    },
    watch: {
      email: function (val) {
        this.failedLoginAttempt = false
      },
      password: function (val) {
        if (val !== '') {
          this.failedLoginAttempt = false
        }
      }
    },
    mounted () {
      if (this.$route.query) {
        if (this.$route.query.token) {
          this.handleToken(this.$route.query.token)
        } else if (this.$route.query.message) {
          this.cameFrom = this.$route.query.message.toLowerCase()
          switch (this.cameFrom) {
          case 'registersuccess':
            this.$set(this.successMessages, 0, this.$t('login.registrationSuccessful'))
            break
          case 'join':
            this.$set(this.successMessages, 0, this.$t('login.fromJoinHeader'))
            this.$set(this.successMessages, 1, this.$t('login.fromJoin'))
            break
          case 'invoice':
            this.$set(this.successMessages, 0, this.$t('login.fromInvoiceHeader'))
            this.$set(this.successMessages, 1, this.$t('login.fromInvoice'))
            break
          case 'aderr':
            this.adFailure = false
          }
        }

        this.email = sessionStorage.getItem('email')
      }
    },
    methods: {
      loginClicked: function (value, event) {
        var self = this
        this.cameFrom = null
        this.successMessages = []
        event.preventDefault()
        this.$validator.validateAll().then(function (valid) {
          if (valid) {
            self.pending = true
            self.login()
          } else {
            return false
          }
        })
      },
      handleToken: function (authToken) {
        this.pending = true
        sessionStorage.setItem('authtoken', authToken)
        this.$store.dispatch('setSessionJwtToken', authToken)
        const sessionRedirect = sessionStorage.getItem('postloginredirect')
        let callback = 'front'
        // Users can be redirected after login using either a postloginredirect session variable, or by the ?from={url} querystring parameter
        if (sessionRedirect) {
          callback = sessionRedirect
          sessionStorage.removeItem('postloginredirect')
        } else if (this.$route.query.from) {
          callback = this.$route.query.from
        }

        this.success = true
        window.location.href = this.$router.resolve({
          path: callback
        }).href
      },
      login: function () {
        this.$http.post(this.$store.state.apiBases.userservice + this.$store.state.apiEndpoints.login[0], { Email: this.email, Password: this.password }).then(response => {
          if (response.status === 200) {
            this.handleToken(response.body)
          } else {
            // rejected connections
            this.password = ''
            this.$validator.reset()
            this.failedLoginAttempt = 2
            this.pending = false
          }
        }, response => {
          // for connections that did not get proper response from the server
          if (response.body.description.toLowerCase() === 'expired') {
            this.$router.push({ path: `/resetexpired/${this.email}` })
          } else if (response.body.description.toLowerCase() === 'employeedenied') {
            this.showAlertInfo(this.$t('login.employeeDenied'))
          } else {
            this.password = ''
            this.$validator.reset()
            this.failedLoginAttempt = 1
            this.pending = false
          }
        })
        return false
      },
      onActiveDirectoryLogin () {
        if (this.$route.query.from) {
          sessionStorage.setItem('logincallback', this.$route.query.from)
        }
        window.location.href = this.$store.state.aduri
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import '~@/assets/scss/_variables.scss';

  .comp-login {
    background-color: $wizard-bg;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding: 0 10px;
    width: 100%;
    min-height: calc(100vh - 200px);
    padding-bottom: 25px;
    background: linear-gradient($brand-back 51%, $brand-white 49%) center center;
    background-repeat: no-repeat;
    background-size: contain cover;
    background-color: $brand-grey1;
    text-align: center;

    display: grid;
    display: -ms-grid;
    grid-template-columns: 1fr;
    -ms-grid-columns: 1fr;
    grid-template-rows: auto 40px 80px;
    -ms-grid-rows: auto 40px 80px;

    .bg-top {
      background: $brand-back center center;
    }
    .bg-bottom {
      background: $brand-white center center;
    }
    .fill-height {
      height: 100%;
    }
    .common-elements {
        grid-column: 1;
        grid-row: 3;
        -ms-grid-column: 1;
        -ms-grid-row: 3;
    }
    .swapped-elements  {
      grid-column: 1;
      grid-row: 1;
      -ms-grid-column: 1;
      -ms-grid-row: 1;
    }

    a {
      padding: 2px;
      font-family: "Signa-Light", Sans-Serif;
      color: $brand-grey5;
    }

    .link-container {
      text-align: center;
      margin: 40px auto 0 auto;
      color: $brand-white;
    }

    .login-link {
      text-decoration: none;
      font-family: "Signa-Light", Sans-Serif;
      cursor: pointer;
      color: $brand-grey11;

      &:hover {
        background: $brand-white;
        color: $brand-red;
      }
    }

    .login-header {
      margin-top: 75px;
      margin-bottom: 65px;
      overflow-wrap: break-word;
      h1 {
        color: $brand-white;
      }
      @media screen and (max-width: $media-sm-max) {
      h1 {
        font-size: 35px;
      }
      margin-bottom: 20px;
      }
      @media screen and (max-width: $media-xs-max) {
        h1 {
          font-size: 24px;
        }

        margin-bottom: 0;
      }
   }

    .login-info {
      width: 100% !important;
      max-width: 300px;
    }

    .btn {
      border: none;
    }

    .pending {
      color: $brand-white;
    }

    .pending_dark  {
      color: $brand-font;
      font-weight: 600;
    }

    .marginBottom10 {
      margin-bottom: 10px;
    }
    .marginBottom25 {
      margin-bottom: 35px;
    }
  }
</style>
