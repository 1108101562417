<template>
  <dropdowninput
    :title="title"
    :items="stringifiedItems"
    :required="required"
    :is-valid="isValid"
    :value="selected"
    :disabled="disabled"
    @input="$emit('input', Number($event))"
  />
</template>

<script lang="ts">
  import Vue from 'vue'
  import * as _ from 'lodash'
  import dropdowninput from './SPRSimpleDropdown.vue'

  export default Vue.extend({
    name: 'SprDropdownNumberKey',
    components: { dropdowninput },
    props: {
      title: {
        type: String,
        default: ''
      },
      value: {
        type: Number,
        default: -1
      },
      items: {
        type: Array,
        default: () => []
      },
      required: Boolean,
      isValid: Boolean,
      disabled: Boolean
    },
    computed: {
      selected (): string | null {
        return this.value ? this.value.toString() : null
      },
      isRequired (): boolean {
        if (this.required) {
          return this.required
        } else {
          return false
        }
      },
      stringifiedItems (): any {
        return _.map(this.items, function (item) {
          return { value: item.value.toString(), text: item.text }
        })
      }
    }
  })
</script>

<style lang="scss" scoped>
  @import "../assets/scss/_variables.scss";

  .spr-simple-dropdown {
    .spr-roundbox-1 {
      border:solid 2px $brand-grey3;
    }
    label {
      color:$brand-red;
      padding:0 20px;
    }
    select {
      margin-top:4px;
      border-radius:2rem;
      border-color:transparent;

      :active,
      :hover {
        outline-color:$brand-red;
        box-shadow: inset 20px 20px #f00;
      }

      > option:hover {
        box-shadow: 0 0 10px 100px #fff inset;
      }

      ::after {
        content: "asdasd";
        display: block;
        width:30px;
        border:solid 1px red;
      }
    }
  }

</style>
