
// GENERATED FILE BY DTOTYPESCRIPTGENERATOR, DO NOT EDIT!
// see DtoTypeScriptGenerator.cs
// SPR.Service.ContentService.Data.Dto Generated from CustomTypeScriptFormatter
/* eslint-disable @typescript-eslint/member-delimiter-style */
/* eslint-disable @typescript-eslint/no-empty-interface */
/* eslint-disable no-multi-spaces */
/* eslint-disable no-multiple-empty-lines */
/* eslint-disable no-tabs */

export interface EnumDto  {
	id: number;
	text: string;
}


// GENERATED FILE BY DTOTYPESCRIPTGENERATOR, DO NOT EDIT!
// see DtoTypeScriptGenerator.cs
// SPR.Shared.ResponseClasses Generated from CustomTypeScriptFormatter
/* eslint-disable @typescript-eslint/member-delimiter-style */
/* eslint-disable @typescript-eslint/no-empty-interface */
/* eslint-disable no-multi-spaces */
/* eslint-disable no-multiple-empty-lines */
/* eslint-disable no-tabs */

export interface FindResponse<T>  {
	results: T[];
	total: number;
}

export interface FindUserResponse extends FindResponse<UserProfileDto> {
}

export interface SearchResult<TDto>  {
	results: TDto[];
	total: number;
}


// GENERATED FILE BY DTOTYPESCRIPTGENERATOR, DO NOT EDIT!
// see DtoTypeScriptGenerator.cs
// SPR.Shared.Interfaces Generated from CustomTypeScriptFormatter
/* eslint-disable @typescript-eslint/member-delimiter-style */
/* eslint-disable @typescript-eslint/no-empty-interface */
/* eslint-disable no-multi-spaces */
/* eslint-disable no-multiple-empty-lines */
/* eslint-disable no-tabs */

export interface Paging  {
	skip: number;
	take: number;
}

export interface SearchTextCriteria  {
	searchString: string;
}

export interface Total  {
	total: number;
}


// GENERATED FILE BY DTOTYPESCRIPTGENERATOR, DO NOT EDIT!
// see DtoTypeScriptGenerator.cs
// SPR.Shared.Models.Dto.Identity Generated from CustomTypeScriptFormatter
/* eslint-disable @typescript-eslint/member-delimiter-style */
/* eslint-disable @typescript-eslint/no-empty-interface */
/* eslint-disable no-multi-spaces */
/* eslint-disable no-multiple-empty-lines */
/* eslint-disable no-tabs */

export interface AvailabilityTimeDto  {
	id: number;
	name: string;
}

export interface CrmUserProfileDto  {
	acceptMarketing: boolean;
	city: string;
	email: string;
	firstName: string;
	id: string;
	lastName: string;
	profileId: number;
	streetAddress: string;
	volunteerOnline?: boolean;
	zipCode: string;
}

export interface FriendRoleDataDto  {
	friendManagementGroupId: number;
	userId: string;
}

export interface GroupPublicProfileDto extends PublicProfileDto {
	areasOfInterest: number[];
	availabilityTimes: number[];
	isAdmin: boolean;
	nativeLanguage: string;
	personalSkills: number[];
	profileState: ProfileState;
	requestedByAdmin: boolean;
	spokenLanguages: string[];
	transportation: number[];
	travelTime: string;
}

export interface GroupPublicProfileLightDto extends PublicProfileLightDto {
	areasOfInterest: number[];
	availabilityTimes: number[];
	isAdmin: boolean;
	nativeLanguage: string;
	personalSkills: number[];
	profileState: ProfileState;
	requestedByAdmin: boolean;
	spokenLanguages: string[];
	transportation: number[];
	travelTime: string;
}

export interface LoginDataDto  {
	email: string;
	password: string;
}

export interface LoginUpdateDataDto extends LoginDataDto {
	currentPassword: string;
}

export interface ProfileAddressDto  {
	city: string;
	country: string;
	streetAddress: string;
	zipCode: string;
}

export interface ProfileCanDoActionsDto  {
	canBeAnonymized: boolean;
	canBeDeleted: boolean;
}

export interface ProfileDeleteRequestDto  {
	created: Date;
	firstName: string;
	id: number;
	lastName: string;
	membershipStatus: MembershipStatus;
	origin: ProfileDeleteRequestOrigin;
	profileId: number;
}

export interface ProfileHasDto  {
	hasBeenAnonymized: boolean;
	hasCreatedEvents: boolean;
	hasFriendPools: boolean;
	hasFriendPoolsNet: boolean;
	hasFriendPoolsStandard: boolean;
	hasFriendships: boolean;
	hasFriendshipsNet: boolean;
	hasFriendshipsStandard: boolean;
	hasFriendVolunteerGroups: boolean;
	hasFriendVolunteerNetProfile: boolean;
	hasFriendVolunteerProfile: boolean;
	hasFriendVolunteerStandardProfile: boolean;
	hasGroupMemberships: boolean;
	hasLightProfiles: boolean;
	hasMessages: boolean;
	hasNotifications: boolean;
	hasParticipatedInEvents: boolean;
	hasPendingGroupMemberships: boolean;
	hasUserLogin: boolean;
	hasUserRoles: boolean;
}

export interface ProfileIdentityDto  {
	address: ProfileAddressDto;
	email: string;
	exist: boolean;
	firstName: string;
	friendVolunteerId?: number;
	friendVolunteerProfileNetId?: number;
	friendVolunteerProfileStandardId?: number;
	lastName: string;
	profileId: number;
	sectionId: number;
	sectionName: string;
}

export interface ProfileUsageDto  {
	can: ProfileCanDoActionsDto;
	friendManagementGroups: TargetInfo[];
	friendPoolsNet: TargetInfo[];
	friendPoolsStandard: TargetInfo[];
	groups: TargetInfo[];
	groupsPending: TargetInfo[];
	has: ProfileHasDto;
	identity: ProfileIdentityDto;
	roles: string[];
}

export interface PublicProfileDto  {
	age?: Date;
	email: string;
	firstName: string;
	id: number;
	lastName: string;
	phone: string;
	portrait: string;
	sectionId: number;
	workingZipCode: string;
	zipCode: string;
}

export interface PublicProfileLightDto  {
	age?: Date;
	firstName: string;
	id: number;
	lastName: string;
	portrait: string;
	sectionId: number;
	workingZipCode: string;
	zipCode: string;
}

export interface RoleDataBatchDto  {
	roles: string[];
	userId: string;
}

export interface SimpleUserProfileDto  {
	age: Date;
	areasOfInterest: number[];
	contactedDate?: Date;
	created?: Date;
	email: string;
	firstName: string;
	id: string;
	isNonIdent: boolean;
	isPublic: boolean;
	lastName: string;
	personalSkills: number[];
	phoneNumber: string;
	portrait: string;
	profileId: number;
	profileState: ProfileState;
	sectionId: number;
	workingZipCode: string;
	zipCode: string;
}

export interface TargetInfo  {
	id: number;
	name: string;
}

export interface UserProfileDto extends CrmUserProfileDto {
	actionGroups: string[];
	age: Date;
	areasOfInterest: number[];
	availabilityTimes: number[];
	contactedDate?: Date;
	country: string;
	created?: Date;
	eventAdmins: EventAdminDto[];
	friendInfo: FriendVolunteerWithFriendshipsDto;
	involvedEventCategories: InvolvedEventCategories;
	isNonIdent: boolean;
	isPublic: boolean;
	lockoutEnabled: boolean;
	motto: string;
	nativeLanguage: string;
	otherLanguages: string;
	personalSkills: number[];
	phoneNumber: string;
	portrait: string;
	preferredContactMethods?: PreferredContactMethods;
	profileState: ProfileState;
	sectionId: number;
	spokenLanguages: string[];
	standardGroups: string[];
	story: string;
	trainedDate?: Date;
	transportation: number[];
	travelTime: string;
	useWorkingZipCode: boolean;
	workingZipCode: string;
}


// GENERATED FILE BY DTOTYPESCRIPTGENERATOR, DO NOT EDIT!
// see DtoTypeScriptGenerator.cs
// SPR.Shared.Enumerations Generated from CustomTypeScriptFormatter
/* eslint-disable @typescript-eslint/member-delimiter-style */
/* eslint-disable @typescript-eslint/no-empty-interface */
/* eslint-disable no-multi-spaces */
/* eslint-disable no-multiple-empty-lines */
/* eslint-disable no-tabs */

export enum AreaOfInterest {
	CollectDonations = 7,
	FirstAid = 9,
	FoodHelp = 12,
	GuideAndTeach = 4,
	HelpInCrisis = 10,
	ImmigrantSupport = 3,
	OrganiseActivities = 5,
	PromoteWellbeing = 6,
	WorkInATeam = 8,
	WorkInMulticulturalEnvironment = 11,
	WorkWithElderly = 2,
	WorkWithYoung = 1
}
export enum AvailabilityFrequence {
	LessThanOnceMonth = 4,
	NotDefined = 0,
	OnceFortnight = 2,
	OnceMonth = 3,
	OncePerWeek = 1
}
export enum AvailabilityTimesEnum {
	WeekdayDaytime = 2,
	WeekdayEvening = 3,
	WeekdayMorning = 1,
	WeekendDaytime = 5,
	WeekendEvening = 6,
	WeekendMorning = 4
}
export enum CustomerState {
	Active = 2,
	ActiveFriendship = 4,
	NonActive = 5,
	NotDefined = 0,
	OnHold = 6,
	PendingApproval = 1,
	PendingFriend = 3
}
export enum EventCategory {
	BloodDonation = 13,
	Collection = 7,
	FirstAidActivity = 2,
	FirstAidTraining = 1,
	FoodHelp = 17,
	Friendship = 14,
	Health = 11,
	HomeSupport = 10,
	Humanitary = 4,
	Immigrants = 9,
	International = 6,
	MentalSupport = 3,
	OrganizationActivity = 5,
	Other = 15,
	Preparation = 12,
	SafeHouse = 16,
	Youth = 8
}
export enum EventOccurance {
	Biweekly = 3,
	Monthly = 4,
	Once = 1,
	Weekly = 2
}
export enum EventOrganizerType {
	District = 100,
	Institution = 0,
	Section = 200
}
export enum FriendPoolStatus {
	Active = 1,
	Removed = 2
}
export enum FriendshipState {
	Active = 2,
	Canceled = 10,
	PendingApproval = 1,
	Terminated = 3
}
export enum Gender {
	Female = 2,
	Male = 1,
	Other = 4
}
export enum GroupOrganizerType {
	District = 100,
	Institution = 0,
	NotSet = -1,
	Section = 200
}
export enum GroupType {
	ActionGroup = 1,
	StandardGroup = 2,
	Unknown = 0
}
export enum IsOnlineEventFilterType {
	All = -1,
	OnlyOffline = 0,
	OnlyOnline = 1
}
export enum MembershipStatus {
	Active = 2,
	Deceased = 4,
	Terminated = 3,
	Transfer = 1,
	Undefined = 0
}
export enum MembershipType {
	AnnualMember = 1,
	HonararyMember = 4,
	LifeMember = 3,
	Undefined = 0,
	YouthMember = 2
}
export enum MessageStatus {
	Deleted = 4,
	Hidden = 3,
	MarkedAsRead = 2,
	New = 1
}
export enum MyProfileLevel {
	UserLevelA = 0,
	UserLevelB = 10,
	UserLevelC = 100
}
export enum PersonalSkills {
	CommunicationAndMarketing = 11,
	CookingAndBaking = 2,
	DigitalCompetence = 10,
	EventHosting = 3,
	EventOrganizaton = 8,
	FinanceAndAccounting = 12,
	HealthCareOrFirstAid = 13,
	MeetingPeople = 1,
	Organizational = 5,
	PhotographyAndVideo = 9,
	PublicPerformances = 4,
	Rescue = 14,
	TeamGuide = 6,
	TrainPeople = 7
}
export enum ProfileDeleteRequestOrigin {
	CRM = 2,
	Oma = 1
}
export enum ProfileState {
	Active = 1,
	ActiveIndependent = 2,
	ActiveNoNew = 3,
	Anonymized = 100,
	Error = 0,
	Inactive = 4
}
export enum ReportFrequency {
	Daily = 1,
	None = 0,
	Weekly = 7
}

// GENERATED FILE BY DTOTYPESCRIPTGENERATOR, DO NOT EDIT!
// see DtoTypeScriptGenerator.cs
// SPR.Shared.Models.Dto.Friend Generated from CustomTypeScriptFormatter
/* eslint-disable @typescript-eslint/member-delimiter-style */
/* eslint-disable @typescript-eslint/no-empty-interface */
/* eslint-disable no-multi-spaces */
/* eslint-disable no-multiple-empty-lines */
/* eslint-disable no-tabs */

export interface CoreFriendManagementGroupItemBaseDto  {
	friendManagementGroupId: number;
	id: number;
	isNet: boolean;
}

export interface CustomerFriendshipInfoDto  {
	birthDate: Date;
	city: string;
	firstName: string;
	gender: Gender;
	id: number;
	lastName: string;
	multipleVolunteers: boolean;
	shortDescription: string;
	state: CustomerState;
	zipCode: string;
}

export interface FindFriendshipCustomerDto  {
	customerId: number;
	firstName: string;
	friendshipState: FriendshipState;
	isNetFriend: boolean;
	lastName: string;
	state: CustomerState;
}

export interface FindFriendshipVolunteerDto  {
	firstName: string;
	friendshipState: FriendshipState;
	friendVolunteerId: number;
	isNetFriend: boolean;
	lastName: string;
	state: CustomerState;
}

export interface FindPoolDto  {
	id: number;
	name: string;
}

export interface FriendBrokerDto  {
	firstName: string;
	friendInfo: FriendVolunteerDto;
	fullName: string;
	isBroker: boolean;
	isGroupManager: boolean;
	lastName: string;
	portrait: string;
	profileId: number;
	userId: string;
}

export interface FriendBrokersRequestDto  {
	friendManagementGroupId: number;
	potentialBrokersOnly: boolean;
	search: string;
	skip: number;
	take: number;
}

export interface FriendCustomerFindDto  {
	birthdate: Date;
	customerNotForPrivateRelations: boolean;
	customerNotForSpr: boolean;
	email: string;
	firstName: string;
	friendManagementGroupId: number;
	friendPools: FindPoolDto[];
	friendships: FindFriendshipVolunteerDto[];
	fullname: string;
	gender: Gender;
	id: number;
	lastFriendshipEndDate: Date;
	lastName: string;
	multipleVolunteers: boolean;
	shortDescription: string;
	state: CustomerState;
	zipCode: string;
}

export interface FriendCustomerNetDto extends FriendCustomerProfileDto {
	customerTypes: NetCustomerTypes;
	econtactDiscord: string;
	econtactEmail: string;
	econtactFreetext: string;
	econtactInstagram: string;
	econtactKik: string;
	econtactSkype: string;
	econtactTelegram: string;
	email: string;
	friendPools: FriendPoolFindNetDto[];
	friendships: FriendshipNetDto[];
}

export interface FriendCustomerProfileDto  {
	availabilityFrequency: AvailabilityFrequence;
	birthdate: Date;
	city: string;
	contactCity: string;
	contactEmail: string;
	contactFirstName: string;
	contactLastName: string;
	contactPhonenumber: string;
	contactRelation: string;
	contactStreetAddress: string;
	contactZipCode: string;
	customerApproval: boolean;
	customerApprovedBy: string;
	customerInterviewed: boolean;
	customerInterviewedBy: string;
	customerInterviewedOnDate?: Date;
	customerLanguages: LanguageDto[];
	customerNotForPrivateRelations: boolean;
	customerNotForPrivateRelationsBy: string;
	customerNotForSpr: boolean;
	customerNotForSprBy: string;
	customerTypeOtherInfo: string;
	description: string;
	firstName: string;
	friendManagementGroupId: number;
	fullname: string;
	gender: Gender;
	gsmPhone: string;
	homePhone: string;
	id: number;
	interestOther: string;
	interests: Interests;
	lastFriendshipEndDate: Date;
	lastName: string;
	multipleVolunteers: boolean;
	nativeLanguage: string;
	onHoldComment: string;
	onHoldUntilDate?: Date;
	otherContact: string;
	otherLanguages: string;
	preferredAges: PreferredAges;
	preferredGenders: PreferredGenders;
	privateNotes: string;
	shortDescription: string;
	startedPending: Date;
	state: CustomerState;
	streetAddress: string;
	zipCode: string;
}

export interface FriendCustomerStandardDto extends FriendCustomerProfileDto {
	availabilityTimes: AvailabilityTimes;
	customerTypes: CustomerTypes;
	email: string;
	friendPools: FriendPoolFindStandardDto[];
	friendships: FriendshipStandardDto[];
}

export interface FriendManagementDto  {
	contactInfo: FriendManagementGroupContactInfoDto[];
	customerCount?: number;
	friendRequestFormAllowed: boolean;
	id: number;
	name: string;
	national: boolean;
	netFriendship: boolean;
	sections: string[];
	volunteerCount?: number;
	zipCodes: string[];
}

export interface FriendManagementGroupContactInfoDto  {
	address: string;
	email: string;
	id: number;
	onCallTimes: string;
	phone: string;
	title: string;
}

export interface FriendManagementLightDto  {
	contactInfo: FriendManagementGroupContactInfoDto[];
	friendRequestFormAllowed: boolean;
	id: number;
	name: string;
	national: boolean;
	netFriendship: boolean;
}

export interface FriendPoolBaseDto  {
	address: string;
	assemblyTimes: string;
	city: string;
	contactInfo: string;
	description: string;
	friendManagementGroupId: number;
	id: number;
	name: string;
	notes: string;
	postalCode: string;
	shortDescription: string;
	status: FriendPoolStatus;
}

export interface FriendPoolCustomerSummaryBaseDto  {
	firstName: string;
	lastName: string;
}

export interface FriendPoolCustomerSummaryNetDto extends FriendPoolCustomerSummaryBaseDto {
	customerProfileNetId: number;
}

export interface FriendPoolCustomerSummaryStandardDto extends FriendPoolCustomerSummaryBaseDto {
	customerProfileStandardId: number;
}

export interface FriendPoolFindBaseDto  {
	address: string;
	city: string;
	customerCount: number;
	groupId: number;
	id: number;
	name: string;
	postalCode: string;
	shortDescription: string;
	status: FriendPoolStatus;
	volunteerCount: number;
}

export interface FriendPoolFindNetDto extends FriendPoolFindBaseDto {
}

export interface FriendPoolFindStandardDto extends FriendPoolFindBaseDto {
}

export interface FriendPoolNetDto extends FriendPoolBaseDto {
	customers: FriendPoolCustomerSummaryNetDto[];
	volunteers: FriendPoolVolunteerSummaryNetDto[];
}

export interface FriendPoolStandardDto extends FriendPoolBaseDto {
	customers: FriendPoolCustomerSummaryStandardDto[];
	volunteers: FriendPoolVolunteerSummaryStandardDto[];
}

export interface FriendPoolVolunteerSummaryBaseDto  {
	firstName: string;
	lastName: string;
	volunteerId: number;
}

export interface FriendPoolVolunteerSummaryNetDto extends FriendPoolVolunteerSummaryBaseDto {
	friendVolunteerProfileNetId: number;
}

export interface FriendPoolVolunteerSummaryStandardDto extends FriendPoolVolunteerSummaryBaseDto {
	friendVolunteerProfileStandardId: number;
}

export interface FriendProfileDto  {
	age: Date;
	availabilityTimes: number[];
	city: string;
	country: string;
	email: string;
	firstName: string;
	friendInfo: FriendVolunteerDto;
	hasFriendInfo: boolean;
	id: number;
	involvedEventCategories: InvolvedEventCategories;
	isNonIdent: boolean;
	lastName: string;
	nativeLanguage: string;
	otherLanguages: string;
	phoneNumber: string;
	spokenLanguages: string[];
	streetAddress: string;
	trainedDate?: Date;
	transportation: number[];
	travelTime: string;
	zipCode: string;
}

export interface FriendshipNetDto extends DtoBaseWithId {
	customer: CustomerFriendshipInfoDto;
	customerProfileNetId: number;
	friendshipState: FriendshipState;
	friendVolunteerProfileNetId: number;
	started: Date;
	volunteer: VolunteerFriendshipInfoDto;
}

export interface FriendshipStandardDto extends DtoBaseWithId {
	customer: CustomerFriendshipInfoDto;
	customerProfileStandardId: number;
	friendshipState: FriendshipState;
	friendVolunteerProfileStandardId: number;
	started: Date;
	volunteer: VolunteerFriendshipInfoDto;
}

export interface FriendVolunteerBaseDto extends DtoBaseWithId {
	friendVolunteerNetId?: number;
	friendVolunteerStandardId?: number;
	gender: Gender;
	hasHealthCareTraining: boolean;
	healthCareTrainingFreetext: string;
	otherLanguages: string;
	phone: string;
	profileId: number;
}

export interface FriendVolunteerBaseExtensionDto<TStandard, TNet> extends FriendVolunteerBaseDto {
	hasNetProfile: boolean;
	hasStandardProfile: boolean;
	netProfile: TNet;
	standardProfile: TStandard;
}

export interface FriendVolunteerDto extends FriendVolunteerBaseExtensionDto<FriendVolunteerProfileStandardDto, FriendVolunteerProfileNetDto> {
}

export interface FriendVolunteerFindDto  {
	gender: Gender;
	hasNetProfile: boolean;
	hasStandardProfile: boolean;
	id: number;
	netProfile: FriendVolunteerFindProfileDto;
	standardProfile: FriendVolunteerFindProfileDto;
}

export interface FriendVolunteerFindProfileDto extends CoreFriendManagementGroupItemBaseDto {
	friendPools: FindPoolDto[];
	friendships: FindFriendshipCustomerDto[];
	lastFriendshipEndDate: Date;
	multipleCustomers: boolean;
	shortDescription: string;
	state: CustomerState;
}

export interface FriendVolunteerProfileCommonDto  {
	availabilityDetails: string;
	availabilityFrequence: AvailabilityFrequence;
	availabilityTimes: AvailabilityTimes;
	friendManagementGroupId: number;
	id: number;
	interests: Interests;
	lastFriendshipEndDate: Date;
	multipleCustomers: boolean;
	onHoldDeadline?: Date;
	onHoldDetails: string;
	otherInterests: string;
	preferredAges: PreferredAges;
	preferredGenders: PreferredGenders;
	shortDescription: string;
	startedPending: Date;
	state: CustomerState;
	volunteerInterviewed: boolean;
	volunteerInterviewedBy: string;
	volunteerInterviewedDate: string;
	volunteerInterviewedOnDate?: Date;
}

export interface FriendVolunteerProfileNetDto extends FriendVolunteerProfileCommonDto {
	customerTypes: NetCustomerTypes;
	econtactDiscord: string;
	econtactEmail: string;
	econtactFreetext: string;
	econtactInstagram: string;
	econtactKik: string;
	econtactSkype: string;
	econtactTelegram: string;
	friendPools: FriendPoolFindNetDto[];
	friendships: FriendshipNetDto[];
	friendshipTypes: NetFriendshipTypes;
	privateNotes: string;
}

export interface FriendVolunteerProfileNetWithFriendshipsDto extends FriendVolunteerProfileNetDto {
}

export interface FriendVolunteerProfileStandardDto extends FriendVolunteerProfileCommonDto {
	alternativeCity: string;
	alternativeStreet: string;
	alternativeZipCode: string;
	customerTypes: CustomerTypes;
	friendPools: FriendPoolFindStandardDto[];
	friendships: FriendshipStandardDto[];
	friendshipTypes: FriendshipTypes;
	hasAlternativeAddress: boolean;
	privateNotes: string;
}

export interface FriendVolunteerProfileStandardWithFriendshipsDto extends FriendVolunteerProfileStandardDto {
}

export interface FriendVolunteerWithFriendshipsDto extends FriendVolunteerBaseExtensionDto<FriendVolunteerProfileStandardWithFriendshipsDto, FriendVolunteerProfileNetWithFriendshipsDto> {
}

export interface LanguageDto  {
	displayName: string;
	id: number;
	isoCode: string;
}

export interface LightFriendProfileDto  {
	availabilityTimes: number[];
	firstName: string;
	id: number;
	involvedEventCategories: InvolvedEventCategories;
	isNonIdent: boolean;
	lastName: string;
	nativeLanguage: string;
	otherLanguages: string;
	spokenLanguages: string[];
	trainedDate?: Date;
	transportation: number[];
	travelTime: string;
}

export interface UserProfileFindDto  {
	acceptMarketing: boolean;
	age: Date;
	email: string;
	firstName: string;
	friendInfo: FriendVolunteerFindDto;
	isNonIdent: boolean;
	lastName: string;
	phoneNumber: string;
	portrait: string;
	profileId: number;
	sectionId: number;
	trainedDate?: Date;
	useWorkingZipCode: boolean;
	workingZipCode: string;
	zipCode: string;
}

export interface VolunteerFriendshipInfoDto  {
	birthDate: Date;
	firstName: string;
	friendVolunteerProfileNetId?: number;
	friendVolunteerProfileStandardId?: number;
	gender: Gender;
	isNetProfile: boolean;
	isNonIdent: boolean;
	lastName: string;
	multipleCustomers: boolean;
	portrait: string;
	profileId: number;
	sectionId: number;
	shortDescription: string;
	state: CustomerState;
	volunteerId: number;
	zipCode: string;
}


// GENERATED FILE BY DTOTYPESCRIPTGENERATOR, DO NOT EDIT!
// see DtoTypeScriptGenerator.cs
// SPR.Shared.Models.Dto Generated from CustomTypeScriptFormatter
/* eslint-disable @typescript-eslint/member-delimiter-style */
/* eslint-disable @typescript-eslint/no-empty-interface */
/* eslint-disable no-multi-spaces */
/* eslint-disable no-multiple-empty-lines */
/* eslint-disable no-tabs */

export interface DtoBase  {
}

export interface DtoBaseWithId extends DtoBase {
	id: number;
}

export interface PagingCriteriaDto  {
	skip: number;
	take: number;
}


// GENERATED FILE BY DTOTYPESCRIPTGENERATOR, DO NOT EDIT!
// see DtoTypeScriptGenerator.cs
// SPR.Shared.Enumerations.Flags Generated from CustomTypeScriptFormatter
/* eslint-disable @typescript-eslint/member-delimiter-style */
/* eslint-disable @typescript-eslint/no-empty-interface */
/* eslint-disable no-multi-spaces */
/* eslint-disable no-multiple-empty-lines */
/* eslint-disable no-tabs */

export enum AvailabilityTimes {
	NotDefined = 0,
	WeekdayDaytime = 2,
	WeekdayEvening = 4,
	WeekdayMorning = 1,
	WeekendDaytime = 16,
	WeekendEvening = 32,
	WeekendMorning = 8
}
export enum CustomerTypes {
	Carer = 4,
	Crippled = 64,
	FamilyWithKids = 1,
	Foreign = 32,
	Hospitalized = 128,
	MemoryIssues = 16,
	MentalHealth = 2,
	MentallyHandicapped = 8,
	NotDefined = 0
}
export enum EventTypes {
	Camp = 64,
	Club = 8,
	Collection = 4,
	CoursesAndTraining = 32,
	Event = 1,
	GroupMeeting = 128,
	Meeting = 16,
	Other = 512,
	Practice = 2,
	PublicEvent = 256,
	VolunteerTask = 1024
}
export enum FriendshipTypes {
	Acute = 32,
	FriendshipBroker = 4,
	GroupLeader = 2,
	Migri = 64,
	NotDefined = 0,
	Oneshot = 16,
	OneToOne = 1,
	Prison = 8
}
export enum Interests {
	Animals = 32,
	BeautyTreatments = 1024,
	ComputerGames = 256,
	Cooking = 64,
	Culture = 1,
	Exercise = 2,
	Handicrafts = 16,
	Music = 512,
	Nature = 8,
	NotDefined = 0,
	Outdoors = 16384,
	Reading = 4096,
	Shopping = 2048,
	SpectatorSports = 4,
	Travel = 128,
	Vehicles = 8192
}
export enum InvolvedEventCategories {
	BloodDonation = 4096,
	Collection = 64,
	FirstAidActivity = 2,
	FirstAidTraining = 1,
	FoodHelp = 65536,
	Friendship = 8192,
	Health = 1024,
	HomeSupport = 512,
	Humanitary = 8,
	Immigrants = 256,
	International = 32,
	MentalSupport = 4,
	NotDefined = 0,
	OrganizationActivity = 16,
	Other = 16384,
	Preparation = 2048,
	SafeHouse = 32768,
	Youth = 128
}
export enum Languages {
	English = 4,
	Finnish = 1,
	NotDefined = 0,
	Swedish = 2
}
export enum NetCustomerTypes {
	Carer = 4,
	Crippled = 16,
	FamilyWithKids = 1,
	Foreign = 8,
	MentalHealth = 2,
	NotDefined = 0
}
export enum NetFriendshipTypes {
	Acute = 32,
	FriendshipBroker = 4,
	GroupLeader = 2,
	NotDefined = 0,
	OneToOne = 1
}
export enum PreferredAges {
	AdultOlder = 8,
	AdultYounger = 4,
	Child = 1,
	Elder = 32,
	NotDefined = 0,
	Pensioner = 16,
	Young = 2
}
export enum PreferredContactMethods {
	Email = 2,
	NotDefined = 0,
	Phone = 1,
	TextMessage = 4
}
export enum PreferredGenders {
	Female = 2,
	Male = 1,
	NotDefined = 0,
	Other = 4
}

// GENERATED FILE BY DTOTYPESCRIPTGENERATOR, DO NOT EDIT!
// see DtoTypeScriptGenerator.cs
// SPR.Shared.Models.Dto.Friend.Interfaces Generated from CustomTypeScriptFormatter
/* eslint-disable @typescript-eslint/member-delimiter-style */
/* eslint-disable @typescript-eslint/no-empty-interface */
/* eslint-disable no-multi-spaces */
/* eslint-disable no-multiple-empty-lines */
/* eslint-disable no-tabs */


// GENERATED FILE BY DTOTYPESCRIPTGENERATOR, DO NOT EDIT!
// see DtoTypeScriptGenerator.cs
// SPR.Shared.Models.Dto.Event Generated from CustomTypeScriptFormatter
/* eslint-disable @typescript-eslint/member-delimiter-style */
/* eslint-disable @typescript-eslint/no-empty-interface */
/* eslint-disable no-multi-spaces */
/* eslint-disable no-multiple-empty-lines */
/* eslint-disable no-tabs */

export interface EventAdminDto extends DtoBaseWithId {
	delegatedByProfileId?: number;
	email: string;
	eventId?: number;
	isCreator: boolean;
}

export interface EventContactDto  {
	email: string;
	firstName: string;
	lastName: string;
	phone: string;
}

export interface EventDto extends DtoBaseWithId {
	category: InvolvedEventCategories;
	contact: EventContactDto;
	creatorId: number;
	endTime: EventTimeDto;
	eventAdmins: EventAdminDto[];
	eventOrganizer: EventOrganizerDto;
	eventRegistration: number;
	eventRoles: EventRoleDto[];
	image: string;
	imageThumb: string;
	isAllDay: boolean;
	isClosed: boolean;
	isFull: boolean;
	isOnline: boolean;
	isPublic: boolean;
	languageId: number;
	languages: Languages;
	lastOccurrence: Date;
	occurance: EventOccurance;
	oldImage: string;
	oldImageThumb: string;
	registrationDeadline?: Date;
	repeat: number;
	showEmail: boolean;
	showPhone: boolean;
	startTime: EventTimeDto;
	translations: EventTranslatableDto[];
	type: EventTypes;
	zipCode: string;
}

export interface EventOrganizerDto extends DtoBaseWithId {
	eventOrganizerType: EventOrganizerType;
	organizerId: number;
}

export interface EventParticipantDto extends DtoBaseWithId {
	cancellationToken: string;
	created?: Date;
	email: string;
	firstName: string;
	interestedInSimilarEvents: boolean;
	interestedInSpr: boolean;
	isLightProfile: boolean;
	lastName: string;
	lightProfileId?: number;
	moreInfo: string;
	notificationSent: boolean;
	participated: boolean;
	phone: string;
	profileId?: number;
	roleIds: number[];
	zipCode: string;
}

export interface EventRoleDto  {
	eventId: number;
	id: number;
	maximumParticipants: number;
	name: string;
	participants: EventParticipantDto[];
}

export interface EventTimeDto  {
	day: number;
	hour: number;
	minute: number;
	month: number;
	year: number;
}

export interface EventTranslatableDto  {
	city: string;
	description: string;
	duration: string;
	externalUrl: string;
	languageId: number;
	moreInfoQuestion: string;
	name: string;
	placeName: string;
	price: string;
	streetAddress: string;
}


// GENERATED FILE BY DTOTYPESCRIPTGENERATOR, DO NOT EDIT!
// see DtoTypeScriptGenerator.cs
// SPR.Shared.RequestClasses Generated from CustomTypeScriptFormatter
/* eslint-disable @typescript-eslint/member-delimiter-style */
/* eslint-disable @typescript-eslint/no-empty-interface */
/* eslint-disable no-multi-spaces */
/* eslint-disable no-multiple-empty-lines */
/* eslint-disable no-tabs */

export interface BaseSendMessageRequest  {
	canReply: boolean;
	content: string;
	customSender: string;
	receiverId: number;
	sendAsEmail: boolean;
	senderName: string;
	senderProfileId: number;
	subject: string;
}

export interface SendFriendshipVolunteerMessage extends BaseSendMessageRequest {
	friendManagementGroupId: number;
	volunteerId: number;
}

export interface SendMessageRequest extends BaseSendMessageRequest {
	districtId?: number;
	friendManagementGroupId?: number;
	groupedMessageId: string;
	groupId?: number;
	groupName: string;
	link: string;
	parentMessageId?: number;
	sectionId?: number;
	sectionName: string;
}

export interface SendMessageSelectedVolunteersRequest extends BaseSendMessageRequest {
	contactList: string[];
	districtId?: number;
	groupedMessageId: string;
	groupId?: number;
	groupName: string;
	link: string;
	sectionId?: number;
}

export interface SimpleFindCustomerRequest  {
	friendshipStates: number[];
	groupId: number;
	orderByNew: boolean;
	searchstring: string;
	skip: number;
	take: number;
}

export interface SimpleFindVolunteerRequest  {
	friendshipStates: number[];
	groupId: number;
	orderByNew: boolean;
	searchstring: string;
	skip: number;
	take: number;
}


// GENERATED FILE BY DTOTYPESCRIPTGENERATOR, DO NOT EDIT!
// see DtoTypeScriptGenerator.cs
// SPR.Shared.Models.Dto.Organization Generated from CustomTypeScriptFormatter
/* eslint-disable @typescript-eslint/member-delimiter-style */
/* eslint-disable @typescript-eslint/no-empty-interface */
/* eslint-disable no-multi-spaces */
/* eslint-disable no-multiple-empty-lines */
/* eslint-disable no-tabs */

export interface DistrictDto  {
	id: number;
	name: string;
	sections: SectionDto[];
}

export interface SectionContactDto  {
	email: string;
	sectionId: number;
}

export interface SectionDto extends SectionReportDto {
	actionGroups: GroupDto[];
	description: string;
	districtId: number;
	districtName: string;
	friendManagementGroupId?: number;
	friendManagementGroupIds: number[];
	language: SectionLanguage;
	name: string;
	standardGroups: GroupDto[];
	volunteers: UserProfileDto[];
	zipCodes: string[];
}

export interface SectionLightDto extends SectionReportDto {
	description: string;
	districtId: number;
	districtName: string;
	friendManagementGroupId?: number;
	language: SectionLanguage;
	name: string;
	zipCodes: string[];
}

export interface SectionReportDto  {
	contacts: SectionContactDto[];
	id: number;
	reportFrequency?: ReportFrequency;
}


// GENERATED FILE BY DTOTYPESCRIPTGENERATOR, DO NOT EDIT!
// see DtoTypeScriptGenerator.cs
// SPR.Shared.Helpers Generated from CustomTypeScriptFormatter
/* eslint-disable @typescript-eslint/member-delimiter-style */
/* eslint-disable @typescript-eslint/no-empty-interface */
/* eslint-disable no-multi-spaces */
/* eslint-disable no-multiple-empty-lines */
/* eslint-disable no-tabs */

export enum SectionLanguage {
	Bilingual = 2,
	Error = 3,
	Finnish = 0,
	Swedish = 1
}

// GENERATED FILE BY DTOTYPESCRIPTGENERATOR, DO NOT EDIT!
// see DtoTypeScriptGenerator.cs
// SPR.Shared.Models.Dto.Group Generated from CustomTypeScriptFormatter
/* eslint-disable @typescript-eslint/member-delimiter-style */
/* eslint-disable @typescript-eslint/no-empty-interface */
/* eslint-disable no-multi-spaces */
/* eslint-disable no-multiple-empty-lines */
/* eslint-disable no-tabs */

export interface GroupDetailDto  {
	category?: EventCategory;
	description: string;
	district: DistrictDto;
	externalForm: ExternalFormUpdateDto;
	groupCreator: PublicProfileDto;
	groupOrganizer: GroupOrganizerDto;
	groupType: GroupType;
	id: number;
	isActive: boolean;
	isAllLocations: boolean;
	languages: Languages;
	members: GroupPublicProfileDto[];
	name: string;
	pendingMembers: GroupPublicProfileDto[];
	section: SectionDto;
	totalMembers: number;
}

export interface GroupDetailLightDto  {
	category?: EventCategory;
	description: string;
	district: DistrictDto;
	externalForm: ExternalFormUpdateDto;
	groupCreator: PublicProfileDto;
	groupOrganizer: GroupOrganizerDto;
	groupType: GroupType;
	id: number;
	isActive: boolean;
	isAllLocations: boolean;
	languages: Languages;
	members: GroupPublicProfileLightDto[];
	name: string;
	pendingMembers: GroupPublicProfileLightDto[];
	section: SectionDto;
	totalMembers: number;
}

export interface GroupDto  {
	category?: EventCategory;
	debugInfo: string;
	description: string;
	districtId: number;
	externalForm: ExternalFormUpdateDto;
	groupCreatorId: number;
	groupManagerFirstName: string;
	groupManagerLastName: string;
	groupOrganizer: GroupOrganizerDto;
	groupType: GroupType;
	id: number;
	isActive: boolean;
	isAllLocations: boolean;
	languages: Languages;
	members: number[];
	name: string;
	pendingMembers: GroupMemberKeyValueDto[];
	sectionId: number;
	totalMembers: number;
}

export interface GroupMemberKeyValueDto  {
	key: number;
	value: boolean;
}

export interface GroupOrganizerDto extends DtoBaseWithId {
	groupOrganizerType: GroupOrganizerType;
	organizerId?: number;
}


// GENERATED FILE BY DTOTYPESCRIPTGENERATOR, DO NOT EDIT!
// see DtoTypeScriptGenerator.cs
// SPR.Shared.Models.Dto.ExternalForm Generated from CustomTypeScriptFormatter
/* eslint-disable @typescript-eslint/member-delimiter-style */
/* eslint-disable @typescript-eslint/no-empty-interface */
/* eslint-disable no-multi-spaces */
/* eslint-disable no-multiple-empty-lines */
/* eslint-disable no-tabs */

export interface ExternalFormDto  {
	link: string;
	name: string;
}

export interface ExternalFormUpdateDto extends DtoBaseWithId {
	link: string;
	name: string;
}


// GENERATED FILE BY DTOTYPESCRIPTGENERATOR, DO NOT EDIT!
// see DtoTypeScriptGenerator.cs
// SPR.Shared.Models.Dto.Organization.Criteria Generated from CustomTypeScriptFormatter
/* eslint-disable @typescript-eslint/member-delimiter-style */
/* eslint-disable @typescript-eslint/no-empty-interface */
/* eslint-disable no-multi-spaces */
/* eslint-disable no-multiple-empty-lines */
/* eslint-disable no-tabs */

export interface GetDistrictVolunteersCriteria extends GetVolunteersCriteria {
	districtId: number;
}

export interface GetSectionVolunteersCriteria extends GetVolunteersCriteria {
	sectionId: number;
}

export interface GetVolunteersCriteria extends PagingCriteriaDto {
	activityArea: string;
	areasOfInterest: AreaOfInterest[];
	availabilityTimes: AvailabilityTimesEnum[];
	contacted?: boolean;
	involvedEventCategories: InvolvedEventCategories;
	isAgeBelow30?: boolean;
	preferredContactMethods?: PreferredContactMethods;
	profileStates: ProfileState[];
	searchWord: string;
	skills: PersonalSkills[];
	spokenLanguages: string[];
	volunteerOnline?: boolean;
}


// GENERATED FILE BY DTOTYPESCRIPTGENERATOR, DO NOT EDIT!
// see DtoTypeScriptGenerator.cs
// SPR.Shared.Models.Dto.Message Generated from CustomTypeScriptFormatter
/* eslint-disable @typescript-eslint/member-delimiter-style */
/* eslint-disable @typescript-eslint/no-empty-interface */
/* eslint-disable no-multi-spaces */
/* eslint-disable no-multiple-empty-lines */
/* eslint-disable no-tabs */

export interface MessageDto  {
	content: string;
	deleted: boolean;
	groupId?: number;
	groupName: string;
	id: number;
	messageStatus: MessageStatus;
	messageType: number;
	parentMessageId?: number;
	sectionId?: number;
	sectionName: string;
	sender: string;
	senderId?: number;
	senderPortrait: string;
	sent?: Date;
	subject: string;
}


// GENERATED FILE BY DTOTYPESCRIPTGENERATOR, DO NOT EDIT!
// see DtoTypeScriptGenerator.cs
// SPR.Shared.Models.Dto.Identity.MyProfile Generated from CustomTypeScriptFormatter
/* eslint-disable @typescript-eslint/member-delimiter-style */
/* eslint-disable @typescript-eslint/no-empty-interface */
/* eslint-disable no-multi-spaces */
/* eslint-disable no-multiple-empty-lines */
/* eslint-disable no-tabs */

export interface MyProfileHasDto  {
	hasActionGroupAdmin: boolean;
	hasActiveFriendship: boolean;
	hasDeleteMyProfileRequest: boolean;
	hasFutureEventAsCreator: boolean;
	hasRole: boolean;
}

export interface MyProfileIsElementsDto  {
	isFutureEventCreator: boolean;
	isSoleActionGroupAdmin: boolean;
	isSoleFutureEventAdmin: boolean;
	isSoleOpenGroupAdmin: boolean;
}

export interface MyProfileSpecialDto  {
	has: MyProfileHasDto;
	is: MyProfileIsElementsDto;
	level: MyProfileLevel;
}

export interface MyProfileUsageDto extends ProfileUsageDto {
	special: MyProfileSpecialDto;
}


// GENERATED FILE BY DTOTYPESCRIPTGENERATOR, DO NOT EDIT!
// see DtoTypeScriptGenerator.cs
// SPR.Shared.Models.Dto.Group.Criteria Generated from CustomTypeScriptFormatter
/* eslint-disable @typescript-eslint/member-delimiter-style */
/* eslint-disable @typescript-eslint/no-empty-interface */
/* eslint-disable no-multi-spaces */
/* eslint-disable no-multiple-empty-lines */
/* eslint-disable no-tabs */

export interface GetGroupsCriteria extends PagingCriteriaDto {
	categories: EventCategory[];
	districtId?: number;
	groupId?: number;
	groupOrganizers: GroupOrganizerCriteria[];
	groupTypes: GroupType[];
	isActive?: boolean;
	isAllLocations?: boolean;
	languages: Languages[];
	profileId?: number;
	searchWord: string;
	searchWordSeparator: string;
	sectionId?: number;
}

export interface GroupOrganizerCriteria  {
	groupOrganizerType: GroupOrganizerType;
	hashCode: string;
	organizerId: number;
}


// GENERATED FILE BY DTOTYPESCRIPTGENERATOR, DO NOT EDIT!
// see DtoTypeScriptGenerator.cs
// SPR.Shared.Models.Dto.Google Generated from CustomTypeScriptFormatter
/* eslint-disable @typescript-eslint/member-delimiter-style */
/* eslint-disable @typescript-eslint/no-empty-interface */
/* eslint-disable no-multi-spaces */
/* eslint-disable no-multiple-empty-lines */
/* eslint-disable no-tabs */

export interface DistanceResult  {
	distance: number;
	distanceText: string;
	duration: number;
	durationMinutes: number;
	durationText: string;
	isRealDistance: boolean;
}


// GENERATED FILE BY DTOTYPESCRIPTGENERATOR, DO NOT EDIT!
// see DtoTypeScriptGenerator.cs
// SPR.Shared.Models.Dto.Friend.SearchResults Generated from CustomTypeScriptFormatter
/* eslint-disable @typescript-eslint/member-delimiter-style */
/* eslint-disable @typescript-eslint/no-empty-interface */
/* eslint-disable no-multi-spaces */
/* eslint-disable no-multiple-empty-lines */
/* eslint-disable no-tabs */

export interface FindFriendManagementGroupCustomersResult extends FindFriendManagementGroupItemsResult<FriendCustomerFindDto> {
}

export interface FindFriendManagementGroupItemsResult<T> extends FriendshipQueryCriteria {
	items: T[];
	total: number;
}

export interface FindFriendManagementGroupVolunteersResult extends FindFriendManagementGroupItemsResult<UserProfileFindDto> {
}

export interface MatchScore  {
	isInappropriateMatch: boolean;
	score: number;
	showOnList: boolean;
}


// GENERATED FILE BY DTOTYPESCRIPTGENERATOR, DO NOT EDIT!
// see DtoTypeScriptGenerator.cs
// SPR.Shared.Models.Dto.Friend.Criteria Generated from CustomTypeScriptFormatter
/* eslint-disable @typescript-eslint/member-delimiter-style */
/* eslint-disable @typescript-eslint/no-empty-interface */
/* eslint-disable no-multi-spaces */
/* eslint-disable no-multiple-empty-lines */
/* eslint-disable no-tabs */

export interface FindFriendManagementGroupContentCriteria extends FriendshipQueryCriteria {
	ages: PreferredAges;
	customerStates: CustomerState[];
	includeFriendPools: boolean;
	includeFriendships: boolean;
	languages: string[];
	natives: string[];
	orderByNew: boolean;
	searchString: string;
	skip: number;
	take: number;
}

export interface FindNetFriendManagementGroupContentCriteria extends FindFriendManagementGroupContentCriteria {
	customerTypes: NetCustomerTypes;
	friendshipTypes: NetFriendshipTypes;
}

export interface FindStandardFriendManagementGroupContentCriteria extends FindFriendManagementGroupContentCriteria {
	customerTypes: CustomerTypes;
	friendshipTypes: FriendshipTypes;
}

export interface FriendManagementCommand  {
	groupId: number;
}

export interface FriendshipNetCommandCriteria extends FriendManagementCommand {
	customerProfileNetId: number;
	volunteerNetProfileId: number;
}

export interface FriendshipQueryCriteria extends FriendManagementCommand {
}

export interface FriendshipStandardCommandCriteria extends FriendManagementCommand {
	customerProfileStandardId: number;
	volunteerStandardProfileId: number;
}

export interface GetFriendProfileByFriendVolunteerIdCriteria  {
	friendManagementGroupId: number;
	friendVolunteerId: number;
	includeFriendPools: boolean;
	includeFriendships: boolean;
}


// GENERATED FILE BY DTOTYPESCRIPTGENERATOR, DO NOT EDIT!
// see DtoTypeScriptGenerator.cs
// SPR.Shared.Models.Dto.Friend.SearchResults.Match Generated from CustomTypeScriptFormatter
/* eslint-disable @typescript-eslint/member-delimiter-style */
/* eslint-disable @typescript-eslint/no-empty-interface */
/* eslint-disable no-multi-spaces */
/* eslint-disable no-multiple-empty-lines */
/* eslint-disable no-tabs */

export interface FindMatchNetCustomerResult extends FindFriendManagementGroupItemsResult<MatchItemNetVolunteerDto> {
}

export interface FindMatchNetVolunteerResult extends FindFriendManagementGroupItemsResult<MatchItemNetCustomerDto> {
}

export interface FindMatchStandardCustomerResult extends FindFriendManagementGroupItemsResult<MatchItemStandardVolunteerDto> {
}

export interface FindMatchStandardVolunteerResult extends FindFriendManagementGroupItemsResult<MatchItemStandardCustomerDto> {
}

export interface MatchDetailCustomerDto extends MatchDetailDto {
	multipleVolunteers: boolean;
}

export interface MatchDetailDto  {
	birthDate: Date;
	city: string;
	contactImage: string;
	firstName: string;
	gender: Gender;
	lastName: string;
	nativeLanguage: string;
	shortDescription: string;
	spokenLanguages: string[];
	state: CustomerState;
	zipCode: string;
}

export interface MatchDetailNetCustomerDto extends MatchDetailCustomerDto {
	customerProfileNetId: number;
	customerTypes: NetCustomerTypes;
}

export interface MatchDetailNetVolunteerDto extends MatchDetailVolunteerDto {
	customerTypes: NetCustomerTypes;
	friendVolunteerProfileNetId: number;
}

export interface MatchDetailStandardCustomerDto extends MatchDetailCustomerDto {
	customerProfileStandardId: number;
	customerTypes: CustomerTypes;
}

export interface MatchDetailStandardVolunteerDto extends MatchDetailVolunteerDto {
	customerTypes: CustomerTypes;
	friendVolunteerProfileStandardId: number;
}

export interface MatchDetailVolunteerDto extends MatchDetailDto {
	friendVolunteerId: number;
	hasNetProfile: boolean;
	hasStandardProfile: boolean;
	isNonIdent: boolean;
	multipleCustomers: boolean;
	profileId: number;
	sectionId: number;
	trainedDate?: Date;
}

export interface MatchItemBaseDto<T> extends MatchScore {
	match: T;
}

export interface MatchItemNetCustomerDto extends MatchItemBaseDto<MatchDetailNetCustomerDto> {
}

export interface MatchItemNetVolunteerDto extends MatchItemBaseDto<MatchDetailNetVolunteerDto> {
}

export interface MatchItemStandardCustomerDto extends MatchItemBaseDto<MatchDetailStandardCustomerDto> {
}

export interface MatchItemStandardVolunteerDto extends MatchItemBaseDto<MatchDetailStandardVolunteerDto> {
}


// GENERATED FILE BY DTOTYPESCRIPTGENERATOR, DO NOT EDIT!
// see DtoTypeScriptGenerator.cs
// SPR.Shared.Models.Dto.Event.Search Generated from CustomTypeScriptFormatter
/* eslint-disable @typescript-eslint/member-delimiter-style */
/* eslint-disable @typescript-eslint/no-empty-interface */
/* eslint-disable no-multi-spaces */
/* eslint-disable no-multiple-empty-lines */
/* eslint-disable no-tabs */

export interface AdvancedEventSearchCriteria extends PagingCriteriaDto {
	endDate?: Date;
	eventCategory?: InvolvedEventCategories;
	eventOrganizer: EventOrganizerCriteria;
	eventType?: EventTypes;
	isFullLocale: boolean;
	isOnline: IsOnlineEventFilterType;
	isPublic?: boolean;
	languages: Languages[];
	locale: string;
	searchWord: string;
	searchWordDelimiters: string[];
	startDate?: Date;
}

export interface EventOrganizerCriteria  {
	eventOrganizerType: EventOrganizerType;
	isValidCriteria: boolean;
	organizerId: number;
}


// GENERATED FILE BY DTOTYPESCRIPTGENERATOR, DO NOT EDIT!
// see DtoTypeScriptGenerator.cs
// SPR.Shared.Models.Dto.Crm Generated from CustomTypeScriptFormatter
/* eslint-disable @typescript-eslint/member-delimiter-style */
/* eslint-disable @typescript-eslint/no-empty-interface */
/* eslint-disable no-multi-spaces */
/* eslint-disable no-multiple-empty-lines */
/* eslint-disable no-tabs */

export interface CrmDataDto  {
	city: string;
	country: string;
	crmcustomerid: string;
	decorations: CrmDecorationDto[];
	email: string;
	firstname: string;
	language: string;
	lastname: string;
	lastPaymentDate?: Date;
	membershipstartyear: string;
	membershipstatus: MembershipStatus;
	membershiptype: MembershipType;
	phone: string;
	positionsoftrust: CrmTrustDto[];
	postalcode: string;
	postoffice: string;
	scenarioName: string;
	scenarioNumber: number;
	section: string;
	streetaddress: string;
	vatiprofileid: string;
}

export interface CrmDecorationDto  {
	decoration: string;
	granteddate: string;
	organization: string;
	status: string;
}

export interface CrmTrustDto  {
	enddate: string;
	organization: string;
	organizationlevel: string;
	position: string;
	startdate: string;
	status: string;
}

