<template>
  <div class="col">
    <div class="container">
      <div class="profilepage">
        <div class="row">
          <div class="col col-12 col-sm-3 col-md-2 progress-container text-center text-md-left">
            <!--<span class="caption vertical-center">{{$t('profile.completion')}}</span>-->
            <div class="d-inline-block">
              <div class="image-editor-container">
                <sprportrait :completion="90"
                             class="progress-size"
                             :style="portClass"
                             :show-numeric="false"
                />
                <router-link tabindex="0" to="/profile/image">
                  <span class="fa-stack fa-lg image-editor-link brand-red">
                    <font-awesome-layers class="fa-lg">
                      <font-awesome-icon icon="circle" />
                      <font-awesome-icon icon="pencil-alt" transform="shrink-6" style="color: white;" />
                    </font-awesome-layers>
                  </span>
                </router-link>
              </div>
            </div>
          </div>
          <div class="col col-12 col-sm-9 col-md-10">
            <span role="heading" aria-level="1" class="h1 brand-red text-center text-md-left">{{ labels }}</span>
          </div>
        </div>
        <!-- Motto and story editors -->
        <div class="row">
          <div class="col col-12 col-md-4">
            <sprEditorText
              :value="profile.motto"
              class="motto-editor"
              :title="$t('profile.motto')"
              save-event="on-save"
              :is-valid="!errors.has('motto')"
              name="motto"
              @on-save="updateMotto"
            />
          </div>
          <div class="col col-12 col-md-8">
            <sprEditorText
              :value="profile.story"
              class="story-editor"
              :title="$t('profile.story')"
              save-event="on-save"
              :is-valid="!errors.has('story')"
              name="story" @on-save="updateStory"
            />
          </div>
        </div>

        <!-- profile status -->
        <div class="row">
          <div class="col col-12">
            <span role="heading" aria-level="3" class="h4 brand-black">
              {{ $t('profile.headerState') }}
            </span>
          </div>
        </div>
        <div class="row">
          <div class="col col-12">
            <div class="profile-state">
              <SPRRadioList
                :value="profile.profileState"
                :options="profileStatusOptions"
                @input="updateProfileState"
              />
            </div>
          </div>
        </div>

        <!-- PreferredContactMethod -->
        <div class="row">
          <div class="col-12 text-left">
            <span class="subheader">{{ $t('wizard.preferredContactMethod') }}</span>
          </div>
          <div class="col-12">
            <checkboxlist :value="profile.preferredContactMethods"
                          :list="preferredContactMethodOptions"
                          :rows="1"
                          @input="updatePreferredContactMethods"
            />
          </div>
        </div>

        <!-- PERSONAL INFO SECTION (Omat tiedot) -->
        <div class="row withbutton">
          <div class="col col-10">
            <span role="heading" aria-level="3" class="h4 brand-black">
              {{ $t('profile.headerInfo') }}
            </span>
          </div>
          <div class="col-2 save">
            <SPRButton type="button"
                       :size="1"
                       :title="$t('volunteerForm.save')"
                       click-event="onSave"
                       @onSave="updateProfileOnBackend"
            />
          </div>
        </div>
        <div class="row profile-feature">
          <div class="col-12">
            <div>
              <div class="row">
                <div class="col col-12 col-md-6">
                  <!-- Profile.InvolvedEventCategories -->
                  <div class="info-label">
                    <span class="cap-label">{{ $t('wizard.alreadyInvolved') }}</span>
                    <router-link tabindex="0" to="/profile/involvedEventCategories">
                      <font-awesome-icon icon="pencil-alt" class="pencil-edit" />
                    </router-link>
                  </div>
                  <div class="info-panel-body">
                    <span v-if="!hasInvolvedEventCategories">{{ $t('profile.noInvolvedEventCategories') }}</span>
                    <span v-for="i in activeProfileInvolvedEventCategories" :key="i" class="selected-value spr-roundbox-1 m-1">{{ $t('involvedEventCategories.' + getInvolvedEventCategories(i)) }}</span>
                  </div>
                </div>
                <div class="col col-12 col-md-6">
                  <div class="info-label">
                    <span class="cap-label">{{ $t('profile.sectionInterests') }}</span>
                    <router-link tabindex="0" to="/profile/interest">
                      <font-awesome-icon icon="pencil-alt" class="pencil-edit" />
                    </router-link>
                  </div>
                  <div class="info-panel-body">
                    <span v-for="i in profile.areasOfInterest" :key="i" class="selected-value spr-roundbox-1 m-1">{{ $t('interest.' + getAreaOfInterestText(i)) }}</span>
                    <span v-if="!profile.areasOfInterest.length">{{ $t('profile.noInterests') }}</span>
                  </div>
                </div>
                <div class="col col-12 col-md-6">
                  <div class="info-label">
                    <span class="cap-label">{{ $t('profile.sectionSkills') }}</span>
                    <router-link tabindex="0" to="/profile/skills">
                      <font-awesome-icon icon="pencil-alt" class="pencil-edit" />
                    </router-link>
                  </div>
                  <div class="info-panel-body">
                    <span v-for="i in profile.personalSkills" :key="i" class="selected-value spr-roundbox-1 m-1">{{ $t('skills.' + getPersonalSkillsText(i)) }}</span>
                    <span v-if="!profile.personalSkills.length">{{ $t('profile.noSkills') }}</span>
                  </div>
                </div>
                <div class="col col-12 col-md-6">
                  <div class="info-label">
                    <span class="cap-label">{{ $t('profile.sectionLanguages') }}</span>
                    <router-link tabindex="0" to="/profile/languages">
                      <font-awesome-icon icon="pencil-alt" class="pencil-edit" />
                    </router-link>
                  </div>
                  <div class="info-panel-body">
                    <span v-for="i in profile.spokenLanguages" :key="i" class="selected-value spr-roundbox-1 m-1">{{ normalizeTranslation(i) }}</span>
                    <span v-if="!profile.spokenLanguages.length">{{ $t('profile.noLangaugeSkills') }}</span>
                  </div>
                </div>
                <div class="col col-12 col-md-6">
                  <div class="info-label">
                    <span class="cap-label">{{ $t('profile.sectionLocation') }}</span>
                    <router-link tabindex="0" to="/profile/location">
                      <font-awesome-icon icon="pencil-alt" class="pencil-edit" />
                    </router-link>
                  </div>
                  <div class="">
                    <dl>
                      <dt>{{ $t('profile.section') }}</dt>
                      <dd>{{ userSection }}</dd>
                      <dt>{{ $t('profile.workZip') }}</dt>
                      <dd>{{ $store.state.profile.workingZipCode }}</dd>
                      <dt>{{ $t('adminUserEditor.volunteerOnline') }}</dt>
                      <dd>{{ formatBoolean($store.state.profile.volunteerOnline) }}</dd>
                      <dt>{{ $t('profile.availabilityTimes') }}</dt>
                      <dd>
                        <span v-for="(e, i) in $store.state.profile.availabilityTimes" :key="i">
                          {{ $t('availabilityTimes.' + databaseIdToText(e, $store.state.databaseAvailabilityTimes)) }}<span v-if="i + 1 < $store.state.profile.availabilityTimes.length">, </span>
                        </span>
                        <span v-if="!$store.state.profile.availabilityTimes.length" />
                      </dd>
                      <dt>{{ $t('profile.travelOptions') }}</dt>
                      <dd>
                        <span v-for="(e, i) in $store.state.profile.transportation" :key="i">
                          {{ $t('transportation.' + idToText(e, $store.state.transportation)) }}<span v-if="i + 1 < $store.state.profile.transportation.length">, </span>
                        </span>
                        <span v-if="!$store.state.profile.transportation.length" />
                      </dd>
                      <dt>{{ $t('profile.travelTime') }}</dt>
                      <dd>
                        <span>{{ $store.state.profile.travelTime }} min</span>
                      </dd>
                    </dl>
                  </div>
                </div>
                <div class="col col-12 col-md-6">
                  <div class="info-label">
                    <span class="cap-label">{{ $t('profile.sectionPersonalInfo') }}</span>
                    <router-link tabindex="0" to="/profile/personal">
                      <font-awesome-icon icon="pencil-alt" class="pencil-edit" />
                    </router-link>
                  </div>
                  <div class="info-panel-body">
                    <dl>
                      <dt>{{ $t('profile.phoneNumber') }}</dt>
                      <dd>{{ $store.state.profile.phoneNumber }}</dd>
                      <dt>{{ $t('profile.address') }}</dt>
                      <dd>{{ $store.state.profile.streetAddress }}, {{ $store.state.profile.zipCode }} {{ $store.state.profile.city }}</dd>
                      <dt>{{ $t('profile.dateOfBirth') }}</dt>
                      <dd>{{ formatDate($store.state.profile.age) }}</dd>
                      <dt>{{ $t('profile.nativeLanguage') }}</dt>
                      <dd>{{ normalizeTranslation($store.state.profile.nativeLanguage) }}</dd>
                    </dl>
                  </div>
                </div>
                <div class="col-12">
                  <div class="info-label">
                    <span class="cap-label">{{ $t('profile.sectionMembership') }}</span>
                  </div>
                  <div class="info-panel-body">
                    <dl v-if="member">
                      <MembershipData :member="member"></MembershipData>
                    </dl>
                    <dl>
                      <dt>{{ $t('profile.rcCourses') }}</dt>
                      {{ trainText }}
                    </dl>
                  </div>
                </div>
                <div class="col-12">
                  <div class="info-label">
                    <span class="cap-label">{{ $t('profile.editUsernameAndPassword') }}</span>
                    <router-link tabindex="0" to="/profile/login">
                      <font-awesome-icon icon="pencil-alt" class="pencil-edit" />
                    </router-link>
                  </div>
                  <div class="info-panel-body">
                    <dl>
                      <dt>{{ $t('profile.email') }}</dt>
                      <dd>{{ $store.state.profile.email }}</dd>
                      <dt>{{ $t('profile.password') }}</dt>
                      <dd>******</dd>
                    </dl>
                  </div>
                </div>
                <div class="col-12">
                  <div class="info-label">
                    <span class="cap-label">{{ $t('profile.editEventAdmins') }}</span>
                    <router-link tabindex="0" to="/profile/eventadmins">
                      <font-awesome-icon icon="pencil-alt" class="pencil-edit" />
                    </router-link>
                  </div>
                  <div class="info-panel-body">
                    <ul v-if="$store.state.profile.eventAdmins" class="eventadmins">
                      <li v-for="admin in $store.state.profile.eventAdmins" :key="admin.email">
                        {{ admin.email }}
                      </li>
                    </ul>
                    <div v-else>
                      {{ $t('profile.noEventAdminsCreated') }}
                    </div>
                  </div>
                </div>
                <!-- Initiate UserProfile Anonomization or Deletion -->
                <div class="col-12">
                  <deleteUserProfile />
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- VOLUNTEER INFO -->
        <div v-if="showFriendshipSection" class="profile-feature">
          <div class="row">
            <div class="col-12">
              <h4>{{ $t('profile.volunteerInfo') }}</h4>
            </div>
          </div>
          <!-- Profile friend profile section -->
          <div v-if="$store.state.profile.friendInfo" class="row">
            <div class="col-12">
              <volunteerprofiledetails :volunteer="$store.state.profile.friendInfo" />
            </div>
          </div>
          <!-- Prompt to create friend profile -->
          <div v-else class="row">
            <div class="col-12">
              <div class="profile-feature text-center">
                <router-link :to="'/friend/volunteer/add'">
                  {{ $t('profile.createFriendProfilePrompt') }}
                </router-link>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 save">
          <SPRButton type="button"
                     :size="1"
                     :title="$t('volunteerForm.save')"
                     click-event="onSave"
                     @onSave="updateProfileOnBackend"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import Vue from 'vue'
  import * as _ from 'lodash'
  import sprportrait from './SPRPortrait.vue'
  import sprEditorText from './SPRProfileEditor.vue'
  import checkboxlist from './SPRSimpleCheckboxBitmaskList.vue'
  import volunteerprofiledetails from './VolunteerProfileDetails.vue'
  import deleteUserProfile from './profile-editor/DeleteUserProfile.vue'
  import cache from '../mixins/cache.js'
  import moment from 'moment'
  import enumhelper from '../mixins/enums.js'
  import inpututils from '../mixins/inpututils'
  import anonPortrait from '../assets/img/anon-portrait.png'
  import MembershipData from './MembershipData.vue'
  import { CrmDataDto, InvolvedEventCategories, PreferredContactMethods, UserProfileDto } from '@/types'
  import { SprCheckboxValue, SprRadioPillOption, YesNoWarning } from '@/types/custom'
  import { TranslateResult } from 'vue-i18n'
  import SPRRadioList from './SPRRadioList.vue'
  import stringhelper from '@/helpers/stringhelper'
  import SPRButton from './SPRButton.vue'

  // the data object
  export interface TemplateComponentData {
    member: undefined | CrmDataDto,
    train: undefined | Array<string>,
    profile: UserProfileDto,
    statusOptions: Array<string>,
    hasChanges: boolean,
    anonPortrait: any
  }

  export default Vue.extend({
    name: 'Profile',
    components: { sprportrait, sprEditorText, volunteerprofiledetails, deleteUserProfile, checkboxlist, SPRRadioList, SPRButton, MembershipData },
    mixins: [cache, enumhelper, inpututils],
    data (): TemplateComponentData {
      return {
        member: undefined,
        train: undefined,
        profile: { ...this.$store.state.profile } as UserProfileDto,
        statusOptions: ['activeOpen', 'activeNoContact', 'activeNoNew', 'inactive'],
        anonPortrait: anonPortrait,
        hasChanges: false
      }
    },
    computed: {
      option1Value (): YesNoWarning {
        return YesNoWarning.Warning
      },
      hasInvolvedEventCategories (): boolean {
        return this.profile.involvedEventCategories !== 0
      },
      profileStatusOptions (): Array<SprRadioPillOption> {
        return this.$store.state.profileState.map((status: any) => {
          return {
            value: status.id,
            text: this.$t(`profile.${status.text}`)
          }
        })
      },
      activeProfileInvolvedEventCategories (): TranslateResult[] | string[] | number[] {
        var involvedEventCategories = this.$store.state.profile.involvedEventCategories
        // @ts-ignore - ts intellisense not finding mixins
        var activeItems = this.getProfileInvolvedEventCategoriesMaskItems(involvedEventCategories)
        return activeItems
      },
      profileInvolvedEventCategories (): TranslateResult | string {
        var involvedEventCategories = this.$store.state.profile.involvedEventCategories
        // @ts-ignore - ts intellisense not finding mixins
        return this.getProfileInvolvedEventCategoriesMaskTranslation(involvedEventCategories)
      },
      involvedEventCategoriesOptions (): Array<SprCheckboxValue> {
        var self = this
        var validItems = _.filter(this.$store.state.involvedEventCategories, (x) => x.id !== 0)

        return _.map(validItems, function (val, key) {
          return { value: val.id, text: self.$t('involvedEventCategories.' + val.text).toString() }
        })
      },
      preferredContactMethodOptions (): Array<SprCheckboxValue> {
        var self = this
        var validItems = _.filter(this.$store.state.preferredContactMethods, (x) => x.id !== 0)

        return _.map(validItems, function (val, key) {
          return { value: val.id, text: self.$t('wizard.preferredContactMethodOption' + val.id).toString() }
        })
      },
      trainText (): string {
        const translationExists = this.$t('profile.rcCourses')
        if (stringhelper.isEmptyOrSpaces(translationExists)) {
          return ''
        }
        if (!this.train || this.train.length === 0) {
          return ' - '
        }

        return this.train.join(', ')
      },
      portClass (): string {
        var port = (this.$store.state.profile && this.$store.state.profile.portrait) ? this.$store.state.profile.portrait : anonPortrait
        return 'background: url(' + port + '); background-size: cover; background-position: center center;'
      },
      labels (): string {
        return this.$store.state.profile
          ? this.$store.state.profile.firstName + ' ' + this.$store.state.profile.lastName
          : ''
      },
      showFriendshipSection (): boolean {
        // @ts-ignore cannot find mixins
        return this.jwtToken && this.jwtToken.FriendProfileStatus && this.jwtToken.FriendProfileStatus > 1
      },
      userSection (): string {
        var self = this
        return _.find(self.$store.state.sections, function (section) {
          return section.id === self.$store.state.profile.sectionId
        }).name
      }
    },
    mounted (): void {
      this.fetchMembership(this.$store.state.profile.profileId)
      this.profile = { ...this.$store.state.profile }
    },

    beforeRouteLeave (to, from, next) {
      if (!_.isEqual(this.$store.state.profile, this.profile)) {
        this.hasChanges = true
      } else {
        this.hasChanges = false
      }
      if (this.hasChanges) {
        var text = this.$t('profile.unsavedChanges').toString()
        const answer = window.confirm(text)
        if (answer) {
          return next()
        } else {
          return next(false)
        }
      }
      return next()
    },
    methods: {
      updateMotto (newValue: string): void {
        this.profile.motto = newValue
      },
      updateStory (newValue: string): void {
        this.profile.story = newValue
      },
      updateProfileState (newVal): void {
        this.profile.profileState = newVal
      },
      updateInvolvedEventCategories (newVal: InvolvedEventCategories): void {
        this.profile.involvedEventCategories = newVal
      },
      updatePreferredContactMethods (newVal: PreferredContactMethods): void {
        this.profile.preferredContactMethods = newVal
      },
      updateProfileOnBackend (): void {
        this.$store.commit('profileEdit', this.profile)
        // @ts-ignore cannot find mixins
        this.updateOwnProfile((response) => {
          // @ts-ignore cannot find mixins
          this.showAlertSuccess(this.$t('userAdmin.saveSuccess'))
        }, (fail) => {
          // @ts-ignore cannot find mixins
          this.showAlertError(this.$t('common.fetchFailed'))
        })
      },
      formatBoolean (val): string {
        if (val != null) {
          // @ts-ignore cannot find mixins
          return this.tBool(val)
        }
        return '-'
      },
      fetchMembership (id): void {
        const self = this
        const url = this.$store.state.apiBases.userservice + this.$store.state.apiEndpoints.membership[0].replace('{0}', id)
        // @ts-ignore cannot find mixins
        this.fetchItem(url, this.expSeconds(3600), function (x) {
          self.member = x
        })

        const url2 = this.$store.state.apiBases.eventservice + this.$store.state.apiEndpoints.trainings[0].replace('{0}', id)
        // @ts-ignore cannot find mixins
        this.fetchItem(url2, this.expSeconds(300), function (x) {
          self.train = x
        })
      }
    }
  })
</script>

<style lang="scss" scoped>
@import "../assets/scss/_variables.scss";
$portrait-size: 100px;
$progress-size: 100px;
.warning-option {
  margin-left: 11px;
  margin-right: 11px;
}
.warning-banner {
  margin-top: 6px;
  margin-left: -47%;
  max-width: 88%;
  padding: 8px 0;
  display: flex;
  background: lemonchiffon;
}
.save {
  text-align: right;
}
.withbutton {
  padding-bottom: 30px;
}
.profilepage {
  margin-top: 50px;

  .subheader {
    color:$brand-red;
    text-transform: uppercase;
    font-family: $font-bold;
    margin: 0px 0 20px 0;
    display:block;
    font-size: 14px;
  }

.profile-feature {
    background-color:$brand-white;
    padding:15px;
    margin-bottom: 30px;
  }

  .profile-state {
    margin-bottom: 30px;
  }

  .progress-container {
    padding: 15px 15px;
    overflow: hidden;

    .progress-size {
      height: $progress-size;
      width: $progress-size;
      display: inline-block;
    }
  }

  .portrait {
    width: $portrait-size;
    height: $portrait-size;
    border-radius: 50%;
  }

  .msg-label {
    padding: 10px 20px 10px 20px;
  }

  .info-label {
    padding: 15px 0;
    color: $brand-red;
    border-bottom: none;

    .pencil-edit {
      margin-left: 5px;
    }
  }

  .info-panel-body {
    margin-bottom: 30px;

    .eventadmins {
      padding: 0;
      li {
        list-style-type: none;
        margin: 0;
        padding: 0;
      }
    }
  }

  .main-btn {
    margin: 10px 0;
  }

  .panel {
    background: $brand-white;
    height: 100%;
  }

  .white-background {
    background: $brand-white;
  }

  .selected-value {
    padding: 5px 10px;
    color: $brand-red;
    background-color:$brand-white;
    border: 2px solid $brand-red;
    display: inline-block;
    font-family: "Signa-Book", Sans-Serif;
    font-weight: bold;
    font-size: 13px;
  }

  .image-editor-container {
    position: relative;

    .image-editor-link {
      position: absolute;
      bottom: -8px;
      right: -20px;
    }
  }

  .break-heading {
    overflow-wrap: break-word;
  }
}
@media (max-width: $media-lg-max) {
  .warning-banner {
    margin-left: -35%;
  }
}
@media (max-width: $media-md-max) {
  .warning-banner {
    margin-left: -10%;
  }
}
@media (max-width: $media-sm-max) {
  .warning-banner {
    margin-left: 0%;
  }
}
</style>
